<div class="user-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="user-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="user-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" formControlName="FirstName" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('FirstName') && !form.hasError('required')">
          Please enter a valid First Name
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          FirstName is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Surname</mat-label>
        <input matInput placeholder="Surname" formControlName="Surname" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('Surname') && !form.hasError('required')">
          Please enter a valid Surname
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          Surname is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="Email" formControlName="Email" maxlength="150" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('Email') && !form.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput type="tel" placeholder="Phone" formControlName="Phone" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <input matInput placeholder="Mobile" formControlName="Mobile" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field>
        <mat-label>Role</mat-label>
        <mat-select formControlName="UserRole" placeholder="Role" [compareWith]="equalsRole">
          <mat-option *ngFor="let role of administrationService.roles | filter:[{Disabled: false}]" [value]="role">{{role.RoleName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Home Station</mat-label>
        <mat-select formControlName="HomeStationId" placeholder="Home Station">
          <mat-option *ngFor="let station of form.get('Stations').value" [value]="station.Id">{{station.StationName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Reports To</mat-label>
        <mat-select formControlName="ReportsToUser" placeholder="Reports To">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let supervisor of supervisors | filter:[{Disabled: false}]" [value]="supervisor.Id">{{supervisor.FirstName}} {{supervisor.Surname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group" style="visibility: hidden">
      <mat-form-field>
        <mat-label>Department</mat-label>
        <mat-select formControlName="DepartmentId" placeholder="Department">
          <mat-option *ngFor="let department of administrationService.departments | filter:[{Disabled: false}]" [value]="department.Id">{{department.DepartmentName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group">
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
    <div class="form-group" style="visibility: hidden">
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="UserName" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('UserName') && !form.hasError('required')">
          Please enter a valid UserName
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          UserName is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <kendo-floatinglabel text="Stations">
      <kendo-multiselect [autoClose]="false"
                         [data]="administrationService.stations"
                         [textField]="'StationName'"
                         [valueField]="'Id'"
                         [valuePrimitive]="false"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="Stations"
                         placeholder="Stations"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Contact Types">
      <kendo-multiselect [autoClose]="false"
                         [data]="administrationService.contactTypes"
                         [textField]="'ContactTypeName'"
                         [valueField]="'Id'"
                         [valuePrimitive]="false"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="ContactTypes"
                         placeholder="Contact Types"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
  </div>
  <div class="user-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
