import { Injectable } from '@angular/core';
import { Permission } from '../../components/administration/administration.models';
import { Role, User } from '../models/common.models';


@Injectable({ providedIn: 'root' })

export class AuthorisationService
{
  public Permissions = AppPermissions;

  public readonly unknownRole: Role = { Id: 0, Disabled: false, LastUpdated: null, Permissions: null, RoleLevel: 0, RoleName: "Unknown", SortOrder: 0, ChangedByUserId: 0 };
  public readonly systemAdminRole: Role = { Id: 1, Disabled: false, LastUpdated: null, Permissions: [], RoleLevel: 5, RoleName: "System Administrator", SortOrder: 1, ChangedByUserId: 0 };

  private _currentuser: User = null;


  constructor()
  {


  }

  get currentuser(): User
  {
    return this._currentuser;
  }
  set currentuser(value: User)
  {
    this._currentuser = value;
  }

  get isSystemAdministrator()
  {
    return this.currentuser != null && this.currentuser.UserRole != null && this.currentuser.UserRole.RoleName.trim().toLowerCase() === "system administrator";
  }


  hasPermission(permissionId: number)
  {
    if (this.isSystemAdministrator)
    {
      //special built in system role that can do anything
      return true;
    }

    if (permissionId == null
      || permissionId < 1
      || this.currentuser == null
      || this.currentuser.UserRole == null
      || this.currentuser.UserRole.Permissions == null
      || this.currentuser.UserRole.Permissions.length == 0)
    {
      return false;
    }

    return this.currentuser.UserRole.Permissions.some((p: Permission) => p.Id == permissionId);
  }




}


export enum AppPermissions
{
  ViewStationCalendar = 1,
  ViewDigitalCalendar = 2,
  ViewWhatsOn = 3,
  ViewCategory = 4,
  AddCategory = 5,
  EditCategory = 6,
  ViewProperty = 7,
  AddProperty = 8,
  EditProperty = 9,
  ViewActivitySales = 10,
  ViewActivityImplementation = 11,
  ViewActivityStationSpecific = 12,
  AddBroadcastActivity = 13,
  EditActivitySales = 14,
  EditActivityImplementation = 15,
  EditActivityStationSpecific = 16,
  DeleteCategory = 17,
  DeleteProperty = 18,
  DeleteActivity = 19,
  ViewWhatsOnEvent = 20,
  AddWhatsOnEvent = 21,
  EditWhatsOnEvent = 22,
  ViewConfidentialActivity = 23,
  EditConfidentialActivity = 24,
  EditConfirmedActivity = 25,
  EditOnHoldActivity = 26,
  ChangeActivityFromHoldStatus = 27,
  ChangeActivityFromGuaranteedStatus = 28,
  ChangeActivityFromConfirmedStatus = 29,
  AddContactTypes = 30,
  EditContactTypes = 31,
  DeleteContactTypes = 32,
  AddDepartments = 33,
  EditDepartments = 34,
  DeleteDepartments = 35,
  AddMechanicTypes = 36,
  EditMechanicTypes = 37,
  DeleteMechanicTypes = 38,
  AddProductCategories = 39,
  EditProductCategories = 40,
  DeleteProductCategories = 41,
  AddRoles = 42,
  EditRoles = 43,
  DeleteRoles = 44,
  AddStations = 45,
  EditStations = 46,
  DeleteStations = 47,
  AddStationTypes = 48,
  EditStationTypes = 49,
  DeleteStationTypes = 50,
  EditUsers = 51,
  DeleteUsers = 52,
  RegisterUsers = 53,
  ViewCurrentUsers = 55,
  ViewHomePage = 56,
  DeleteWhatsOnEvent = 58,
  Search = 59,
  ViewCreditReport = 60,
  ViewRevenueReport = 61,
  ViewDigitalReport = 62,
  ViewActivitiesSummaryReport = 63,
  AddPropertyTypes = 64,
  EditPropertyTypes = 65,
  DeletePropertyTypes = 66,
  ChangeRolePermissions = 67,
  ViewRolePermissions = 68,
  AddDigitalDependencyTypes = 69,
  EditDigitalDependencyTypes = 70,
  DeleteDigitalDependencyTypes = 71,
  AddStationNotificationGroups = 72,
  EditStationNotificationGroups = 73,
  DeleteStationNotificationGroups = 74,
  AddNotificationGroups = 75,
  EditNotificationGroups = 76,
  DeleteNotificationGroups = 77,
  ResetDropdeadDate = 78,
  ChangeActivityFromSFDStatus = 79,
  ChangeCreditLineDueDate = 80,
  ChangeSFDHeldDate = 81,
  ChangeIsGuaranteedHeldDate = 82,
  ChangeOnHoldHeldDate = 83,
  AddUsers = 84,
  AllowActivityProductCategoryConflict = 85,
  ChangeActivityToHoldStatus = 86,
  ChangeActivityToGuaranteedStatus = 87,
  ChangeActivityToConfirmedStatus = 88,
  ChangeActivityToSFDStatus = 89,
  Audit = 90,
  AddEventCategories = 91,
  EditEventCategories = 92,
  DeleteEventCategories = 93,
  AddDayParts = 94,
  EditDayParts = 95,
  DeleteDayParts = 96,
  ChangeDropdeadDate = 97,
  ViewCreditLineReport = 98,
  CanCopyActivityInformation = 99,
  CanCopyPropertyInformation = 100,
  CanReplaceActivityContacts = 101,
  AddClients = 102,
  EditClients = 103,
  DeleteClients = 104,
  CanEnableDisableActivity = 105,
  CanEnableDisableProperty = 106,
  EditDisabledActivity = 107,
  CanCopyCreditLines = 108,
  ViewAvailsReport = 109,
  ViewFillReport = 110,
  AddJobs = 111,
  EditJobs = 112,
  DeleteJobs = 113,
  ViewAvailsSummaryReport = 114,
  CanUseControlPanel = 115,
  AddCampaigns = 116,
  EditCampaigns = 117,
  DeleteCampaigns = 118,
  ViewCampaigns = 119,
  CanProgressCampaignActivities = 120,
  ViewCreditlineFollowupsReport = 121,
  AddExtensions = 122,
  EditExtensions = 123,
  DeleteExtensions = 124,
  ViewExtensions = 125,
  CanWorkWithBroadcastActivities = 126,
  CanWorkWithNonBroadcastActivities = 127,
  AddNonBroadcastActivity = 128

}
