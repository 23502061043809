import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { Role } from "../../../shared/models/common.models";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { Permission } from "../administration.models";
import { AdministrationService } from '../administration.service';


@Component({
    templateUrl: './role-edit.component.html',
    selector: 'role-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './roles.component.scss'],
    providers: [MatTableCrudService]
})
export class RoleEditComponent extends MatTableCrudEditComponent<RoleEditComponent, Role>
{
    public selectedPermissions: Permission[] = [];
    public rolePermissionsControlSettings: any = { add: 'Available Permissions', remove: 'Permissions For Role', all: 'All', none: 'None', direction: 'left-to-right', draggable: true, locale: undefined }



    constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<RoleEditComponent>, @Inject(MAT_DIALOG_DATA) public data: Role, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
    {
        super(dialogRef, data);

        this.form = this.fb.group({
            Id: [this.data.Id],
            RoleName: [this.data.RoleName, Validators.required],
            RoleLevel: [this.data.RoleLevel, Validators.compose([Validators.pattern('^\\d+$')])],
            Disabled: [this.data.Disabled],
            Permissions: [this.data.Permissions],
            SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
        });

    }

    ngOnInit()
    {
        super.ngOnInit();

        //reset selected values
        this.selectedPermissions = [];

        if (this.data && this.data.Permissions && this.data.Permissions.length > 0)
        {
            this.selectedPermissions = JSON.parse(JSON.stringify(this.data.Permissions));
        }
    }

    onSave(): void
    {
        let role: any = this.form.value;

        if (role)
        {
            role.Permissions = this.selectedPermissions;

            if (this.isNew)
            {
                role.Id = 0;
                role.RoleLevel = 0;

                if (role.Disabled == null)
                {
                    role.Disabled = false;
                }
            }

            role[this.changedByField] = this.authorisationService.currentuser.Id;

            super.onSave(role);
        }
  }


  onCancelClick(): void
  {
   

    super.onCancelClick();
  }

    get dialogHeaderText(): string
    {
        let headerText = "Edit Role";

        if (this.isNew)
        {
            headerText = "New Role";
        }
        else
        {
            if (this.showEditItemNameInHeaderText)
            {
                headerText = `${headerText} - ${this.data.RoleName}`;
            }
        }

        return headerText;
    }

    rolePermissionsChange(event: any)
    {
        this.form.markAsDirty();
    }


    ngOnDestroy()
    {
        super.ngOnDestroy();

    }

    get disableRolePermissions(): boolean
    {
        let disable = true;

        disable = !this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeRolePermissions);

        return disable;
    }

    get showRolePermissions(): boolean
    {
        let show = true;

        show = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewRolePermissions) || this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeRolePermissions);

        return show;
    }

}
