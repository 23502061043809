import { EventEmitter, inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { QueryClientService, queryOptions, tapSuccess, UsePersistedQuery } from '@ngneat/query';
import { getDate } from '@progress/kendo-date-math';
import { addDays, differenceInCalendarDays, parse } from 'date-fns';
import { BehaviorSubject, debounceTime, firstValueFrom, Observable, switchMap } from 'rxjs';
import { ActivityInsertResult, DeleteResult, SaveResult } from '../../shared/models/result.model';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { ActivitiesByDateRangeParameters, ActivitiesParameters, Activity, ActivityStatus, CalendarDayLetter, CalendarInfo, CalendarParameters, Category, EventDay, EventDaysParameters, MechanicType, ProductCategory, Property, PropertyType, Station, StationType, WeekListItem } from './calendars.models';

@Injectable({ providedIn: 'root' })

export abstract class CalendarsBaseService
{
  private queryClient = inject(QueryClientService);
  private usePersistedQuery = inject(UsePersistedQuery);

  public _sanitizer = inject(DomSanitizer);
  public configurationService = inject(ConfigurationService);
  public commonService = inject(CommonService);
  private dataHandler = inject(DataHandlerService);

  protected abstract isOnline: boolean;

  public homeStation: Station = null;

  private _CalendarHtml: SafeHtml;
  private _availableStations: Station[] = [];

  private _startDate: Date;
  private _endDate: Date;

  private _stationIdOfClickedActivity: number;

  private _selectedStationGroupId: number;
  private _selectedStations: Station[] = [];
  private _selectedCategoryIds: number[] = [];
  private _selectedPropertyTypeIds: number[] = [];
  private _selectedWeek: WeekListItem = null;
  private _selectedYear: number = this.commonService.today.getFullYear();
  private _noOfStations = 0;
  private _colSpan = 0;
  private _stationsColSpan = 0;

  private _propertyTypes: PropertyType[] = [];
  private _mechanicTypes: MechanicType[] = [];
  private _productCategories: ProductCategory[] = [];
  private _categories: Category[] = [];
  private _properties: Property[] = [];
  private _stationGroups: StationType[] = [];
  private _stations: Station[] = [];
  private _dayLetters: BehaviorSubject<CalendarDayLetter[]> = new BehaviorSubject<CalendarDayLetter[]>([]);
  private _noOfDays = 0;
  private _noOfWeeks = 5;
  private _weeks: WeekListItem[] = undefined;
  private _weeksForSelectedYear: WeekListItem[] = undefined;
  private _years: number[] = [];
  private _statuses: ActivityStatus[] = [];
  private _dataResetFromActivitySave = false;
  private _dataResetFromCategorySave = false;
  private _dataResetFromPropertySave = false;

  private _currentEventDay = 1;
  private _currentEventMonth = 0;
  private _currentEventYear = 0;
  private _currentEventStationId = 0;
  private _currentEventStationTypeId = 0;

  private dayLettersAndNumbersProcessing = false;

  private _calendarParameters$ = new BehaviorSubject<CalendarParameters>(
    {
      CategoryIds: this.selectedCategoryIds,
      PropertyTypeIds: [],
      startDate: this.commonService.dateToWebApiString(new Date()),
      endDate: this.commonService.dateToWebApiString(addDays(new Date(), ((this.noOfWeeks < 1 ? 1 : 0) * 7) - 1)),
      stationIds: this.selectedStations.map<number>(x => x.Id),
      noOfWeeks: this.noOfWeeks,
      noOfStations: this.noOfStations,
      isOnline: false
    });

  public calendarParameters$ = this._calendarParameters$.asObservable();


  calendarParametersUpdate(name: string, value: any)
  {
    if (!value) return;

    this._calendarParameters$.next({
      ...this._calendarParameters$.value,
      [name]: value
    },
    );
  }

  calendarParametersUpdateAll()
  {
    this._calendarParameters$.next({
      CategoryIds: this.selectedCategoryIds,
      endDate: this.commonService.dateToWebApiString(getDate(this.endDate)),
      isOnline: this.isOnline,
      noOfStations: this.noOfStations,
      noOfWeeks: this.noOfWeeks,
      PropertyTypeIds: this.selectedPropertyTypeIds,
      startDate: this.commonService.dateToWebApiString(getDate(this.startDate)),
      stationIds: this.selectedStations.map<number>(x => x.Id)
    },
    );
  }

  public getCalendarHtml = this.usePersistedQuery((queryKey: [CalendarParameters]) =>
  {
    return queryOptions({
      queryKey,
      queryFn: ({ queryKey }) => this.dataHandler.postHttpObservable<CalendarInfo>(this.configurationService.cbSettings.calendarServiceUrl + "/GetEventDaysAndCalendarHtml", queryKey[0], false, false),
      staleTime: this.configurationService.cbSettings.persistedQueryStaleTime
    });
  });


  public calendarHtml$ = this._calendarParameters$.pipe(
    debounceTime(this.configurationService.cbSettings.cacheDebounceTime),
    switchMap(parameters =>
      this.getCalendarHtml([parameters]).result$.pipe(
        tapSuccess(result =>
        {
          try
          {
            this.calendarDataArrived.emit(true);

            if (result)
            {
              let stationIds: number[] = [];
              this.selectedStations.map((s) => stationIds.push(s.Id));

              let colspan = 1;

              if (this.noOfDays && this.noOfStations)
              {
                colspan = (this.noOfDays + (this.noOfWeeks * this.noOfStations));
              }

              if (colspan < 1)
              {
                colspan = 1;
              }

              this._colSpan = colspan;

              colspan = 1;

              if (this.noOfDays && this.noOfStations)
              {
                colspan = (this.noOfDays + (this.noOfWeeks * this.noOfStations)) / this.noOfStations;
              }

              if (colspan < 1)
              {
                colspan = 1;
              }

              this._stationsColSpan = colspan;

              this.currentStations.next(this._selectedStations);

              this.processDayNumbersAndLetters(result.EventDays);

              this.dateLettersProcessed.emit(true);

              this.commonService.appSignalRService.run('JoinCalendarStateGroupAsync', this.startDate, this.endDate, stationIds, this.noOfWeeks, this.isOnline);

              result.CalendarHtml = this.transformCalendarHtml(result.CalendarHtml);

              if (this.dataResetFromActivitySave || this.dataResetFromCategorySave || this.dataResetFromPropertySave)
              {
                this.commonService.sendCalendarHtml(result.CalendarHtml);

                //only activity saves will affect dashboard data
                if (this.dataResetFromActivitySave)
                {
                  this.commonService.refreshDashboard();
                }

                this.dataResetFromActivitySave = false;
                this.dataResetFromCategorySave = false;
                this.dataResetFromPropertySave = false;
              }
            }
          }
          catch
          {
            this.calendarDataArrived.emit(true);
          }

          let previousWeek: WeekListItem = this.getPreviousWeekById(this.selectedWeek.Id);

          if (previousWeek)
          {
            let sDate = this.getStartDateForWeekNo(previousWeek.WeekNo);

            let eDate = new Date(sDate); //clone start date
            eDate = addDays(eDate, (this.noOfWeeks * 7) - 1);

            const weekBeforeCalendarParameters = {
              ...this._calendarParameters$.value,
              startDate: this.commonService.dateToWebApiString(sDate),
              endDate: this.commonService.dateToWebApiString(eDate)
            };

            this.queryClient.prefetchQuery([weekBeforeCalendarParameters], () =>
              firstValueFrom(this.dataHandler.postHttpObservable<CalendarInfo>(this.configurationService.cbSettings.calendarServiceUrl + "/GetEventDaysAndCalendarHtml", weekBeforeCalendarParameters, false, false)),
              { staleTime: this.configurationService.cbSettings.prefetchQueryStaleTime }
            ).then(data =>
            {
              let nextWeek: WeekListItem = this.getNextWeekById(this.selectedWeek.Id);

              if (nextWeek)
              {
                sDate = this.getStartDateForWeekNo(nextWeek.WeekNo);

                eDate = new Date(sDate); //clone start date
                eDate = addDays(eDate, (this.noOfWeeks * 7) - 1);

                const weekAfterCalendarParameters = {
                  ...this._calendarParameters$.value,
                  startDate: this.commonService.dateToWebApiString(sDate),
                  endDate: this.commonService.dateToWebApiString(eDate)
                };

                this.queryClient.prefetchQuery([weekAfterCalendarParameters], () =>
                  firstValueFrom(this.dataHandler.postHttpObservable<CalendarInfo>(this.configurationService.cbSettings.calendarServiceUrl + "/GetEventDaysAndCalendarHtml", weekAfterCalendarParameters, false, false)),
                  { staleTime: this.configurationService.cbSettings.prefetchQueryStaleTime }
                );
              }
            });
          }
        })
      )

    )
  );




  public calendarResetStarted: EventEmitter<boolean> = new EventEmitter();
  public calendarDataArrived: EventEmitter<boolean> = new EventEmitter();
  public dateLettersProcessed: EventEmitter<boolean> = new EventEmitter();

  public currentStations: BehaviorSubject<Station[]> = new BehaviorSubject<Station[]>([]);
  public currentCalendarHtml: BehaviorSubject<SafeHtml> = new BehaviorSubject<SafeHtml>("");



  constructor()
  { }

  public load()
  {
    this.weeks;
  }

  public resetHttpCache()
  {
    this.dataHandler.resetCache();
  }

  get dataResetFromActivitySave(): boolean
  {
    return this._dataResetFromActivitySave;
  }
  set dataResetFromActivitySave(value: boolean)
  {
    this._dataResetFromActivitySave = value;
  }

  get dataResetFromCategorySave(): boolean
  {
    return this._dataResetFromCategorySave;
  }
  set dataResetFromCategorySave(value: boolean)
  {
    this._dataResetFromCategorySave = value;
  }

  get dataResetFromPropertySave(): boolean
  {
    return this._dataResetFromPropertySave;
  }
  set dataResetFromPropertySave(value: boolean)
  {
    this._dataResetFromPropertySave = value;
  }



  get stationIdOfClickedActivity(): number
  {
    return this._stationIdOfClickedActivity;
  }
  set stationIdOfClickedActivity(value: number)
  {
    this._stationIdOfClickedActivity = value;
  }



  get selectedStationGroupId(): number
  {
    return this._selectedStationGroupId;
  }
  set selectedStationGroupId(value: number)
  {
    this._selectedStationGroupId = value;
    this._availableStations = [];
  }


  get selectedStations(): Station[]
  {
    return this._selectedStations;
  }
  set selectedStations(value: Station[])
  {
    this._selectedStations = value.sort((a, b) =>
    {
      // Sort by SortOrder
      // If the first item has a higher number, move it up
      // If the first item has a lower number, move it down
      if (a.SortOrder > b.SortOrder) return 1;
      if (a.SortOrder < b.SortOrder) return -1;

      // If the SortOrder number is the same between both items, sort alphabetically
      // If the first item comes first in the alphabet, move it up
      // Otherwise move it down
      if (a.StationName > b.StationName) return 1;
      if (a.StationName < b.StationName) return -1;

      return 1;
    });

    localStorage.setItem("selectedStations", JSON.stringify(this._selectedStations));
  }

  get selectedCategoryIds(): number[]
  {
    return this._selectedCategoryIds;
  }
  set selectedCategoryIds(value: number[])
  {
    this._selectedCategoryIds = value;

    localStorage.setItem("selectedCategoryIds", JSON.stringify(value));
  }

  get selectedPropertyTypeIds(): number[]
  {
    return this._selectedPropertyTypeIds;
  }
  set selectedPropertyTypeIds(value: number[])
  {
    this._selectedPropertyTypeIds = value;

    localStorage.setItem("selectedPropertyTypeIds", JSON.stringify(value));
  }

  get selectedPropertyTypeNames(): string
  {
    let name = "All Property Types";  // if none selected name is "ALL"

    if (this._selectedPropertyTypeIds && this._selectedPropertyTypeIds.length > 0)
    {
      let pts = this.propertyTypes.filter(p => this.selectedPropertyTypeIds.some(spi => spi == p.Id));

      if (pts && pts.length > 0)
      {
        //If all selected then choose name is "ALL" as well
        if (pts.length < this.propertyTypes.length)
        {
          name = "";

          pts.map(p =>
          {
            name += p.PropertyTypeName + ", ";
          });

          //remove trailing comma
          name = name.substring(0, name.length - 2);
        }
      }
    }

    return name;
  }


  setNoOfWeeks()
  {
    switch (this.selectedStations.length)
    {
      case 0:
        this.noOfWeeks = 5;
        break;
      case 1:
        this.noOfWeeks = 5;
        break;
      case 2:
        this.noOfWeeks = 2;
        break;
      case 3:
        this.noOfWeeks = 1;
        break;
      default:
        this.noOfWeeks = 1;
        break;
    }
  }

  get currentEventDay(): number
  {
    return this._currentEventDay;
  }
  set currentEventDay(value: number)
  {
    this._currentEventDay = value;
  }

  get currentEventMonth(): number
  {
    return this._currentEventMonth;
  }
  set currentEventMonth(value: number)
  {
    this._currentEventMonth = value;
  }

  get currentEventYear(): number
  {
    return this._currentEventYear;
  }
  set currentEventYear(value: number)
  {
    this._currentEventYear = value;
  }

  get currentEventStationId(): number
  {
    return this._currentEventStationId;
  }
  set currentEventStationId(value: number)
  {
    this._currentEventStationId = value;
  }

  get currentEventStationTypeId(): number
  {
    return this._currentEventStationTypeId;
  }
  set currentEventStationTypeId(value: number)
  {
    this._currentEventStationTypeId = value;
  }

  get selectedWeek(): WeekListItem
  {
    return this._selectedWeek;
  }
  set selectedWeek(value: WeekListItem)
  {
    this._selectedWeek = value;

    if (!this.startDate && value && value.WeekNo > 0)
    {
      this.startDate = this.getStartDateForWeekNo(value.WeekNo);
    }

    localStorage.setItem("selectedWeek", JSON.stringify(value));
  }

  get selectedYear(): number
  {
    if (!this._selectedYear)
    {
      this._selectedYear = this.commonService.today.getFullYear();
    }

    return this._selectedYear;
  }
  set selectedYear(value: number)
  {
    this._selectedYear = value;

    localStorage.setItem("selectedYear", JSON.stringify(value));
  }

  get noOfWeeks(): number
  {
    return this._noOfWeeks;
  }
  set noOfWeeks(value: number)
  {
    if (value != null && value > 0 && this.startDate)
    {
      if (!this.endDate)
      {
        this.endDate = new Date(this.startDate.valueOf())
      }

      this.endDate.setDate(this.startDate.getDate() + ((value * 7) - 1));

      this._noOfWeeks = value;
    }
  }

  get years(): number[]
  {
    if (!this._years || this._years.length == 0)
    {
      this._years = this.weeks.reduce((a, d) =>
      {
        if (!a.includes(d["Year"])) { a.push(d["Year"]); }
        return a;
      }, []);

      //for (var year = this.minDate.getFullYear(); year <= this.maxDate.getFullYear(); year++)
      //{
      //  this._years.push(year);
      //}
    }

    // return Array.from(this._years);
    return this._years;
  }

  get weeksForSelectedYear(): WeekListItem[]
  {
    if (!this._weeksForSelectedYear || this._weeksForSelectedYear.length < 1 || this._weeksForSelectedYear[0].Year != this.selectedYear)
    {
      if (this.weeks && this.weeks.length > 0 && this.selectedYear)
      {
        this._weeksForSelectedYear = this.weeks.filter(w => w.Year == this.selectedYear);
      }
      else
      {
        this._weeksForSelectedYear = [];
      }
    }

    return this._weeksForSelectedYear;
  }

  get weeks(): WeekListItem[]
  {
    return this._weeks;
  }
  set weeks(value: WeekListItem[])
  {
    this._weeks = value;
  }

  getPreviousWeekById(id: number): WeekListItem
  {
    let week: WeekListItem = undefined;

    if (this.weeks && this.weeks.length > 0)
    {
      let prevId = id - 1;

      if (prevId > 0 && this.weeks.some((w: WeekListItem) => w.Id == prevId))
      {
        week = this.weeks.filter((w: WeekListItem) => w.Id == prevId)[0];
      }
    }

    return week;
  }

  getNextWeekById(id: number): WeekListItem
  {
    let week: WeekListItem = undefined;

    if (this.weeks && this.weeks.length > 0)
    {
      let nextId = id + 1;

      if (this.weeks.some((w: WeekListItem) => w.Id == nextId))
      {
        week = this.weeks.filter((w: WeekListItem) => w.Id == nextId)[0];
      }
    }

    return week;
  }


  getWeekNoForDate(date: Date): number
  {
    let weekNo = 1;

    if (this.weeks && this.weeks.length > 0)
    {
      if (this.weeks.some((w: WeekListItem) =>
      {
        let weekCommencingDate: Date = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today);

        return weekCommencingDate <= getDate(date) && addDays(weekCommencingDate, 6) >= getDate(date);
      }))
      {
        weekNo = this.weeks.filter((w: WeekListItem) =>
        {
          let weekCommencingDate: Date = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today);

          return weekCommencingDate <= getDate(date) && addDays(weekCommencingDate, 6) >= getDate(date);
        })[0].WeekNo;
      }
    }

    return weekNo;
  }

  getStartDateForWeekNo(weekNo: number): Date
  {
    let selectedDate: Date = this.startDate;
    let selectedDateString = "";

    if (weekNo > 0 && this.weeksForSelectedYear && this.weeksForSelectedYear.length > 0)
    {
      if (this.weeksForSelectedYear.some((w: WeekListItem) => w.WeekNo == weekNo))
      {
        selectedDateString = this.weeksForSelectedYear.filter((w: WeekListItem) => w.WeekNo == weekNo)[0].WeekCommencingDateString;
      }
      else
      {
        //Select 1st week if unable to get week for week number
        this.selectedWeek = this.weeksForSelectedYear.filter((w: WeekListItem) => w.WeekNo == 1)[0];

        selectedDateString = this.selectedWeek.WeekCommencingDateString;
      }

      selectedDate = parse(selectedDateString, "yyyy-MM-dd", this.commonService.today);
    }

    return selectedDate;
  }


  get startDate(): Date
  {
    return this._startDate;
  }
  set startDate(value: Date)
  {
    this._startDate = value;
  }

  get endDate(): Date
  {
    return this._endDate;
  }
  set endDate(value: Date)
  {
    this._endDate = value;
  }

  get statuses(): ActivityStatus[]
  {
    if (!this._statuses || this._statuses.length == 0)
    {
      this._statuses = [
        { Name: 'Pending', Value: 'Pending' },
        { Name: 'On Hold', Value: 'OnHold' },
        { Name: 'Submitted For Delivery', Value: 'IsUnConfirmedSold' },
        { Name: 'First Right Refusal', Value: 'IsGuaranteed' },
        { Name: 'Confirmed', Value: 'IsSold' }
      ];
    }

    return this._statuses;
  }

  get noOfDays(): number
  {
    return this._noOfDays;
  }


  get dayLetters(): Observable<CalendarDayLetter[]>
  {
    return this._dayLetters.asObservable();
  }


  processDayNumbersAndLetters(eventDays: EventDay[] = null)
  {
    if (!this.dayLettersAndNumbersProcessing)
    {
      try
      {
        this.dayLettersAndNumbersProcessing = true;

        let dls: CalendarDayLetter[] = [];

        let lastStationId = this.selectedStations[this.selectedStations.length - 1].Id;

        this.selectedStations.forEach((s) =>
        {
          let daycount = 1;
          let currDate: Date = new Date(this.startDate.valueOf());

          while (currDate <= this.endDate)
          {
            let eventCount = 0;
            let eventTooltip = "";

            let eventDay: EventDay = null;

            if (eventDays && eventDays.length > 0)
            {
              eventDay = eventDays.find(e => this.commonService.parseJsonDate(e.EventDate).valueOf() == currDate.valueOf() && e.StationId == s.Id);

              if (eventDay)
              {
                eventCount = eventDay.EventCount;
                eventTooltip = eventDay.EventsTooltip;
              }
            }

            let cdl: CalendarDayLetter = new CalendarDayLetter();
            cdl.Letter = currDate.toLocaleDateString(navigator.language, { weekday: 'narrow' });
            cdl.DayNumber = currDate.getDate().toString();
            cdl.EventMonth = currDate.getMonth();
            cdl.EventYear = currDate.getFullYear();
            cdl.EventStationId = s.Id;
            cdl.EventStationTypeId = s.StationTypeId;
            cdl.EventCount = eventCount;
            cdl.EventsTooltip = eventTooltip;

            dls.push(cdl);

            //increment date
            currDate.setDate(currDate.getDate() + 1);

            if (daycount == 7)
            {
              let divider = new CalendarDayLetter();
              divider.LastDivider = s.Id == lastStationId && currDate > this.endDate;

              //add week divider cells
              dls.push(divider);

              //reset for new week
              daycount = 1;
            }
            else
            {
              daycount++;
            }
          }
        });

        this._dayLetters.next(dls);

        this.dayLettersAndNumbersProcessing = false;
      }
      catch
      {
        this.dayLettersAndNumbersProcessing = false;
      }
    }
  }

  get colSpan(): number
  {
    return this._colSpan;
  }

  get stationsColSpan(): number
  {
    return this._stationsColSpan;
  }

  cssGridSpan(span: number): string
  {
    return "span " + span;
  }

  get noOfStations(): number
  {
    return this._noOfStations;
  }

  resetSelectedStations()
  {
    if (!this.selectedStations)
    {
      this.selectedStations = [];
    }

    if (this.availableStations && this.availableStations.length > 0)
    {
      if (this.selectedStationGroupId == 0) //ALL
      {
        this.selectedStations = [];

        let propTypes = this.propertyTypes.filter(pt => this.selectedPropertyTypeIds.includes(pt.Id) || (this.selectedPropertyTypeIds.length == 1 && this.selectedPropertyTypeIds[0] == 0));

        if (propTypes && propTypes.length > 0 && !propTypes.some(pt => pt.IsBroadcast))
        {
          this.selectedStations = this.availableStations;
        }
      }
      else
      {
        //Remove selected stations from other Station Groups
        this.selectedStations = this.selectedStations.filter(s => s.StationTypeId == this.selectedStationGroupId);
      }

      //Add default selected station if all were another station group which wasn't ALL
      if (this.selectedStations.length == 0)
      {
        let newSelectedStations: Station[] = [];

        if (this.homeStation
          && (this.homeStation.StationTypeId == this.selectedStationGroupId || this.selectedStationGroupId == 0)
          && this.availableStations.map(s => s.StationTypeId).includes(this.homeStation.StationTypeId)) //All is StationGroupId 0
        {
          newSelectedStations.push(this.homeStation);
        }
        else
        {
          newSelectedStations.push(this.availableStations[0]);
        }

        //selected Stations set NOT added to with push so selectedStations localStorage saved
        this.selectedStations = newSelectedStations;
      }
    }
  }

  resetCategories()
  {
    this._categories = [];
  }

  get categories(): Category[]
  {
    return this._categories;
  }
  set categories(value: Category[])
  {
    this._categories = value;
  }

  get properties(): Property[]
  {
    return this._properties;
  }
  set properties(value: Property[])
  {
    this._properties = value;
  }

  get propertyTypes(): PropertyType[]
  {
    return this._propertyTypes;
  }
  set propertyTypes(value: PropertyType[])
  {
    this._propertyTypes = value;
  }

  get currentProperties(): Property[]
  {
    return this._properties.filter(p => !p.Disabled && !p.Deleted && (p.IsPermanent || (p.StartDate <= this.commonService.today && p.EndDate >= this.commonService.today)));
  }

  get mechanicTypes(): MechanicType[]
  {
    return this._mechanicTypes;
  }
  set mechanicTypes(value: MechanicType[])
  {
    this._mechanicTypes = value;
  }

  get productCategories(): ProductCategory[]
  {
    return this._productCategories;
  }
  set productCategories(value: ProductCategory[])
  {
    this._productCategories = value;
  }

  get stationGroups(): StationType[]
  {
    return this._stationGroups;
  }
  set stationGroups(value: StationType[])
  {
    this._stationGroups = value;
  }

  get stations(): Station[]
  {
    return this._stations;
  }
  set stations(value: Station[])
  {
    this._stations = value;
  }


  get availableStations(): Station[]
  {
    if (!this._availableStations || this._availableStations.length == 0 && this.stations)
    {
      if (this.selectedStationGroupId != null)
      {
        if (this.selectedStationGroupId == 0
          && (!this.selectedPropertyTypeIds
            || this.selectedPropertyTypeIds.length == 0
            || (this.selectedPropertyTypeIds.length == 1 && this.selectedPropertyTypeIds[0] == 0)))
        {
          this._availableStations = this.stations;
        }
        else
        {
          if (this.selectedStationGroupId > 0)
          {
            this._availableStations = this.stations.filter((s) => s.StationType?.Id == this.selectedStationGroupId);
          }
          else
          {
            let propertyTypes = this.propertyTypes.filter(f => this.selectedPropertyTypeIds.includes(f.Id));

            this._availableStations = this.stations.filter(f => (f.PlatformType?.Name == "Internet" && propertyTypes.some(s => !s.IsBroadcast)) || (f.PlatformType?.Name == "Radio Station" && propertyTypes.some(s => s.IsBroadcast)));
          }
        }
      }

      //Ensure that there are always stations returned so if the above results in nothing, return all
      if (!this._availableStations || this._availableStations.length == 0)
      {
        this._availableStations = this.stations;
      }
    }

    return this._availableStations;
  }


  resetCalendarData()
  {
    this.calendarResetStarted.emit(true);

    let stationsQty = 1;

    if (this._selectedStations && this._selectedStations.length > 0)
    {
      stationsQty = this._selectedStations.length;
    }

    this._noOfStations = stationsQty;

    this.startDate = this.getStartDateForWeekNo(this.selectedWeek.WeekNo);

    this.endDate = new Date(this.startDate); //clone start date
    this.endDate = addDays(this.endDate, (this.noOfWeeks * 7) - 1);

    this._noOfDays = (differenceInCalendarDays(this._endDate, this._startDate) + 1) * this.noOfStations;

    let stationIds: number[] = [];
    this.selectedStations.map((s) => stationIds.push(s.Id));

    this.calendarParametersUpdateAll();
  }

  get CalendarHtml(): SafeHtml
  {
    return this._CalendarHtml;
  }

  transformCalendarHtml(html: string)
  {
    if (html && (html.includes("z1") || html.includes("z5")))
    {
      html = html.replace(/z0/g, "<div class='ch c' style='grid-area:");
      html = html.replace(/z1/g, "<div class='c' style='grid-area:");
      html = html.replace(/z2/g, "/1/span 1/span 1;' onclick='window.my.cc(event,");
      html = html.replace(/z3/g, "/2/span 1/span");
      html = html.replace(/z4/g, ";' onclick='window.my.cc(event,");
      html = html.replace(/z5/g, "<div class='pr' title='Id:");
      html = html.replace(/z6/g, "style='grid-area:");
      html = html.replace(/z7/g, ";' onclick='window.my.pc(event,");
      html = html.replace(/z8/g, "<div class=");
      html = html.replace(/z9/g, "onclick=\"window.my.ac(event,'");
      html = html.replace(/q0/g, "oncontextmenu=\"window.my.arc(event,'");
      html = html.replace(/q1/g, "<input id='");
      html = html.replace(/q2/g, "</div>");
      html = html.replace(/q3/g, "'><div class='l'>");
      html = html.replace(/q4/g, "' type='hidden' value='");
      html = html.replace(/q5/g, "onmouseenter=\"window.my.ah(event,'");
      html = html.replace(/q6/g, "onmouseenter='window.my.ph(event,");
    }

    return html;
  }



  getStationNameFromId(id: number): string
  {
    let name = "";

    let station: Station = this.stations.filter(s => s.Id == id)[0];

    if (station)
    {
      name = station.StationName;
    }

    return name;
  }


  getWeekListItems(useCachedData: boolean = false): Observable<WeekListItem[]>
  {
    return this.dataHandler.getHttpObservable<WeekListItem[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetAllWeekListItems", useCachedData, false);
  }

  getStationGroups(useCachedData: boolean = false): Observable<StationType[]>
  {
    return this.dataHandler.getHttpObservable<StationType[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetStationGroups", useCachedData, false);
  }

  getStations(useCachedData: boolean = false): Observable<Station[]>
  {
    return this.dataHandler.getHttpObservable<Station[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetStations", useCachedData, false);
  }

  getPropertyTypes(useCachedData: boolean = false): Observable<PropertyType[]>
  {
    return this.dataHandler.getHttpObservable<PropertyType[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetPropertyTypes?isOnline=" + this.isOnline, useCachedData, false);
  }

  getMechanicTypes(useCachedData: boolean = false): Observable<MechanicType[]>
  {
    return this.dataHandler.getHttpObservable<MechanicType[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetMechanicTypes", useCachedData, false);
  }

  getProductCategories(useCachedData: boolean = false): Observable<ProductCategory[]>
  {
    return this.dataHandler.getHttpObservable<ProductCategory[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetProductCategories", useCachedData, false);
  }

  getCategory(id: number, useCachedData: boolean = false): Promise<Category>
  {
    return this.dataHandler.getHttpPromise<Category>(this.configurationService.cbSettings.calendarServiceUrl + "/GetCategory?id=" + id, useCachedData, false);
  }

  getCategories(useCachedData: boolean = false): Promise<Category[]>
  {
    return this.dataHandler.getHttpPromise<Category[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetCategories?isOnline=" + this.isOnline, useCachedData, false);
  }

  createCategory(category: Category, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings.calendarServiceUrl + "/CreateCategory", category, useCachedData, false);
  }

  updateCategory(category: Category): Promise<SaveResult>
  {
    return this.dataHandler.putHttpPromise<SaveResult>(this.configurationService.cbSettings.calendarServiceUrl + "/UpdateCategory", category, false);
  }

  deleteCategory(category: Category): Promise<DeleteResult>
  {
    return this.dataHandler.putHttpPromise<DeleteResult>(this.configurationService.cbSettings.calendarServiceUrl + "/DeleteCategory", category, false);
  }


  getProperty(id: number, useCachedData: boolean = false): Promise<Property>
  {
    return this.dataHandler.getHttpPromise<Property>(this.configurationService.cbSettings.calendarServiceUrl + "/GetProperty?id=" + id, useCachedData, false);
  }

  getProperties(useCachedData: boolean = false): Observable<Property[]>
  {
    return this.dataHandler.getHttpObservable<Property[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetProperties?isOnline=" + this.isOnline, useCachedData, false);
  }

  createProperty(property: Property, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings.calendarServiceUrl + "/CreateProperty", property, useCachedData, false);
  }

  updateProperty(property: Property): Promise<SaveResult>
  {
    return this.dataHandler.putHttpPromise<SaveResult>(this.configurationService.cbSettings.calendarServiceUrl + "/UpdateProperty", property, false);
  }

  deleteProperty(property: Property): Promise<DeleteResult>
  {
    return this.dataHandler.putHttpPromise<DeleteResult>(this.configurationService.cbSettings.calendarServiceUrl + "/DeleteProperty", property, false);
  }


  getActivity(id: number, useCachedData: boolean = false): Promise<Activity>
  {
    return this.dataHandler.getHttpPromise<Activity>(this.configurationService.cbSettings.calendarServiceUrl + "/GetActivity?id=" + id, useCachedData, false);
  }

  getActivitiesForPropertyAndDate(propertyId: number, spotDate: Date, stationIds: number[], level: number, useCachedData: boolean = false): Promise<Activity[]>
  {
    let activitiesParameters: ActivitiesParameters = new ActivitiesParameters();
    activitiesParameters.spotDate = spotDate;
    activitiesParameters.stationIds = stationIds;
    activitiesParameters.propertyId = propertyId;
    activitiesParameters.level = level;

    return this.dataHandler.postHttpPromise<Activity[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetActivitiesForPropertyAndDate", activitiesParameters, useCachedData, false);
  }

  getActivitiesForPropertyAndDateRange(propertyId: number, startDate: string, endDate: string, stationIds: number[], level: number, useCachedData: boolean = false)
  {
    let activitiesParameters: ActivitiesByDateRangeParameters = new ActivitiesByDateRangeParameters();
    activitiesParameters.startDate = startDate;
    activitiesParameters.endDate = endDate;
    activitiesParameters.stationIds = stationIds;
    activitiesParameters.propertyId = propertyId;
    activitiesParameters.level = level;

    return this.dataHandler.postHttpObservable<Activity[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetActivitiesForPropertyAndDateRange", activitiesParameters, useCachedData, false);
  }

  createActivity(activity: Activity, useCachedData: boolean = false): Promise<ActivityInsertResult>
  {
    return this.dataHandler.postHttpPromise<ActivityInsertResult>(this.configurationService.cbSettings.calendarServiceUrl + "/CreateActivity", activity, useCachedData, false);
  }

  updateActivity(activity: Activity): Observable<SaveResult>
  {
    return this.dataHandler.putHttpObservable<SaveResult>(this.configurationService.cbSettings.calendarServiceUrl + "/UpdateActivity", activity, false);
  }

  deleteActivity(activity: Activity): Promise<DeleteResult>
  {
    return this.dataHandler.putHttpPromise<DeleteResult>(this.configurationService.cbSettings.calendarServiceUrl + "/DeleteActivity", activity, false);
  }

  getEventDays(startDate: Date, endDate: Date, stationIds: number[], useCachedData: boolean = false): Promise<EventDay[]>
  {
    let eventDaysParameters: EventDaysParameters = new EventDaysParameters();
    eventDaysParameters.startDate = startDate;
    eventDaysParameters.endDate = endDate;
    eventDaysParameters.stationIds = stationIds;

    return this.dataHandler.postHttpPromise<EventDay[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetEventDays", eventDaysParameters, useCachedData, false);
  }





}










