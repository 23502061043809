import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from "../../../shared/services/common.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { PlatformType, Station } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { StationEditComponent } from "./station-edit.component";


@Component({
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class StationsComponent extends MatTableCrudComponent<Station>
{
  public StationNameFilter: any;
  public StateFilter: any;
  public StationTypeIdFilter: any;
  public PlatformTypeIdFilter: any;

  constructor(
    public authorisationService: AuthorisationService,
    protected injector: Injector,
    public commonService: CommonService,
    public configurationService: ConfigurationService,
    public administrationService: AdministrationService)
  {
    super(injector,
      StationEditComponent,
      StationEditComponent,
      StationEditComponent,
      "CreateStation",
      "DeleteStation",
      "GetStationsServer",
      "UpdateStation",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";
    this.ServerPaging = true;

    this.displayedColumns = ['Id', 'StationName', 'State', 'StationType.StationTypeName', 'PlatformType.Name'];
    this.filteredValues = [
      { Field: "StationName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "State", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "StationTypeId", Value: "", Path: "StationType.Id", DataType: FilterDataType.Number },
      { Field: "PlatformTypeId", Value: "", Path: "PlatformType.Id", DataType: FilterDataType.Number }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "65rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStations);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStations);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStations);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    let platformType: PlatformType = this.administrationService.platformTypes.filter(p => p.Id == this.commonService.stationPlatformTypeId)[0];

    this.addedItem.PlatformType = platformType;
    this.addedItem.PlatformTypeId = this.commonService.stationPlatformTypeId;
    this.addedItem.SortOrder = 0;
    this.addedItem.DaysUntilDropDead = this.configurationService.cbSettings.defaultPodcastDaysUntilDropDead;

    super.addNew(event);
  }

  deleteItem(event: any, station: Station)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this platform (${station.StationName})?`;
    this.deleteHeaderText = "Delete Platform?";

    super.deleteItem(event, station);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

