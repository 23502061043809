import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'filter'
})
export class GenericFilterPipe implements PipeTransform
{
    transform(value: any, args: any[]): any
    {
        if (value && Array.isArray(value) && value.length > 0)
        {
            return value.filter((item: any) =>
            {
                let blnReturn = true;

                for (let filter of args)
                {
                    if (filter)
                    {
                        let filterKeys = Object.keys(filter);

                        filterKeys.reduce((memo: any, keyName: any) =>
                        {
                            blnReturn = blnReturn
                                && memo != null
                                && keyName != null
                                && item[keyName] != null
                                && filter[keyName] != null
                                && memo != undefined
                                && keyName != undefined
                                && item[keyName] != undefined
                                && filter[keyName] != undefined
                                && item[keyName].toString().toLowerCase() === filter[keyName].toString().toLowerCase();

                            return blnReturn;
                        }, true);
                    }
                }

                return blnReturn;
            });
        }
        else
        {
            return value;
        }
    }

}
