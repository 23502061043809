import { NgModule } from '@angular/core';
import { ChartModule } from '@progress/kendo-angular-charts';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DatePickerModule, DateInputsModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule, ButtonGroupModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { IconsModule } from "@progress/kendo-angular-icons";
//import { ListBoxModule } from "@progress/kendo-angular-listbox";
import { Layout } from '@progress/kendo-drawing';
import { NotificationModule } from "@progress/kendo-angular-notification";
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { LabelModule } from '@progress/kendo-angular-label';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PDFModule, SchedulerModule } from '@progress/kendo-angular-scheduler';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

//import { DropDownListFilterComponent } from './shared/components/kendo-dropdownlist-filter.component';
//import { ExternalDropDownListFilterComponent } from './shared/components/kendo-external-dropdownlist-filter.component';


@NgModule({
  exports: [
    PDFExportModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    IconsModule,
    LayoutModule,
    DatePickerModule,
    TimePickerModule,
    SchedulerModule,
    TooltipModule,
    PDFModule,
    IndicatorsModule,
    DropDownsModule,
    DialogModule,
    ChartModule,
    ButtonGroupModule,
    DateInputsModule, 
    ExcelExportModule,
    ExcelModule,
    GridModule,
    //ListBoxModule,
    NotificationModule
    //ExternalDropDownListFilterComponent,
    //DropDownListFilterComponent,
  ]
})
export class KendoAngularModule { }



