import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { AdministrationService } from '../administration.service';
import { Job, JobAction, JobDocumentType, JobType } from "../administration.models";
import { PopupSettings } from "@progress/kendo-angular-dateinputs";
import { ReportService } from "../../reports/report.service";
import { CommonService } from "../../../shared/services/common.service";
import { ReportParametersComponent } from "../../reports/report-parameters.component";
import { Subscription, takeUntil } from "rxjs";
import { ReportListItem, ReportParameter, ReportParameters } from "../../reports/reports.models";



@Component({
  templateUrl: './job-edit.component.html',
  selector: 'job-edit-form',
  styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './jobs.component.scss'],
  providers: [MatTableCrudService]
})
export class JobEditComponent extends MatTableCrudEditComponent<JobEditComponent, Job>
{
  private dialogIsOpen = false;
  private parametersClosedSubscription: Subscription;

  public popupSettings: PopupSettings = {
    appendTo: 'root',
    animate: false,
    popupClass: 'popup-class-activity'
  };

  constructor(public fb: UntypedFormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<JobEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Job,
    public administrationService: AdministrationService,
    public authorisationService: AuthorisationService,
    public commonService: CommonService,
    public reportService: ReportService,
    public loggerService: LoggerService,
    private cdr: ChangeDetectorRef)
  {
    super(dialogRef, data);

    if (this.data.StartDate)
    {
      this.data.StartDate = this.commonService.parseJsonDate(this.data.StartDate);
    }
    if (this.data.StartTime)
    {
      this.data.StartTime = this.commonService.parseJsonDate(this.data.StartTime);
    }
    if (this.data.EndDate)
    {
      this.data.EndDate = this.commonService.parseJsonDate(this.data.EndDate);
    }
    if (this.data.EndTime)
    {
      this.data.EndTime = this.commonService.parseJsonDate(this.data.EndTime);
    }

    if (!this.data.ReportParameters)
    {
      this.data.ReportParameters = new ReportParameters();
    }

    this.form = this.fb.group({
      Id: [this.data.Id],
      Title: [this.data.Title, Validators.required],
      Description: [this.data.Description],
      JobDocumentTypeId: [this.data.JobDocumentTypeId],
      JobTypeId: [this.data.JobTypeId, Validators.required],
      JobActionId: [this.data.JobActionId, Validators.required],
      Target: [this.data.Target],
      Frequency: [this.data.Frequency, Validators.required],
      ReportParameters: [this.data.ReportParameters],
      Recipients: [this.data.Recipients],
      StartDate: [this.data.StartDate, Validators.required],
      EndDate: [this.data.EndDate],
      StartTime: [this.data.StartTime, Validators.required],
      StartTimeZone: [this.data.StartTimeZone],
      EndTime: [this.data.EndTime],
      EndTimeZone: [this.data.EndTimeZone],
      Disabled: [this.data.Disabled || false]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();


  }

  reportParameters()
  {
    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "50rem";
        dialogConfig.hasBackdrop = true;

        let report: ReportListItem = this.reportService.reportListItems.filter(r => r.value == this.form.get("Target").value)[0];

        let params: ReportParameters = this.form.get("ReportParameters").value;
        params.ReportName = report.value;
        params.ReportTitle = report.name;

        dialogConfig.data = params;

        const dialogRef = this.dialog.open(ReportParametersComponent, dialogConfig);

        this.parametersClosedSubscription = dialogRef.afterClosed().subscribe((result: ReportParameters) =>
        {
          this.dialogIsOpen = false;

          if (result)
          {
            this.form.controls["ReportParameters"].patchValue(result, { emitEvent: false });

            this.cdr.detectChanges();

            this.form.markAsDirty();
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  onSave(): void
  {
    let job: any = this.form.value as Job;

    if (job)
    {
      if (this.isNew)
      {
        job.Id = 0;

        if (job.Disabled == null)
        {
          job.Disabled = false;
        }
      }

      job[this.changedByField] = this.authorisationService.currentuser.Id;

      job.StartTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      job.EndTimeZone = job.StartTimeZone;

      if (job.StartDate)
      {
        job.StartDate = this.commonService.formatDateForService(job.StartDate);
      }

      if (job.StartTime)
      {
        job.StartTime = this.commonService.formatDateForService(job.StartTime);
      }

      if (job.EndDate)
      {
        job.EndDate = this.commonService.formatDateForService(job.EndDate);
      }

      if (job.EndTime)
      {
        job.EndTime = this.commonService.formatDateForService(job.EndTime);
      }

      if (job.JobActionId == 1 && !job.Frequency || job.Frequency < 1)
      {
        //Set default
        job.Frequency = 1; //Daily
      }

      if (job.JobActionId ==1 && !job.JobDocumentTypeId || job.JobDocumentTypeId < 1)
      {
        //Set default
        job.JobDocumentTypeId = 1; //Pdf
      }

      super.onSave(job);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Job";

    if (this.isNew)
    {
      headerText = "New  Job";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.Title}`;
      }
    }

    return headerText;
  }

  equalsJobDocumentType(o1: JobDocumentType, o2: JobDocumentType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsJobType(o1: JobType, o2: JobType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsJobAction(o1: JobAction, o2: JobAction)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();

    if (this.parametersClosedSubscription)
    {
      this.parametersClosedSubscription.unsubscribe();
    }

  }



}
