import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CldrIntlService } from '@progress/kendo-angular-intl';

@Injectable()
export class CustomKendoIntlService extends CldrIntlService
{

  constructor(@Inject(LOCALE_ID) localeId: string)
  {
    super(localeId);
  }

  public firstDay(): number
  {
    return 1;
  }


}
