<mat-card appearance="outlined" class="mat-crud-wide">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
      Users
    </mat-card-title>
    <div class="card-header-text-icons">
      Reload data:
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">
      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="Id">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Id</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FirstName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            First Name
          </div>
          <mat-form-field>
            <mat-label>Search First Name</mat-label>
            <input matInput class="form-field" [formControl]="FirstNameFilter" placeholder="Search First Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.FirstName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Surname">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Surname
          </div>
          <mat-form-field>
            <mat-label>Search Surname</mat-label>
            <input matInput class="form-field" [formControl]="SurnameFilter" placeholder="Search Surname" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.Surname}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Email">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Email
          </div>
          <mat-form-field>
            <mat-label>Search Email</mat-label>
            <input matInput class="form-field" [formControl]="EmailFilter" placeholder="Search Email" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.Email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="HomeStationName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Home Station
          </div>
          <mat-form-field>
            <mat-label>Search Home Station</mat-label>
            <input matInput class="form-field" [formControl]="HomeStationNameFilter" placeholder="Search Home Station" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.HomeStationName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="UserRole.RoleName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div class="sort-header">
            User Role
          </div>
          <mat-form-field>
            <mat-select [formControl]="RoleIdFilter" placeholder="Search User Role">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let role of administrationService.roles | filter:[{Disabled: false}]" [value]="role.Id">{{role.RoleName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.UserRole.RoleName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ReportsToName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Reports To
          </div>
          <mat-form-field>
            <mat-select [formControl]="ReportsToUserFilter" placeholder="Search Reports To">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let supervisor of administrationService.supervisors | filter:[{Disabled: false}]" [value]="supervisor.Id">{{supervisor.FirstName}} {{supervisor.Surname}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.ReportsToName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ContactTypeNames">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Contact Types
          </div>
          <mat-form-field>
            <mat-label>Search Contact Types</mat-label>
            <input matInput class="form-field" [formControl]="ContactTypeNamesFilter" placeholder="Search Contact Types" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.ContactTypeNames}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Disabled">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Disabled
          </div>
          <input type="checkbox"
                  kendoCheckBox
                  size="large"
                  rounded="medium"
                  [indeterminate]="chkStatus?.status==='crossed'"
                  [ngModel]="chkStatus?.status==='checked'"
                  (change)="onChange(chkStatus)"
                  style="margin: 10px 25px;"/>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <input type="checkbox"
                 kendoCheckBox
                 size="large"
                 rounded="medium"
                 [ngModel]="row?.Disabled"
                 [disabled]="true"
                 style="margin: 10px 13px;" />
        </mat-cell>
      </ng-container>


      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
          <div class="card-header-text-icons">
            Reload data:
            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <button mat-icon-button color="accent" (click)="addNew($event)" title="add" *ngIf="CanAdd">
            <i class="material-icons">add_box</i>
          </button>
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row; let i=index;">
          <button mat-icon-button color="accent" (click)="editItem($event, row)" title="edit" *ngIf="CanEdit">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>

          <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete" *ngIf="CanDelete">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>

          <button mat-icon-button *ngIf="false"
                  (click)="lockUser(row)"
                  title="{{row?.IsLocked ? 'Locked' : 'Unlocked'}}"
                  [ngClass]="{'locked-button': row?.IsLocked,'unlocked-button': !row?.IsLocked}">
            <mat-icon *ngIf="row?.IsLocked" aria-label="Locked">lock</mat-icon>
            <mat-icon *ngIf="!row?.IsLocked" aria-label="Unlocked">lock_open</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource?.data?.length == 0 ? '' : 'none'">
      No results
    </div>

  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator
                   [length]="dataSource?.filteredData?.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   showFirstLastButtons="true">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
