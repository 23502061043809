import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { StationType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';


@Component({
  templateUrl: './station-type-edit.component.html',
  selector: 'station-type-edit-form',
  styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './station-types.component.scss'],
  providers: [MatTableCrudService]
})
export class StationTypeEditComponent extends MatTableCrudEditComponent<StationTypeEditComponent, StationType>
{
  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<StationTypeEditComponent>, @Inject(MAT_DIALOG_DATA) public data: StationType, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      StationTypeName: [this.data.StationTypeName, Validators.required],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    let stationType: any = this.form.value;

    if (stationType)
    {
      if (this.isNew)
      {
        stationType.Id = 0;

        if (stationType.Disabled == null)
        {
          stationType.Disabled = false;
        }
      }

      stationType[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(stationType);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Platform Group";

    if (this.isNew)
    {
      headerText = "New Platform Group";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.StationTypeName}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
