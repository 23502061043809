<div class="notify-group-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="notify-group-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="notify-group-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="Name" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Notification Group Type</mat-label>
        <mat-select formControlName="NotifyGroupType" placeholder="Notification Group Type" [compareWith]="equalsNotifyGroupType">
          <mat-option *ngFor="let notifygrouptype of notifyGroupTypes | filter:[{Disabled: false}]" [value]="notifygrouptype">{{notifygrouptype.Name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group span-all-grid-columns">
      <fieldset>
        <legend>Notify members when</legend>
        <div class="form-group" *ngIf="!isNotifyTypeInterfacing">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformIfConfirmed" title="Activity Confirmed">Activity Confirmed</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="!isNotifyTypeInterfacing">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformIfSubmittedForSale" title="Activity Submitted For Delivery">Activity Submitted For Delivery</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="!isNotifyTypeInterfacing">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformIfOnHold" title="Activity On Hold">Activity On Hold</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformDropDeadWarning" title="Activity Drop Dead Warning">Activity Drop Dead Warning</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformDropDead" title="Activity Drop Dead">Activity Drop Dead</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformAudioDueToAdOps" title="Audio Due To Ad Ops">Audio Due To Ad Ops</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformCreditLineChanged" title="Credit Line Changed">Credit Line Changed</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformCreditLineDue" title="Credit Line Due">Credit Line Due</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformConfirmedActivityDisabled" title="Confirmed Activity Disabled">Confirmed Activity Disabled</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformConfirmedActivityDateRangeChanged" title="Date Range Changed on Confirmed Activity">Date Range Changed on Confirmed</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformFinalPcrDue" title="Final PCR Due">Final PCR Due</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformFirstDayAircheckDue" title="First Day Aircheck Due">First Day Aircheck Due</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="WarnFirstRightRefusalCountdown" title="First Right of Refusal Warning">FRR Warning</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformIfFirstRightRefusalExpires" title="First Right of Refusal Expires">FRR Expires</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformIfOnHoldExpires" title="On Hold Expires">On Hold Expires</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeGeneral">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformIfOffSubmittedForSale" title="Submitted For Delivery Expires">Submitted For Delivery Expires</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeInterfacing && configurationService?.cbSettings?.isInterfacingActivities">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformActivitiesInterfaceEvents" title="Activities Interfacing Events">Activities Interfacing Event Occurs</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeInterfacing && configurationService?.cbSettings?.isInterfacingActivities">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformActivitiesImportErrors" title="Activities Import Errors">Activities Import Error Occurs</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeInterfacing && configurationService?.cbSettings?.isInterfacingClients">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformClientsInterfaceEvents" title="Client Interfacing Events">Client Interfacing Event Occurs</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeInterfacing && configurationService?.cbSettings?.isInterfacingClientCategories">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformClientCategoriesInterfaceEvents" title="Client Categories Interfacting Events">Client Categories Interfacting Event Occurs</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeInterfacing && configurationService?.cbSettings?.isInterfacingRates">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformRatesInterfaceEvents" title="Rates Interfacing Events">Rates Interfacing Event Occurs</mat-checkbox>
          </div>
        </div>
        <div class="form-group" *ngIf="isNotifyTypeInterfacing && configurationService?.cbSettings?.isInterfacingUsers">
          <div class="checkbox-form-group">
            <mat-checkbox formControlName="InformUsersInterfaceEvents" title="Users Interfacing Events">Users Interfacing Event Occurs</mat-checkbox>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="notify-group-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
