import { Injectable, inject } from "@angular/core";
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CommonDataService } from "./common-data.service";
import { ToolsService } from "../../components/tools/tools.service";
import { DashboardService } from "../components/dashboard/dashboard.service";
import { AuthorisationService } from "./authorisation.service";
import { AdministrationService } from "../../components/administration/administration.service";
import { ContactTypeId } from "../../components/calendars/calendars.models";
import { CalendarsService } from "../../components/calendars/calendars.service";
import { DashboardData } from "../components/dashboard/dashboard.models";
import { DiaryService } from "../../components/diary/diary.service";



@Injectable({ providedIn: 'root' })

export class ResolveDataService
{
  private administrationService = inject(AdministrationService);
  private commonDataService = inject(CommonDataService);
  private calendarsService = inject(CalendarsService);
  private diaryService = inject(DiaryService);
  private toolsService = inject(ToolsService);
  private dashboardService = inject(DashboardService);
  private authorisationService = inject(AuthorisationService);


  public resolveAccessTypes()
  {
    if (this.administrationService.accessTypes && this.administrationService.accessTypes.length > 0)
    {
      return of(this.administrationService.accessTypes);
    }
    else
    {
      return this.administrationService.getAccessTypes().pipe(take(1), tap(data => this.administrationService.accessTypes = data)).subscribe();
    }
  }

  public resolveActivitiesList()
  {
    if (this.toolsService.activitiesList && this.toolsService.activitiesList.length > 0)
    {
      return of(this.toolsService.activitiesList);
    }
    else
    {
      return of(this.toolsService.activitiesList);
      //return this.toolsService.getActivitiesList().pipe(take(1), tap(data => this.toolsService.activitiesList = data)).subscribe();
    }
  }

  public resolveAdOps()
  {
    if (this.commonDataService.adOps && this.commonDataService.adOps.length > 0)
    {
      return of(this.commonDataService.adOps);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.AdOps).pipe(take(1), tap(data => this.commonDataService.adOps = data)).subscribe();
    }
  }

  public resolveBriefManagers()
  {
    if (this.commonDataService.briefManagers && this.commonDataService.briefManagers.length > 0)
    {
      return of(this.commonDataService.briefManagers);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.BriefManager).pipe(take(1), tap(data => this.commonDataService.briefManagers = data)).subscribe();
    }
  }

  public resolveCampaigns()
  {
    if (this.commonDataService.campaigns && this.commonDataService.campaigns.length > 0)
    {
      return of(this.commonDataService.campaigns);
    }
    else
    {
      return this.commonDataService.getCampaigns().pipe(take(1), tap(data => this.commonDataService.campaigns = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveCategories()
  {
    if (this.calendarsService.categories && this.calendarsService.categories.length > 0)
    {
      return of(this.calendarsService.categories);
    }
    else
    {
      return this.calendarsService.getCategories().then(data => this.calendarsService.categories = !data || !Array.isArray(data) ? [] : data);
    }
  }

  public resolveClientCategories()
  {
    if (this.calendarsService.productCategories && this.calendarsService.productCategories.length > 0)
    {
      return of(this.calendarsService.productCategories);
    }
    else
    {
      return this.calendarsService.getProductCategories().pipe(take(1), tap(data => this.calendarsService.productCategories = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveClientExecutives()
  {
    if (this.commonDataService.clientExecutives && this.commonDataService.clientExecutives.length > 0)
    {
      return of(this.commonDataService.clientExecutives);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.ClientExecutive).pipe(take(1), tap(data => this.commonDataService.clientExecutives = data)).subscribe();
    }
  }

  public resolveClients()
  {
    if (this.commonDataService.clients && this.commonDataService.clients.length > 0)
    {
      return of(this.commonDataService.clients);
    }
    else
    {
      return this.commonDataService.getClients().pipe(take(1), tap(data => this.commonDataService.clients = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveClientSupports()
  {
    if (this.commonDataService.clientSupport && this.commonDataService.clientSupport.length > 0)
    {
      return of(this.commonDataService.clientSupport);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.ClientSupport).pipe(take(1), tap(data => this.commonDataService.clientSupport = data)).subscribe();
    }
  }

  public resolveContactTypes()
  {
    if (this.administrationService.contactTypes && this.administrationService.contactTypes.length > 0)
    {
      return of(this.administrationService.contactTypes);
    }
    else
    {
      return this.administrationService.getContactTypes().pipe(take(1), tap(data => this.administrationService.contactTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveDashboard()
  {
    if (this.dashboardService.dashboardData || !this.authorisationService.currentuser)
    {
      return of(this.dashboardService.dashboardData);
    }
    else
    {
      return this.dashboardService.getDashboardData(this.authorisationService.currentuser.Id).pipe(take(1), tap((data: DashboardData) => this.dashboardService.dashboardData = data)).subscribe();
    }
  }

  public resolveDayParts()
  {
    if (this.commonDataService.dayparts && this.commonDataService.dayparts.length > 0)
    {
      return of(this.commonDataService.dayparts);
    }
    else
    {
      return this.commonDataService.getDayParts().pipe(take(1), tap(data => this.commonDataService.dayparts = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveDepartments()
  {
    if (this.administrationService.departments && this.administrationService.departments.length > 0)
    {
      return of(this.administrationService.departments);
    }
    else
    {
      return this.administrationService.getDepartments().pipe(take(1), tap(data => this.administrationService.departments = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveEventCategories()
  {
    if (this.diaryService.eventCategories && this.diaryService.eventCategories.length > 0)
    {
      return of(this.diaryService.eventCategories);
    }
    else
    {
      return this.diaryService.getEventCategories().pipe(take(1), tap(data => this.diaryService.eventCategories = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveExtensions()
  {
    if (this.administrationService.extensions && this.administrationService.extensions.length > 0)
    {
      return of(this.administrationService.extensions);
    }
    else
    {
      return this.administrationService.getExtensions().pipe(take(1), tap(data => this.administrationService.extensions = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveJobActions()
  {
    if (this.administrationService.jobActions && this.administrationService.jobActions.length > 0)
    {
      return of(this.administrationService.jobActions);
    }
    else
    {
      return this.administrationService.getJobActions().pipe(take(1), tap(data => this.administrationService.jobActions = data)).subscribe();
    }
  }

  public resolveJobDocumentTypes()
  {
    if (this.administrationService.jobDocumentTypes && this.administrationService.jobDocumentTypes.length > 0)
    {
      return of(this.administrationService.jobDocumentTypes);
    }
    else
    {
      return this.administrationService.getJobDocumentTypes().pipe(take(1), tap(data => this.administrationService.jobDocumentTypes = data)).subscribe();
    }
  }

  public resolveJobTypes()
  {
    if (this.administrationService.jobTypes && this.administrationService.jobTypes.length > 0)
    {
      return of(this.administrationService.jobTypes);
    }
    else
    {
      return this.administrationService.getJobTypes().pipe(take(1), tap(data => this.administrationService.jobTypes = data)).subscribe();
    }
  }

  public resolveMechanicTypes()
  {
    if (this.calendarsService.mechanicTypes && this.calendarsService.mechanicTypes.length > 0)
    {
      return of(this.calendarsService.mechanicTypes);
    }
    else
    {
      return this.calendarsService.getMechanicTypes().pipe(take(1), tap(data => this.calendarsService.mechanicTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveNationalProjectManagers()
  {
    if (this.commonDataService.nationalProjectManagers && this.commonDataService.nationalProjectManagers.length > 0)
    {
      return of(this.commonDataService.nationalProjectManagers);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.NationalProjectManager).pipe(take(1), tap(data => this.commonDataService.nationalProjectManagers = data)).subscribe();
    }
  }

  public resolveNotifyGroups()
  {
    if (this.administrationService.notifyGroups && this.administrationService.notifyGroups.length > 0)
    {
      return of(this.administrationService.notifyGroups);
    }
    else
    {
      return this.administrationService.getNotifyGroups().pipe(take(1), tap(data => this.administrationService.notifyGroups = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveNotifyGroupTypes()
  {
    if (this.administrationService.notifyGroupTypes && this.administrationService.notifyGroupTypes.length > 0)
    {
      return of(this.administrationService.notifyGroupTypes);
    }
    else
    {
      return this.administrationService.getNotifyGroupTypes().pipe(take(1), tap(data => this.administrationService.notifyGroupTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveOnlineDependencyTypes()
  {
    if (this.administrationService.onlineDependencyTypes && this.administrationService.onlineDependencyTypes.length > 0)
    {
      return of(this.administrationService.onlineDependencyTypes);
    }
    else
    {
      return this.administrationService.getOnlineDependencyTypes().pipe(take(1), tap(data => this.administrationService.onlineDependencyTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolvePermissions()
  {
    if (this.administrationService.permissions && this.administrationService.permissions.length > 0)
    {
      return of(this.administrationService.permissions);
    }
    else
    {
      return this.administrationService.getPermissions().pipe(take(1), tap(data => this.administrationService.permissions = data)).subscribe();
    }
  }

  public resolvePlatformTypes()
  {
    if (this.administrationService.platformTypes && this.administrationService.platformTypes.length > 0)
    {
      return of(this.administrationService.platformTypes);
    }
    else
    {
      return this.administrationService.getPlatformTypes().pipe(take(1), tap(data => this.administrationService.platformTypes = data)).subscribe();
    }
  }

  public resolveProgrammers()
  {
    if (this.commonDataService.programmers && this.commonDataService.programmers.length > 0)
    {
      return of(this.commonDataService.programmers);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.Programmer).pipe(take(1), tap(data => this.commonDataService.programmers = data)).subscribe();
    }
  }

  public resolveProjectManagers()
  {
    if (this.commonDataService.projectManagers && this.commonDataService.projectManagers.length > 0)
    {
      return of(this.commonDataService.projectManagers);
    }
    else
    {
      return this.commonDataService.getContactTypeUsers(ContactTypeId.ProjectManager).pipe(take(1), tap(data => this.commonDataService.projectManagers = data)).subscribe();
    }
  }

  public resolveProperties()
  {
    if (this.calendarsService.properties && this.calendarsService.properties.length > 0)
    {
      return of(this.calendarsService.properties);
    }
    else
    {
      return this.calendarsService.getProperties().pipe(take(1), tap(data => this.calendarsService.properties = !data || !Array.isArray(data) ? [] : data)).subscribe();
    }
  }

  public resolvePropertyTypes()
  {
    if (this.calendarsService.propertyTypes && this.calendarsService.propertyTypes.length > 0)
    {
      return of(this.calendarsService.propertyTypes);
    }
    else
    {
      return this.calendarsService.getPropertyTypes().pipe(take(1), tap(data => this.calendarsService.propertyTypes = !data || !Array.isArray(data) ? [] : data)).subscribe();
    }
  }

  public resolveRoles()
  {
    if (this.administrationService.roles && this.administrationService.roles.length > 0)
    {
      return of(this.administrationService.roles);
    }
    else
    {
      return this.administrationService.getRoles().pipe(take(1), tap(data => this.administrationService.roles = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveStationGroups()
  {
    if (this.calendarsService.stationGroups && this.calendarsService.stationGroups.length > 0)
    {
      return of(this.calendarsService.stationGroups);
    }
    else
    {
      return this.calendarsService.getStationGroups().pipe(take(1), tap(data => this.calendarsService.stationGroups = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveStations()
  {
    if (this.calendarsService.stations && this.calendarsService.stations.length > 0)
    {
      return of(this.calendarsService.stations);
    }
    else
    {
      return this.calendarsService.getStations().pipe(take(1), tap(data => this.calendarsService.stations = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveStationsAdmin()
  {
    if (this.administrationService.stations && this.administrationService.stations.length > 0)
    {
      return of(this.administrationService.stations);
    }
    else
    {
      return this.administrationService.getStations().pipe(take(1), tap(data => this.administrationService.stations = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveStationTypesAdmin()
  {
    if (this.administrationService.stationTypes && this.administrationService.stationTypes.length > 0)
    {
      return of(this.administrationService.stationTypes);
    }
    else
    {
      return this.administrationService.getStationTypes().pipe(take(1), tap(data => this.administrationService.stationTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveSupervisors()
  {
    if (this.administrationService.supervisors && this.administrationService.supervisors.length > 0)
    {
      return of(this.administrationService.supervisors);
    }
    else
    {
      return this.administrationService.getSupervisors().pipe(take(1), tap(data => this.administrationService.supervisors = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveWeeks()
  {
    if (this.calendarsService.weeks && this.calendarsService.weeks.length > 0)
    {
      return of(this.calendarsService.weeks);
    }
    else
    {
      return this.calendarsService.getWeekListItems().pipe(take(1), tap(data => this.calendarsService.weeks = !data || !Array.isArray(data) ? [] : data)).subscribe();
    }
  }




























}
