<mat-toolbar color="primary" class="main-menu">
  <button mat-icon-button class="menu-button" (click)="sidenavButtonClick($event)">
    <mat-icon>menu</mat-icon>
  </button>
  <div class='main-nav'>
    <div *ngFor="let routeCategory of appService.routeCategories" [ngClass]="{'selected-menu': commonService.currentpagecategory == routeCategory.CategoryName }">
      <button mat-button *ngIf="routeCategory.RoutesInCategory == 1" [routerLink]="['/' + routeCategory.Path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{routeCategory.Text}}</button>
      <div *ngIf="routeCategory.RoutesInCategory > 1">
        <button mat-button [matMenuTriggerFor]="menu">{{routeCategory.CategoryName}}</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor='let route of appService.routes | filter: [{visible: true}, {category: routeCategory.CategoryName}]' [routerLink]="['/' + route.path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{route.text}}</button>
        </mat-menu>
      </div>
    </div>
    <div #loginDiv id="loginDiv" *ngIf="false && !commonService.isLoggedIn" class="nav-item nav-link" style="cursor:pointer" (click)="authenticationService.login();">Login</div>
    <div *ngIf="false && commonService.isLoggedIn" class="nav-item nav-link" style="cursor:pointer" (click)="authenticationService.logout()">Logout</div>
    <div class="greeting-user" *ngIf="authenticationService != null && authenticationService.currentUserValue != null  && authenticationService.currentUserValue.FirstName != null && authenticationService.currentUserValue.FirstName != ''">Hi {{authenticationService?.currentUserValue?.FirstName}}</div>
  </div>
</mat-toolbar>

