import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { CalendarsBaseService } from './calendars-base.service';


@Injectable({ providedIn: 'root' })

export class CalendarsService extends CalendarsBaseService
{
  protected isOnline = false;

  constructor()
  {
    super();
  }






}







