import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
{
  let router = inject(Router);
  let authorisationService = inject(AuthorisationService);
  let configurationService = inject(ConfigurationService);
  let commonService = inject(CommonService);

  //Get UrlTree and then remove query parameters
  const urlTree: UrlTree = router.parseUrl(state.url);
  urlTree.queryParams = {};
  urlTree.fragment = null;


  let url: string = urlTree.toString();

  if (router.isActive(url, true))
  {
    //cannot activate a route that is already active
    return false;
  }

  if (commonService.isLoggedIn) //user exists and is authenticated
  {
    switch (url.replace('/', ''))
    {
      case 'addcampaign':
        return authorisationService.hasPermission(authorisationService.Permissions.AddCampaigns);
      case 'editcampaign':
        return authorisationService.hasPermission(authorisationService.Permissions.EditCampaigns);
      case 'campaigns':
        return authorisationService.hasPermission(authorisationService.Permissions.DeleteCampaigns)
          || authorisationService.hasPermission(authorisationService.Permissions.AddCampaigns)
          || authorisationService.hasPermission(authorisationService.Permissions.EditCampaigns)
          || authorisationService.hasPermission(authorisationService.Permissions.ViewCampaigns);
      case 'calendar':
        return authorisationService.hasPermission(authorisationService.Permissions.ViewStationCalendar);
      case 'onlinecalendar':
        return configurationService.cbSettings.showDigitalCalendar && authorisationService.hasPermission(authorisationService.Permissions.ViewDigitalCalendar);
      case 'diary':
        return authorisationService.hasPermission(authorisationService.Permissions.ViewWhatsOn)
          || authorisationService.hasPermission(authorisationService.Permissions.ViewWhatsOnEvent)
          || authorisationService.hasPermission(authorisationService.Permissions.AddWhatsOnEvent)
          || authorisationService.hasPermission(authorisationService.Permissions.EditWhatsOnEvent)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteWhatsOnEvent);
      case 'search':
        return authorisationService.hasPermission(authorisationService.Permissions.Search);
      case 'toolscopyactivityinfo':
        return authorisationService.hasPermission(authorisationService.Permissions.CanCopyActivityInformation);
      case 'toolscopycreditlines':
        return authorisationService.hasPermission(authorisationService.Permissions.CanCopyCreditLines);
      case 'toolscopypropertyinfo':
        return authorisationService.hasPermission(authorisationService.Permissions.CanCopyPropertyInformation);
      case 'toolsreplacecontacts':
        return authorisationService.hasPermission(authorisationService.Permissions.CanReplaceActivityContacts);
      case 'audit':
        return authorisationService.hasPermission(authorisationService.Permissions.Audit);
      case 'reports':
        return authorisationService.hasPermission(authorisationService.Permissions.ViewActivitiesSummaryReport)
          || authorisationService.hasPermission(authorisationService.Permissions.ViewRevenueReport)
          || authorisationService.hasPermission(authorisationService.Permissions.ViewDigitalReport)
          || authorisationService.hasPermission(authorisationService.Permissions.ViewCreditLineReport)
          || authorisationService.hasPermission(authorisationService.Permissions.ViewCreditReport);
      case 'contacttypes':
        return authorisationService.hasPermission(authorisationService.Permissions.AddContactTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteContactTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.EditContactTypes);
      case 'controlpanel':
        return authorisationService.hasPermission(authorisationService.Permissions.CanUseControlPanel);
      case 'eventcategories':
        return authorisationService.hasPermission(authorisationService.Permissions.AddEventCategories)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteEventCategories)
          || authorisationService.hasPermission(authorisationService.Permissions.EditEventCategories);
      case 'extensions':
        return authorisationService.hasPermission(authorisationService.Permissions.AddExtensions)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteExtensions)
          || authorisationService.hasPermission(authorisationService.Permissions.EditExtensions);
      case 'clients':
        return authorisationService.hasPermission(authorisationService.Permissions.AddClients)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteClients)
          || authorisationService.hasPermission(authorisationService.Permissions.EditClients);
      case 'departments':
        return authorisationService.hasPermission(authorisationService.Permissions.AddDepartments)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteDepartments)
          || authorisationService.hasPermission(authorisationService.Permissions.EditDepartments);
      case 'dayparts':
        return authorisationService.hasPermission(authorisationService.Permissions.AddDayParts)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteDayParts)
          || authorisationService.hasPermission(authorisationService.Permissions.EditDayParts);
      case 'jobs':
        return authorisationService.hasPermission(authorisationService.Permissions.AddJobs)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteJobs)
          || authorisationService.hasPermission(authorisationService.Permissions.EditJobs);
      case 'mechanictypes':
        return authorisationService.hasPermission(authorisationService.Permissions.AddMechanicTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteMechanicTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.EditMechanicTypes);
      case 'onlinedependencytypes':
        return authorisationService.hasPermission(authorisationService.Permissions.AddDigitalDependencyTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteDigitalDependencyTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.EditDigitalDependencyTypes);
      case 'productcategories':
        return authorisationService.hasPermission(authorisationService.Permissions.AddProductCategories)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteProductCategories)
          || authorisationService.hasPermission(authorisationService.Permissions.EditProductCategories);
      case 'propertytypes':
        return authorisationService.hasPermission(authorisationService.Permissions.AddPropertyTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.DeletePropertyTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.EditPropertyTypes);
      case 'roles':
        return authorisationService.hasPermission(authorisationService.Permissions.AddRoles)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteRoles)
          || authorisationService.hasPermission(authorisationService.Permissions.EditRoles);
      case 'platforms':
        return authorisationService.hasPermission(authorisationService.Permissions.AddStations)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteStations)
          || authorisationService.hasPermission(authorisationService.Permissions.EditStations);
      case 'platformgroups':
        return authorisationService.hasPermission(authorisationService.Permissions.AddStationTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteStationTypes)
          || authorisationService.hasPermission(authorisationService.Permissions.EditStationTypes);
      case 'notifygroupmembers':
        return authorisationService.hasPermission(authorisationService.Permissions.AddStationNotificationGroups)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteStationNotificationGroups)
          || authorisationService.hasPermission(authorisationService.Permissions.EditStationNotificationGroups);
      case 'notifygroups':
        return authorisationService.hasPermission(authorisationService.Permissions.AddNotificationGroups)
          || authorisationService.hasPermission(authorisationService.Permissions.DeleteNotificationGroups)
          || authorisationService.hasPermission(authorisationService.Permissions.EditNotificationGroups);
      case 'users':
        return authorisationService.hasPermission(authorisationService.Permissions.DeleteUsers)
          || authorisationService.hasPermission(authorisationService.Permissions.EditUsers);
      case 'currentUsers':
        return authorisationService.hasPermission(authorisationService.Permissions.ViewCurrentUsers);
      case 'home':
        return true;
      case 'signin-callback':
        return true;
      case 'signout-callback':
        return true;
      case 'unauthorised':
        return true;
      default:
        return false;
    }
  }

  if (!url.includes("home"))
  {
    // not logged in so redirect to home page with the return url
    router.navigate(['/home'], { queryParams: { returnUrl: state.url } });
  }

  return false;

};


