import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModuleShared } from './app.shared.module';
import { AppComponent } from './components/app/app.component';
import { appRoutingProviders } from './components/app/app.routing';





@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        AppModuleShared,
        BrowserAnimationsModule,
        HammerModule
    ],
    providers: [],
  bootstrap: [AppComponent, appRoutingProviders]
})
export class AppModule { }
