import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { CommonService } from '../services/common.service';
import { ConfigurationService } from '../services/configuration.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor
{
  constructor(
    public authenticationService: AuthenticationService,
    public configurationService: ConfigurationService,
    public commonService: CommonService,
    public router: Router)
  {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (this.isCallToApi(req.url))
    {
      let tokenType = "access";

      if (req.url.includes("/GetUserContext"))
      {
        tokenType = "id";
      }

      return from(this.authenticationService.getToken(tokenType).then(async (token: any) =>
      {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        const authReq = req.clone({ headers });

        return await lastValueFrom(next.handle(authReq).pipe(tap(_ => { }, error =>
        {
          let respError = error as HttpErrorResponse;

          if (respError && (respError.status === 401 || respError.status === 403))
          {
            this.router.navigate(['/unauthorised']);
          }
        })));
      }));
    }
    else
    {
      return next.handle(req);
    }
  }


  private isCallToApi(url: string): boolean
  {
    let isApi = false;

    if (this.configurationService
      && this.configurationService.cbSettings
      && this.configurationService.cbSettings.calendarServiceUrl
      && this.configurationService.cbSettings.campaignServiceUrl
      && this.configurationService.cbSettings.dashboardServiceUrl
      && this.configurationService.cbSettings.reportsApiUrl
      && this.configurationService.cbSettings.eventsApiUrl
      && this.configurationService.cbSettings.toolsServiceUrl
      && this.configurationService.cbSettings.controlPanelServiceUrl
      && this.configurationService.cbSettings.fileUploadApiUrl
      && this.configurationService.cbSettings.adminServiceUrl
      && this.configurationService.cbSettings.authorisationServiceUrl
      && this.configurationService.cbSettings.loggingApiUrl
    )
    {
      isApi = url.trim().toLowerCase().includes(this.configurationService.cbSettings.calendarServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.campaignServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.dashboardServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.reportsApiUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.eventsApiUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.toolsServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.controlPanelServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.fileUploadApiUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.adminServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.authorisationServiceUrl.trim().toLowerCase())
        || url.trim().toLowerCase().includes(this.configurationService.cbSettings.loggingApiUrl.trim().toLowerCase());
    }

    return isApi;
  }















}
