<div class="notify-group-member-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="notify-group-member-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="notify-group-member-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Notification Group</mat-label>
        <mat-select formControlName="NotifyGroup" placeholder="Notification Group" [compareWith]="equalsNotifyGroup" (selectionChange)="notificationGroupChange($event)">
          <mat-option *ngFor="let notifygroup of administrationService.notifyGroups" [value]="notifygroup">{{notifygroup.Name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="!isNotifyTypeInterfacing">
      <mat-form-field>
        <mat-label>Platform</mat-label>
        <mat-select formControlName="Station" placeholder="Platform" [compareWith]="equalsStation">
          <mat-option *ngFor="let station of administrationService.stations" [value]="station">{{station.StationName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="!isNotifyTypeInterfacing">
      <mat-form-field>
        <mat-label>Property Type</mat-label>
        <mat-select formControlName="PropertyType" placeholder="All Property Types" [compareWith]="equalsPropertyType">
          <mat-option [value]="null">All</mat-option>
          <mat-option *ngFor="let propertyType of calendarsService.propertyTypes | filter:[{Disabled: false}]; trackBy: trackByPropertyTypes" [value]="propertyType">{{propertyType.PropertyTypeName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="Email" formControlName="Email" maxlength="150" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('Email') && !form.hasError('required')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <input matInput placeholder="Mobile" formControlName="Mobile" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="notify-group-member-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
