<div class="product-category-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="product-category-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="product-category-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="ProductCategoryName" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('ProductCategoryName') && !form.hasError('required')">
          Please enter a valid Name
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="product-category-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
