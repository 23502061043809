import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { ProductCategory } from "../administration.models";
import { AdministrationService } from '../administration.service';


@Component({
    templateUrl: './product-category-edit.component.html',
    selector: 'product-category-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './product-categories.component.scss'],
    providers: [MatTableCrudService]
})
export class ProductCategoryEditComponent extends MatTableCrudEditComponent<ProductCategoryEditComponent, ProductCategory>
{
    constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<ProductCategoryEditComponent>, @Inject(MAT_DIALOG_DATA) public data: ProductCategory, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
    {
        super(dialogRef, data);

        this.form = this.fb.group({
            Id: [this.data.Id],
            ProductCategoryName: [this.data.ProductCategoryName, Validators.required],
            Disabled: [this.data.Disabled],
            SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
        });

    }

    ngOnInit()
    {
        super.ngOnInit();

    }

    onSave(): void
    {
      let productCategory: any = this.form.value;

        if (productCategory)
        {
            if (this.isNew)
            {
                productCategory.Id = 0;

                if (productCategory.Disabled == null)
                {
                    productCategory.Disabled = false;
                }
            }

            productCategory[this.changedByField] = this.authorisationService.currentuser.Id;

            super.onSave(productCategory);
        }
    }

    get dialogHeaderText(): string
    {
        let headerText = "Edit Client Category";

        if (this.isNew)
        {
            headerText = "New Client Category";
        }
        else
        {
            if (this.showEditItemNameInHeaderText)
            {
                headerText = `${headerText} - ${this.data.ProductCategoryName}`;
            }
        }

        return headerText;
    }




    ngOnDestroy()
    {
        super.ngOnDestroy();

    }



}
