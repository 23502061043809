<div class="app-control" kendoTooltip tooltipClass="tooltip">
  <div class="app-header" [style.visibility]="!router?.url?.includes('sign') && !router?.url?.includes('login') ? 'visible' : 'hidden'">
    <a class="navbar-brand" [routerLink]="['/home']" style=" grid-column: 1; grid-row: 1; padding-left: 15px; border-bottom: 2px solid #3f51b5 !important;"><img src="assets/images/cb-logo-colour.svg" style="height: 55px; vertical-align: middle" alt="Campaign Boss" title="Campaign Boss" /></a>
    <nav-menu (sideBarToggle)="sidenav.toggle()" style="grid-column: 2; grid-row: 1;">
    </nav-menu>
  </div>
  <div class='app-content'>
    <h3 *ngIf="commonService.isLoggedIn && !router?.url?.includes('sign') && commonService.showPageHeader">{{commonService.currentpagetitle}}</h3>
    <mat-sidenav-container>
      <mat-sidenav #sidenav mode="over">
        <mat-nav-list class="menu">
          <div *ngFor="let routeCategory of appService.routeCategories" [ngClass]="{'selected-menu': commonService.currentpagecategory == routeCategory.CategoryName }">
            <button mat-button *ngIf="routeCategory.RoutesInCategory == 1" [routerLink]="['/' + routeCategory.Path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{routeCategory.Text}}</button>
            <div *ngIf="routeCategory.RoutesInCategory > 1">
              <button mat-button [matMenuTriggerFor]="menu">{{routeCategory.CategoryName}}</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor='let route of appService.routes | filter: [{visible: true}, {category: routeCategory.CategoryName}]' [routerLink]="['/' + route.path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{route.text}}</button>
              </mat-menu>
            </div>
          </div>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div class='app-footer'>
  </div>
  <div *ngIf="commonService.isNavigating || (commonService.isLoading | async)" class="app-spinner-container">
    <mat-spinner></mat-spinner>
    <label class="app-spinner-loading-label">{{commonService.loadingText}}</label>
  </div>
</div>
