import { Component, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { param } from "jquery";
import { CommonService } from "../../shared/services/common.service";
import { CalendarsService } from "../calendars/calendars.service";
import { ReportService } from "./report.service";
import { ReportParameter, ReportParameters } from "./reports.models";


@Component({
  selector: "report-parameters",
  templateUrl: './report-parameters.component.html',
  styleUrls: ['./report.component.scss'],
  providers: []
})
export class ReportParametersComponent implements OnInit, OnDestroy
{
  public form: UntypedFormGroup;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  @Input() public dragDisabled = true;

  constructor(
    public fb: UntypedFormBuilder,
    public calendarsService: CalendarsService,
    public reportService: ReportService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: ReportParameters = null,
    @Optional() private dialogRef: MatDialogRef<ReportParametersComponent> = null
  )
  {
    this.form = this.fb.group({
      ReportName: [this.data.ReportName],
      ReportTitle: [this.data.ReportTitle],
      StartDate: [],
      EndDate: [],
      StationIds: [this.data.StationIds],
      PropertyTypeIds: [this.data.PropertyTypeIds],
      CategoryIds: [this.data.CategoryIds],
      PropertyIds: [this.data.PropertyIds],
      Statuses: [this.data.Statuses],
      CategoryId: [this.data.CategoryId],
      PropertyId: [this.data.PropertyId]
    });
  }


  ngOnInit(): void
  {
    if (this.data)
    {
      if (this.data.StartDate)
      {
        this.data.StartDate = this.commonService.parseJsonDate(this.data.StartDate);
      }

      if (this.data.EndDate)
      {
        this.data.EndDate = this.commonService.parseJsonDate(this.data.EndDate);
      }

      this.form.controls["StartDate"].patchValue(this.data.StartDate, { emitEvent: false });
      this.form.controls["EndDate"].patchValue(this.data.EndDate, { emitEvent: false });
    }
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["EndDate"].patchValue(this.form.get("StartDate").value, { emitEvent: false });
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["StartDate"].patchValue(this.form.get("EndDate").value, { emitEvent: false });
    }
  }

  onCancelClick(): void
  {
    this.dialogRef.close();
  }

  onSave(): void
  {
    let params: ReportParameters = this.form.value;

    if (!this.showCategoryId)
    {
      params.CategoryId = 0;
    }
    if (!this.showCategoryIds)
    {
      params.CategoryIds = [];
    }
    if (!this.showEndDate)
    {
      params.EndDate = null;
    }
    if (!this.showPropertyId)
    {
      params.PropertyId = 0;
    }
    if (!this.showPropertyIds)
    {
      params.PropertyIds = [];
    }
    if (!this.showPropertyTypeIds)
    {
      params.PropertyTypeIds = [];
    }
    if (!this.showStartDate)
    {
      params.StartDate = null;
    }
    if (!this.showStationIds)
    {
      params.StationIds = [];
    }
    if (!this.showStatuses)
    {
      params.Statuses = [];
    }

    this.dialogRef.close(params);
  }

  ngOnDestroy()
  {

  }


  get showStartDate(): boolean
  {
    let show = true;

    show = this.data.ReportName == "CreditLine.trdp"
      || this.data.ReportName == "Avails.trdp"
      || this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "Fill.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showEndDate(): boolean
  {
    let show = true;

    show = this.data.ReportName == "CreditLine.trdp"
      || this.data.ReportName == "Avails.trdp"
      || this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "Fill.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showPropertyTypeIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "CreditLine.trdp"
      || this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "Avails.trdp"
      || this.data.ReportName == "Fill.trdp";

    return show;
  }

  get showStationIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "CreditLine.trdp"
      || this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "Avails.trdp"
      || this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "Fill.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showCategoryIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "Avails.trdp"
      || this.data.ReportName == "Fill.trdp";

    return show;
  }

  get showPropertyIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "Avails.trdp";

    return show;
  }

  get showStatuses(): boolean
  {
    let show = true;

    show = this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "Fill.trdp";

    return show;
  }

  get showCategoryId(): boolean
  {
    let show = true;

    show = this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showPropertyId(): boolean
  {
    let show = true;

    show = this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

}


