import { getCurrencySymbol } from '@angular/common';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HubConnectionState } from '@microsoft/signalr';
import { NotificationService } from '@progress/kendo-angular-notification';
import { addMinutes, differenceInCalendarDays, endOfMonth, getDay, getISOWeek, getISOWeeksInYear, isValid } from 'date-fns';
import { Subject } from 'rxjs';
import { ImportProgress } from '../../components/administration/control-panel/control-panel.models';
import { AppSignalRService } from './app-signalr.service';
import { ConfigurationService } from './configuration.service';
import { DataHandlerService } from './data-handler.service';


export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

@Injectable({ providedIn: 'root' })

export class CommonService
{
  private _currentlyProcessingFunctions: string[] = [];
  private _currentpage = "";
  private _currentpagetitle = "";
  private _currentpagecategory = "";
  private _showPageHeader = true;
  private _browserRefresh = false;
  private _isLoggedIn = false;
  private _manuallyLoggedOut = false;
  private _modifierKeyPressed = false;
  private _previouspage = "";
  private _appPath = "";
  private _appName = "";
  private _loadingText = "";
  private _maxSelectedCalendarStations = 0;
  private _isNavigating: boolean = false;

  private messageHorizontalPosition: any = this.configurationService.cbSettings.messageHorizontalPosition;
  private messageVerticalPosition: any = this.configurationService.cbSettings.messageVerticalPosition;
  private messageDuration: number = this.configurationService.cbSettings.messageDurationInMilliseconds;

  public isLoading: Subject<boolean> = new Subject<boolean>();

  public readonly generalNotificationGroupTypeId: number = 1;
  public readonly interfacingNotificationGroupTypeId: number = 7;

  public readonly stationPlatformTypeId: number = 1;
  public readonly vendorPlatformTypeId: number = 2;

  @Output() public isNavigatingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public loadingTextChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dataHandler: DataHandlerService,
    private configurationService: ConfigurationService,
    private notificationService: NotificationService,
    public appSignalRService: AppSignalRService)
  {
    this.isLoading.next(false);

    this.isLoading.subscribe((loading: boolean) =>
    {
      if (!loading)
      {
        this.loadingText = "";
      }
    });
  }


  initialiseHubs(currentUserId: number)
  {
    if (currentUserId)
    {
      if (!this.appSignalRService.hubConnection)
      {
        this.appSignalRService.createConnection(this.configurationService.cbSettings.appHubUrl, currentUserId);
      }

      if (this.appSignalRService.hubConnection.state == HubConnectionState.Disconnected)
      {
        this.appSignalRService.startConnection();
      }
    }
  }

  get isInterfacing(): boolean
  {
    let hasAnyInterfacing = false;

    if (this.configurationService && this.configurationService.cbSettings)
    {
      hasAnyInterfacing = this.configurationService.cbSettings.isInterfacingActivities
        || this.configurationService.cbSettings.isInterfacingClientCategories
        || this.configurationService.cbSettings.isInterfacingClients
        || this.configurationService.cbSettings.isInterfacingRates
        || this.configurationService.cbSettings.isInterfacingUsers;
    }

    return hasAnyInterfacing;
  }

  get today(): Date
  {
    return new Date(new Date().toDateString());
  }

  get appPath(): string
  {
    if (!this._appPath)
    {
      this.getAppPath()
        .then((res) =>        
        {
          this._appPath = res;
        })
        .catch((err: any) =>
        {

        });
    }

    return this._appPath;
  }

  get appName(): string
  {
    if (!this._appName)
    {
      this.getAppName()
        .then((res) =>        
        {
          this._appName = res;
        })
        .catch((err: any) =>
        {

        });
    }

    return this._appName;
  }

  get loadingText(): string
  {
    return this._loadingText;
  }
  set loadingText(value: string)
  {
    this._loadingText = value;

    this.loadingTextChanged.emit(true);
  }


  public getLocale()
  {
    let locale: any = null;

    if (localStorage && localStorage.length > 0 && localStorage.getItem(CURRENT_LOCALE))
    {
      locale = JSON.parse(localStorage.getItem(CURRENT_LOCALE));
    }

    return locale;
  }

  public getLocaleName()
  {
    let locale: string = this.configurationService?.cbSettings?.systemLocale;

    if (localStorage && localStorage.length > 0 && localStorage.getItem(CURRENT_LOCALE_NAME))
    {
      locale = localStorage.getItem(CURRENT_LOCALE_NAME);
    }

    return locale;
  }

  public getDateFormatPattern (locale: string)
  {
    return new Intl.DateTimeFormat(locale).formatToParts(new Date())
      .map(this.getPatternForPart)
      .join('');
  };

  public getPatternForPart(part: Intl.DateTimeFormatPart) 
  {
    switch (part.type)
    {
      case 'day':
        return 'd'.repeat(part.value.length);
      case 'month':
        return 'M'.repeat(part.value.length);
      case 'year':
        return 'y'.repeat(part.value.length);
      case 'literal':
        return part.value;
      default:
        console.log('Unsupported date part', part);
        return '';
    }
  };

  public sendCalendarHtml(data: any): void
  {
    if (this.appSignalRService && this.appSignalRService.hubConnection)
    {
      this.appSignalRService.run('SendCalendarHtmlAsync', data);
    }
  }

  public refreshDashboard(): void
  {
    if (this.appSignalRService && this.appSignalRService.hubConnection)
    {
      this.appSignalRService.run('RefreshDashboardDataMessageAsync');
    }
  }

  get reportNewTab(): boolean
  {
    let reportvalue = true;

    if (this.configurationService && this.configurationService.cbSettings)
    {
      reportvalue = this.configurationService.cbSettings.reportNewTab;
    }

    return reportvalue;
  }

  saveScrollPosition(key: string, scrollPosition: number)
  {
    if (!scrollPosition || scrollPosition < 0)
    {
      scrollPosition = 0;
    }

    localStorage.setItem(key, scrollPosition.toString());
  }

  getScrollPosition(key: string)
  {
    let position = 0;

    let savedPosition = parseInt(localStorage.getItem(key));

    if (savedPosition)
    {
      position = savedPosition;
    }

    if (position < 0)
    {
      position = 0;
    }

    return position;
  }

  getSymbolForCurrency(format?: 'wide' | 'narrow'): string
  {
    if (!format)
    {
      format = 'narrow';
    }

    return getCurrencySymbol(this.getCurrencyCodeFromLocale(), format);
  }

  getCurrencyCodeFromLocale(locale?: string)
  {
    if (!locale)
    {
      locale = this.getLocaleName();
    }

    switch (locale.toLowerCase())
    {
      case "en-gb":
        return "GBP";
      case "en-us":
        return "USD";
      case "en-au":
        return "AUD";
      case "en-nz":
        return "NZD";
      case "en-ca":
      case "fr-ca":
        return "CAD";
      case "de-at":
      case "de-de":
      case "de-lu":
      case "fr-be":
      case "fr-fr":
      case "fr-lu":
      case "en-ie":
      case "el":
      case "fi":
      case "et":
      case "nl-be":
      case "nl-nl":
      case "it-it":
      case "pt-pt":
      case "es-es":
        return "EUR";
      case "it-ch":
      case "fr-ch":
      case "de-ch":
        return "CHF";
      case "ja":
        return "JPY";
      case "zh-cn":
        return "CNY";
      case "zh-hk":
        return "HKD";
      case "en-sg":
      case "zh-sg":
        return "SGD";
      case "zh-tw":
        return "TWD";
      case "ko":
        return "KRW";
      case "en-za":
        return "ZAR";
    }

    return "AUD";
  }



  get maxSelectedCalendarStations(): number
  {
    if (this._maxSelectedCalendarStations < 1 && this.configurationService && this.configurationService.cbSettings)
    {
      this._maxSelectedCalendarStations = this.configurationService.cbSettings.maxSelectedCalendarStations;
    }

    return this._maxSelectedCalendarStations;
  }
  set maxSelectedCalendarStations(value: number)
  {
    this._maxSelectedCalendarStations = value;
  }


  setFunctionProcessingState(functionName: string, isProcessing: boolean)
  {
    if (isProcessing)
    {
      if (!this.isFunctionCurrentlyProcessing(functionName))
      {


        this.currentlyProcessingFunctions.push(functionName);
      }
    }
    else
    {
      if (this.currentlyProcessingFunctions && this.currentlyProcessingFunctions.length > 0)
      {
        this.currentlyProcessingFunctions = this.currentlyProcessingFunctions.filter(f => f != functionName);


      }
    }
  }

  isFunctionCurrentlyProcessing(functionName: string): boolean
  {
    return this.currentlyProcessingFunctions.some(f => f == functionName);
  }


  get currentlyProcessingFunctions(): string[]
  {
    if (!this._currentlyProcessingFunctions)
    {
      this._currentlyProcessingFunctions = [];
    }

    return this._currentlyProcessingFunctions;
  }
  set currentlyProcessingFunctions(value: string[])
  {
    this._currentlyProcessingFunctions = value;
  }

  get modifierKeyPressed(): boolean
  {
    return this._modifierKeyPressed;
  }
  set modifierKeyPressed(value: boolean)
  {
    this._modifierKeyPressed = value;
  }

  get browserRefresh(): boolean
  {
    return this._browserRefresh;
  }
  set browserRefresh(value: boolean)
  {
    this._browserRefresh = value;
  }

  get isLoggedIn(): boolean
  {
    return this._isLoggedIn;
  }
  set isLoggedIn(value: boolean)
  {
    this._isLoggedIn = value;
  }

  get manuallyLoggedOut(): boolean
  {
    return this._manuallyLoggedOut;
  }
  set manuallyLoggedOut(value: boolean)
  {
    this._manuallyLoggedOut = value;
  }

  get previouspage(): string
  {
    if (localStorage.getItem("previouspage"))
    {
      this._previouspage = localStorage.getItem("previouspage");
    }

    return this._previouspage;
  }
  set previouspage(value: string)
  {
    if (this.isLoggedIn)
    {
      localStorage.setItem("previouspage", value);
      this._previouspage = value;
    }

    if (!this._previouspage || !this.isLoggedIn)
    {
      this._previouspage = "";
    }
  }

  get currentpage(): string
  {
    if (localStorage.getItem("currentpage"))
    {
      this._currentpage = localStorage.getItem("currentpage");
    }

    return this._currentpage;
  }
  set currentpage(value: string)
  {
    let currentpage = "/home";

    if (this.isLoggedIn
      && value
      && value != 'signin-callback'
      && value != 'signout-callback'
      && value != 'unauthorised'
      && value != 'login'
      && value != 'register')
    {
      currentpage = value;
    }

    localStorage.setItem("currentpage", currentpage);
    this._currentpage = currentpage;
  }

  get currentpagetitle(): string
  {
    return this._currentpagetitle;
  }
  set currentpagetitle(value: string)
  {
    this._currentpagetitle = value;
  }

  get currentpagecategory(): string
  {
    return this._currentpagecategory;
  }
  set currentpagecategory(value: string)
  {
    this._currentpagecategory = value;
  }

  get showPageHeader(): boolean
  {
    return this._showPageHeader;
  }
  set showPageHeader(value: boolean)
  {
    this._showPageHeader = value;
  }

  public SubscribeToMessages()
  {
    this.isLoading.next(true);

    this.appSignalRService.hubConnection.on('SendInformationToUser', (data: any) =>
    {
      try
      {
        if (data)
        {
          let ip: ImportProgress = JSON.parse(data);

          if (ip)
          {
            this.loadingText = ip.Message;

            if (!ip.Continue || ip.Completed)
            {
              this.UnsubscribeToMessages(ip);
            }
          }
          else
          {
            this.UnsubscribeToMessages(null);
          }
        }
        else
        {
          this.UnsubscribeToMessages(null);
        }
      }
      catch (e: any)
      {
        this.UnsubscribeToMessages(null);
      }
    });
  }

  public UnsubscribeToMessages(ip: ImportProgress)
  {
    this.isLoading.next(false);

    this.appSignalRService.hubConnection.off('SendInformationToUser');

    this.loadingText = "";

    if (ip && ip.Completed)
    {
      if (ip.CompletionMessage)
      {
        this.notify(ip.Title, ip.CompletionMessage, ip.Successful);
      }
      else
      {
        this.notify(ip.Title, ip.Message, ip.Successful);
      }
    }
  }


  public notify(title: string, msg: string, isSuccessful: boolean)
  {
    if (isSuccessful)
    {
      this.notifySuccess(title, msg);
    }
    else
    {
      this.notifyError(title, msg);
    }
  }

  notifyFailure(title: string, content: string, exceptionMessage: string, validationExceptionMessage: string)
  {
    let showExceptionMessage = false;
    let showValidationExceptionMessage = false;

    if (this.configurationService && this.configurationService.cbSettings)
    {
      if (this.configurationService.cbSettings.showExceptionMessages && exceptionMessage)
      {
        showExceptionMessage = this.configurationService.cbSettings.showExceptionMessages;
      }

      if (this.configurationService.cbSettings.showValidationExceptionMessages && validationExceptionMessage)
      {
        showValidationExceptionMessage = this.configurationService.cbSettings.showValidationExceptionMessages;
      }
    }

    if (showExceptionMessage && exceptionMessage)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-error",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "error", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }

    if (showValidationExceptionMessage && validationExceptionMessage)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-info",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "info", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }

    if (content && !showExceptionMessage && !showValidationExceptionMessage)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-error",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "error", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }
  }

  notifyAlert(title: string, content: string)
  {
    if (content)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-warning",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "warning", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }
  }

  notifyError(title: string, content: string)
  {
    if (content)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-error",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "error", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }
  }

  notifyInfo(title: string, content: string)
  {
    if (content)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-info",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "info", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }
  }

  notifySuccess(title: string, content: string)
  {
    if (content)
    {
      this.notificationService.show({
        content: content,
        cssClass: "message-success",
        animation: { type: "slide", duration: 400 },
        position: { horizontal: this.messageHorizontalPosition, vertical: this.messageVerticalPosition },
        type: { style: "success", icon: false },
        closable: false,
        hideAfter: this.messageDuration,
      });
    }
  }

  public numberArrayEquals(a: number[], b: number[])
  {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  public cloneObject(source: any, destination: any): any
  {
    if (source && destination)
    {
      Object.keys(source).map(key => 
      {
        if (source[key] && destination[key])
        {
          if (source[key] instanceof Object && !Array.isArray(source[key]))
          {
            destination[key] = this.cloneObject(source[key], destination[key]);
          }
          else
          {
            destination[key] = source[key];
          }
        }
      });
    }

    return destination;
  }

  CloneArray(sourceArray: Array<any>)
  {
    var arr = sourceArray.slice(0);

    for (var i = 0; i < sourceArray.length; i++)
    {
      if (sourceArray[i].clone)
      {
        //recursion
        arr[i] = sourceArray[i].clone();
      }
    }
    return arr;
  }

  public formatBytes(bytes: any, decimals: any, binaryUnits: any)
  {
    if (bytes == 0)
    {
      return '0 Bytes';
    }
    var unitMultiple = (binaryUnits) ? 1024 : 1000;
    var unitNames = (unitMultiple === 1024) ? // 1000 bytes in 1 Kilobyte (KB) or 1024 bytes for the binary version (KiB)
      ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] :
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
    return parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)) + ' ' + unitNames[unitChanges];
  }

  public getDayInstanceInMonthForDate(date: Date): number
  {
    let firstDayOfMonth: Date = new Date(date.getFullYear(), date.getMonth(), 1);
    let instanceOfDayInMonth: number = 0;

    let dayNoDate: number = getDay(date);

    for (var d = firstDayOfMonth; d <= date; d.setDate(d.getDate() + 1))
    {
      if (getDay(d) == dayNoDate)
      {
        instanceOfDayInMonth++;
      }
    }

    return instanceOfDayInMonth;
  }

  public getISOStringFromDate(date: Date)
  {
    let monthString: string = (date.getMonth() + 1).toString();

    if (monthString.length == 1)
    {
      monthString = "0" + monthString;
    }

    let dayString: string = date.getDate().toString();

    if (dayString.length == 1)
    {
      dayString = "0" + dayString;
    }

    return date.getFullYear().toString() + "-" + monthString + "-" + dayString;
  }

  public myadjustTimezone(date: any)
  {
    if (typeof date === 'string' || date instanceof String)
    {
      date = new Date(date.toString());
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
  }

  public myunadjustTimezone(date: any)
  {
    if (typeof date === 'string' || date instanceof String)
    {
      date = new Date(date.toString());
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  }

  public adjustToUtcDateOnly(date: any)
  {
    if (typeof date === 'string' || date instanceof String)
    {
      date = new Date(date.toString());
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
  }

  public formatDateForService(value: any, dateOnly: boolean = false)
  {
    if (dateOnly)
    {
      value = new Date(value.toDateString())
    }

    if (this.isValidDate(value))
    {
      //Adjust date by adding minutes offset so date is always saved as the day
      let offset: number = new Date(value).getTimezoneOffset();

      value = addMinutes(this.parseJsonDate(value), -(offset));

      if (value.toString() == 'Invalid Date')
      {
        value = undefined;
      }
    }
    else
    {
      value = undefined;
    }

    return value;
  }

  dateToWebApiString(date: Date): string
  {
    //month is zero indexed
    return date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString();
  }

  parseJsonDate(dateItemToParse: any, unadjustTime: boolean = false): Date
  {
    if (this.isValidDate(dateItemToParse))
    {
      if (unadjustTime)
      {
        return this.myunadjustTimezone(new Date(dateItemToParse));
      }
      else
      {
        return new Date(dateItemToParse);
      }
    }

    if (dateItemToParse && dateItemToParse.length > 6)
    {
      if (unadjustTime)
      {
        return this.myunadjustTimezone(new Date(parseInt(dateItemToParse.toString().substr(6))));
      }
      else
      {
        return new Date(parseInt(dateItemToParse.toString().substr(6)));
      }
    }
    else
    {
      return dateItemToParse;
    }
  }

  ensureDateIsNotOnWeekend(date: Date): Date
  {
    var nonWeekendDate: Date = date;

    if (date != null && Object.prototype.toString.call(date) === "[object Date]")
    {
      switch (date.getDay())
      {
        case DayOfWeek.Friday:
          nonWeekendDate.setDate(date.getDate() + 3);
          break;
        case DayOfWeek.Saturday:
          nonWeekendDate.setDate(date.getDate() + 2);
          break;
        case DayOfWeek.Sunday:
          nonWeekendDate.setDate(date.getDate() + 1);
          break;
      }
    }
    return nonWeekendDate;
  }

  ensureDateIsNotOnWeekendBack(date: Date): Date
  {
    var nonWeekendDate: Date = date;

    if (date != null && Object.prototype.toString.call(date) === "[object Date]")
    {
      switch (date.getDay())
      {
        case DayOfWeek.Sunday:
          nonWeekendDate.setDate(date.getDate() - 3);
          break;
        case DayOfWeek.Saturday:
          nonWeekendDate.setDate(date.getDate() - 2);
          break;
        case DayOfWeek.Friday:
          nonWeekendDate.setDate(date.getDate() - 1);
          break;
      }
    }
    return nonWeekendDate;
  }

  getDaysUntilEndOfMonth(date: Date, adjustForSmallestMonth: boolean = true): number
  {
    let daysFromDateToLastDayOfMonth: number = differenceInCalendarDays(endOfMonth(date), date);

    if (adjustForSmallestMonth && daysFromDateToLastDayOfMonth > 28)
    {
      //adjust so not greater than smallest possible month
      daysFromDateToLastDayOfMonth = 28;
    }

    return daysFromDateToLastDayOfMonth;
  }

  openInNewTab(url: string)
  {
    var win = window.open(url, '_blank', 'noopener');
  }

  isValidDate(d: any)
  {
    if (!d)
    {
      return false;
    }

    return isValid(new Date(d));
  }

  getWeekNumber(d: any)
  {
    return getISOWeek(new Date(d));
  }

  getWeeksInYear(year: number)
  {
    return getISOWeeksInYear(new Date(year, 1, 11));
  }


  get isNavigating(): boolean
  {
    return this._isNavigating;
  }
  set isNavigating(value: boolean)
  {
    this._isNavigating = value;
    this.isNavigatingChanged.emit(value);
  }

  get currentPageHasOwnLoader(): boolean
  {
    let hasOwnLoader = false;

    hasOwnLoader = !this.currentpage || this.currentpage == "/" || this.currentpage == "/calendar";

    return hasOwnLoader;
  }


  getAppName()
  {
    return this.dataHandler.getHttpPromise<any>(`api/common/GetAppName`, false, false);
  }

  getAppPath()
  {
    return this.dataHandler.getHttpPromise<any>(`api/common/GetAppPath`, false, false);
  }





}


export enum DayOfWeek
{
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}
