<div class="report-parameters-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="report-parameters-header">
    <h1 mat-dialog-title class="dialog-header">{{form.get('ReportTitle').value}} Report Parameters</h1>
  </div>
  <div class="report-parameters-content">
    <mat-form-field style="width:unset" *ngIf="showStartDate">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Start Date" (dateChange)="startDateChange($event)" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
      <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width:unset" *ngIf="showEndDate">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="End Date" [min]="form.get('StartDate').value" (dateChange)="endDateChange($event)" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
      <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
    </mat-form-field>
    <kendo-floatinglabel text="Stations" *ngIf="showStationIds">
      <kendo-multiselect [autoClose]="false" 
                         [data]="calendarsService.stations"
                         [textField]="'StationName'"
                         [valueField]="'Id'"
                         [valuePrimitive]="true"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="StationIds"
                         placeholder="All Stations"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Property Types" *ngIf="showPropertyTypeIds">
      <kendo-multiselect [autoClose]="false" 
                         [data]="calendarsService.propertyTypes"
                         [textField]="'PropertyTypeName'"
                         [valueField]="'Id'"
                         [valuePrimitive]="true"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="PropertyTypeIds"
                         placeholder="All Property Types"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Categories" *ngIf="showCategoryIds">
      <kendo-multiselect [autoClose]="false" 
                         [data]="calendarsService.categories"
                         [textField]="'CategoryName'"
                         [valueField]="'Id'"
                         [valuePrimitive]="true"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="CategoryIds"
                         placeholder="All Categories"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Properties" *ngIf="showPropertyIds">
      <kendo-multiselect [autoClose]="false" 
                         [data]="calendarsService.properties"
                         [textField]="'IdAndPropertyName'"
                         [valueField]="'Id'"
                         [valuePrimitive]="true"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="PropertyIds"
                         placeholder="All Properties"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Statuses" *ngIf="showStatuses">
      <kendo-multiselect [autoClose]="false" 
                         [data]="calendarsService.statuses"
                         [textField]="'Name'"
                         [valueField]="'Value'"
                         [valuePrimitive]="true"
                         [size]="'small'"
                         [fillMode]="'flat'"
                         formControlName="Statuses"
                         placeholder="All Statuses"
                         [kendoDropDownFilter]="filterSettings">
      </kendo-multiselect>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Category" *ngIf="showCategoryId">
      <kendo-combobox
                      [data]="calendarsService.categories"
                      formControlName="CategoryId"
                      [kendoDropDownFilter]="filterSettings"
                      [textField]="'CategoryName'"
                      [valueField]="'Id'"
                      [valuePrimitive]="true"
                      [size]="'small'"
                      [fillMode]="'flat'"
                      [placeholder]="'Please Select Category'">
      </kendo-combobox>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="Property" *ngIf="showPropertyId">
      <kendo-combobox 
                      [data]="calendarsService.properties"
                      formControlName="PropertyId"
                      [kendoDropDownFilter]="filterSettings"
                      [textField]="'IdAndPropertyName'"
                      [valueField]="'Id'"
                      [valuePrimitive]="true"
                      [size]="'small'"
                      [fillMode]="'flat'"
                      [placeholder]="'Please Select Property'">
      </kendo-combobox>
    </kendo-floatinglabel>
  </div>
  <div class="report-parameters-footer">
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
