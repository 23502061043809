import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CbSharedModule } from '../cb-shared.module';
import { ClientEditComponent } from '../components/administration/clients/client-edit.component';
import { ClientsComponent } from '../components/administration/clients/clients.component';
import { ContactTypeEditComponent } from '../components/administration/contact-types/contact-type-edit.component';
import { ContactTypesComponent } from '../components/administration/contact-types/contact-types.component';
import { ControlPanelComponent } from '../components/administration/control-panel/control-panel.component';
import { DayPartEditComponent } from '../components/administration/dayparts/daypart-edit.component';
import { DayPartsComponent } from '../components/administration/dayparts/dayparts.component';
import { DepartmentEditComponent } from '../components/administration/departments/department-edit.component';
import { DepartmentsComponent } from '../components/administration/departments/departments.component';
import { ExtensionEditComponent } from '../components/administration/extensions/extension-edit.component';
import { ExtensionsComponent } from '../components/administration/extensions/extensions.component';
import { JobEditComponent } from '../components/administration/jobs/job-edit.component';
import { JobsComponent } from '../components/administration/jobs/jobs.component';
import { MechanicTypeEditComponent } from '../components/administration/mechanic-types/mechanic-type-edit.component';
import { MechanicTypesComponent } from '../components/administration/mechanic-types/mechanic-types.component';
import { NotifyGroupMemberEditComponent } from '../components/administration/notify-group-members/notify-group-member-edit.component';
import { NotifyGroupMembersComponent } from '../components/administration/notify-group-members/notify-group-members.component';
import { NotifyGroupEditComponent } from '../components/administration/notify-groups/notify-group-edit.component';
import { NotifyGroupsComponent } from '../components/administration/notify-groups/notify-groups.component';
import { OnlineDependencyTypeEditComponent } from '../components/administration/online-dependency-types/online-dependency-type-edit.component';
import { OnlineDependencyTypesComponent } from '../components/administration/online-dependency-types/online-dependency-types.component';
import { ProductCategoriesComponent } from '../components/administration/product-categories/product-categories.component';
import { ProductCategoryEditComponent } from '../components/administration/product-categories/product-category-edit.component';
import { RoleEditComponent } from '../components/administration/roles/role-edit.component';
import { RolesComponent } from '../components/administration/roles/roles.component';
import { StationTypeEditComponent } from '../components/administration/station-types/station-type-edit.component';
import { StationTypesComponent } from '../components/administration/station-types/station-types.component';
import { StationEditComponent } from '../components/administration/stations/station-edit.component';
import { StationsComponent } from '../components/administration/stations/stations.component';
import { UserEditComponent } from '../components/administration/users/user-edit.component';
import { UsersComponent } from '../components/administration/users/users.component';



@NgModule({
  declarations: [
    UserEditComponent,
    UsersComponent,
    ContactTypesComponent,
    ContactTypeEditComponent,
    ControlPanelComponent,
    ExtensionEditComponent,
    ExtensionsComponent,
    OnlineDependencyTypesComponent,
    OnlineDependencyTypeEditComponent,
    NotifyGroupEditComponent,
    NotifyGroupsComponent,
    NotifyGroupMemberEditComponent,
    NotifyGroupMembersComponent,
    JobsComponent,
    JobEditComponent,
    ClientsComponent,
    ClientEditComponent,
    DepartmentsComponent,
    DepartmentEditComponent,
    DayPartEditComponent,
    DayPartsComponent,
    MechanicTypesComponent,
    MechanicTypeEditComponent,
    RolesComponent,
    RoleEditComponent,
    StationTypesComponent,
    StationTypeEditComponent,
    StationsComponent,
    StationEditComponent,
    ProductCategoriesComponent,
    ProductCategoryEditComponent

  ],
  imports: [
    CommonModule,
    CbSharedModule
  ]
})
export class AdministrationModule { }
