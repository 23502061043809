import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { Role } from "../../../shared/models/common.models";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { AdministrationService } from '../administration.service';
import { RoleEditComponent } from "./role-edit.component";


@Component({
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class RolesComponent extends MatTableCrudComponent<Role>
{
  public RoleNameFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      RoleEditComponent,
      RoleEditComponent,
      RoleEditComponent,
      "CreateRole",
      "DeleteRole",
      "GetRoles",
      "UpdateRole",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'RoleName'];
    this.filteredValues = [{ Field: "RoleName", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "50rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddRoles);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditRoles);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteRoles);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, role: Role)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this role (${role.RoleName})?`;
    this.deleteHeaderText = "Delete Role?";

    super.deleteItem(event, role);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

