<mat-card appearance="outlined" class="mat-crud-narrow">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
      Event Categories
    </mat-card-title>
    <div class="card-header-text-icons">
      Reload data:
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="Id">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Id</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Name">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Name
          </div>
          <mat-form-field>
            <mat-label>Search Name</mat-label>
            <input matInput class="form-field" [formControl]="NameFilter" placeholder="Search Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.Name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ColourName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Colour
          </div>
          <mat-form-field>
            <mat-label>Search Colour</mat-label>
            <input matInput class="form-field" [formControl]="ColourNameFilter" placeholder="Search Colour" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.ColourName}}</mat-cell>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
          <div class="card-header-text-icons">
            Reload data:
            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <button mat-icon-button color="accent" (click)="addNew($event)" title="add" *ngIf="CanAdd">
            <i class="material-icons">add_box</i>
          </button>
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row; let i=index;">
          <button mat-icon-button color="accent" (click)="editItem($event, row)" title="edit" *ngIf="CanEdit">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>

          <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete" *ngIf="CanDelete" style="display:none">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource?.data?.length == 0 ? '' : 'none'">
      No results
    </div>

  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator
                   [length]="dataSource?.filteredData?.length"
                   [pageIndex]="0"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   showFirstLastButtons="true">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
