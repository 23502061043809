import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { PropertyType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { PropertyTypeEditComponent } from "./property-type-edit.component";



@Component({
  templateUrl: './property-types.component.html',
  styleUrls: ['./property-types.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class PropertyTypesComponent extends MatTableCrudComponent<PropertyType>
{
  public PropertyTypeNameFilter: any;
  public AirtimeCreditsTextFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      PropertyTypeEditComponent,
      PropertyTypeEditComponent,
      PropertyTypeEditComponent,
      "CreatePropertyType",
      "DeletePropertyType",
      "GetPropertyTypes",
      "UpdatePropertyType",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'PropertyTypeName', 'AirtimeCreditsText'];
    this.filteredValues = [{ Field: "PropertyTypeName", Value: "", Path: "", DataType: FilterDataType.String }, { Field: "AirtimeCreditsText", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    //Set to false because Property Types are system objects and shouldn't be added by users only limited editing.
    this.CanAdd = false; //this.authorisationService.hasPermission(this.authorisationService.Permissions.AddPropertyTypes);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditPropertyTypes);
    this.CanDelete = false; //this.authorisationService.hasPermission(this.authorisationService.Permissions.DeletePropertyTypes);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, propertytype: PropertyType)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this property type (${propertytype.PropertyTypeName})?`;
    this.deleteHeaderText = "Delete Property Type?";

    super.deleteItem(event, propertytype);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

