import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { PropertyType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';


@Component({
  templateUrl: './property-type-edit.component.html',
  selector: 'property-type-edit-form',
  styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './property-types.component.scss'],
  providers: [MatTableCrudService]
})
export class PropertyTypeEditComponent extends MatTableCrudEditComponent<PropertyTypeEditComponent, PropertyType>
{
  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<PropertyTypeEditComponent>, @Inject(MAT_DIALOG_DATA) public data: PropertyType, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [{ value: this.data.Id, disabled: true }],
      PropertyTypeName: [{ value: this.data.PropertyTypeName, disabled: true }, Validators.required],
      AirtimeCreditsText: [this.data.AirtimeCreditsText, Validators.required],
      CanHaveFirstRightOfRefusal: [this.data.CanHaveFirstRightOfRefusal],
      CanAppearInWhatsOnCalendar: [this.data.CanAppearInWhatsOnCalendar],
      IsOnline: [{ value: this.data.IsOnline, disabled: true }],
      Disabled: [{ value: this.data.Disabled, disabled: false }],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    let propertyType: any = this.form.getRawValue();  //use getRawValue to include disabled controls as well

    if (propertyType)
    {
      if (this.isNew)
      {
        propertyType.Id = 0;

        if (propertyType.Disabled == null)
        {
          propertyType.Disabled = false;
        }
      }

      propertyType[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(propertyType);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Property Type";

    if (this.isNew)
    {
      headerText = "New Property Type";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.PropertyTypeName}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
