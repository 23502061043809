import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { isValid } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ServerPagingParameters } from '../../models/common.models';
import { CommonService } from '../../services/common.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DataHandlerService } from '../../services/data-handler.service';

@Injectable()
export class MatTableCrudService extends BehaviorSubject<any[]>
{
  public idField = "Id";

  public OnRead: EventEmitter<any> = new EventEmitter<any>();
  public OnServerRead: EventEmitter<any> = new EventEmitter<any>();
  public OnDataFetched: EventEmitter<any> = new EventEmitter<any>();
  public OnSaved: EventEmitter<any> = new EventEmitter<any>();


  public dataChange: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  private dialogData: any;

  private _getParameters: Parameter[] = [];
  private _postPayload: any = null;

  private _ServerPaging = false;

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _readActionMethod = "";
  private _createActionMethod = "";
  private _destroyActionMethod = "";
  private _updateActionMethod = "";
  private _readServiceUrl = "";
  private _createServiceUrl = "";
  private _destroyServiceUrl = "";
  private _updateServiceUrl = "";
  private _useReviver = false;
  private _prepareDataForSave = false;

  get getParameters(): Parameter[]
  {
    return this._getParameters;
  }
  set getParameters(value: Parameter[])
  {
    this._getParameters = value;

    if (!this.data || this.data.length == 0)
    {
      if (this.ServerPaging)
      {
        const spp: ServerPagingParameters = new ServerPagingParameters();

        spp.Take = 10;
        spp.Skip = 0;

        this.serverread(spp);
      }
      else
      {
        this.read(null);
      }
    }
  }

  get postPayload(): any
  {
    return this._postPayload;
  }
  set postPayload(value: any)
  {
    this._postPayload = value;

    if (!this.data || this.data.length == 0)
    {
      if (this.ServerPaging)
      {
        const spp: ServerPagingParameters = new ServerPagingParameters();

        spp.Take = 10;
        spp.Skip = 0;

        this.serverread(spp);
      }
      else
      {
        this.read(null);
      }
    }
  }

  get ServerPaging(): boolean
  {
    return this._ServerPaging;
  }
  set ServerPaging(value: boolean)
  {
    this._ServerPaging = value;
  }


  get readActionMethod(): string
  {
    return this._readActionMethod;
  }
  set readActionMethod(value: string)
  {
    this._readActionMethod = value;
  }

  get createActionMethod(): string
  {
    return this._createActionMethod;
  }
  set createActionMethod(value: string)
  {
    this._createActionMethod = value;
  }

  get destroyActionMethod(): string
  {
    return this._destroyActionMethod;
  }
  set destroyActionMethod(value: string)
  {
    this._destroyActionMethod = value;
  }

  get updateActionMethod(): string
  {
    return this._updateActionMethod;
  }
  set updateActionMethod(value: string)
  {
    this._updateActionMethod = value;
  }

  get readServiceUrl(): string
  {
    return this._readServiceUrl;
  }
  set readServiceUrl(value: string)
  {
    this._readServiceUrl = value;
  }

  get createServiceUrl(): string
  {
    return this._createServiceUrl;
  }
  set createServiceUrl(value: string)
  {
    this._createServiceUrl = value;
  }

  get destroyServiceUrl(): string
  {
    return this._destroyServiceUrl;
  }
  set destroyServiceUrl(value: string)
  {
    this._destroyServiceUrl = value;
  }

  get updateServiceUrl(): string
  {
    return this._updateServiceUrl;
  }
  set updateServiceUrl(value: string)
  {
    this._updateServiceUrl = value;
  }


  constructor(public http: HttpClient, public commonService: CommonService, public configurationService: ConfigurationService, public dataHandler: DataHandlerService)
  {
    super([]);

    this.dialogData
  }

  get data(): any
  {
    return this.dataChange.value;
  }

  getDialogData()
  {
    return this.dialogData;
  }

  get useReviver(): boolean
  {
    return this._useReviver;
  }
  set useReviver(value: boolean)
  {
    this._useReviver = value;
  }

  get prepareDataForSave(): boolean
  {
    return this._prepareDataForSave;
  }
  set prepareDataForSave(value: boolean)
  {
    this._prepareDataForSave = value;
  }

  public serverread(spp: ServerPagingParameters)
  {
    this.OnServerRead.emit();

    if (this.readServiceUrl && this._readActionMethod)
    {
      this.serverfetch(spp)
        .pipe(
          tap((data: any) =>
          {
            this.dataChange.next(data);
          })
        )
        .subscribe((data: any) => 
        {
          this.OnDataFetched.emit(data);
          super.next(data);
        }),
        (error: any) =>
        {
          this.OnDataFetched.emit([]);
        };
    }
  }

  public read(state: any)
  {
    this.OnRead.emit();

    if (this.readServiceUrl && this._readActionMethod)
    {
      if (state)
      {
        this.getParameters = state;
      }

      this.fetch()
        .pipe(
          tap((data: any) =>
          {
            this.dataChange.next(data);
          })
        )
        .subscribe((data: any) => 
        {
          this.OnDataFetched.emit(data);
          super.next(data);
        },
          (error: any) =>
          {
            this.OnDataFetched.emit([]);
          }
        );
    }
  }

  public save(data: any, isNew?: boolean)
  {
    if (this.prepareDataForSave)
    {
      data = this.prepareData(data);
    }

    if (isNew)
    {
      if (this.createServiceUrl && this.createActionMethod)
      {
        this.create(data)
          .subscribe((res: any) => this.saveReturned(res), (err: any) => this.saveReturned(err));
      }
    }
    else
    {
      if (this.updateServiceUrl && this.updateActionMethod)
      {
        this.update(data)
          .subscribe((res: any) => this.saveReturned(res), (err: any) => this.saveReturned(err));
      }
    }
  }

  public saveReturned(result: any)
  {
    this.OnSaved.emit(result);

    if (this.ServerPaging)
    {
      const spp: ServerPagingParameters = new ServerPagingParameters();

      spp.Take = 10;
      spp.Skip = 0;

      this.serverread(spp);
    }
    else
    {
      this.read(null);
    }
  }

  private prepareData(data: any): any
  {
    let preparedData: any = data;

    Object.keys(preparedData).map(key => 
    {
      if (preparedData[key])
      {
        if (preparedData[key] instanceof Date)
        {
          //must convert to service friendly date format
          preparedData[key] = this.commonService.formatDateForService(preparedData[key]);
        }

        if (preparedData[key] instanceof Object)
        {
          preparedData[key] = this.prepareData(preparedData[key]);
        }
      }
    });

    return preparedData;
  }

  public remove(data: any)
  {
    data = this.prepareData(data);

    this.destroy(data)
      .subscribe((res: any) => this.saveReturned(res), (err: any) => this.saveReturned(err));
  }

  public resetItem(dataItem: any)
  {
    if (!dataItem) { return; }

    //find orignal data item
    const originalDataItem = this.data.find((item: any) => item[this.idField] === dataItem[this.idField]);

    if (originalDataItem)
    {
      //revert changes
      Object.assign(originalDataItem, dataItem);
    }

    super.next(this.data);
  }

  private reset()
  {
    this.dataChange.next([]);
  }

  private serverfetch(spp: ServerPagingParameters)
  {
    let url: string = this.readServiceUrl + `/${this.readActionMethod}`;

    return this.dataHandler.postHttpObservable(url, spp, false, false);
  }



  private fetch(data?: any): Observable<any[]>
  {
    if (this.postPayload)
    {
      let url: string = this.readServiceUrl + `/${this.readActionMethod}`;

      return this.dataHandler.postHttpObservable(url, this.postPayload, false, false);
    }
    else
    {
      let extraParams = "";

      if (this.getParameters && this.getParameters.length > 0)
      {
        this.getParameters.map(param =>
        {
          if (param)
          {
            if (param.name && param.name.length > 0 && param.value != null && param.value.toString().length > 0)
            {
              extraParams += param.name + "=" + param.value + "&";
            }
          }
        });

        //remove trailing &
        extraParams = extraParams.substring(0, extraParams.length - 1);
      }

      let url: string = this.readServiceUrl + `/${this.readActionMethod}?${extraParams}`;

      return this.dataHandler.getHttpObservable(url, false, false);
    }
  }

  private update(data?: any): Observable<any[]>
  {
    let url: string = this.updateServiceUrl + `/${this.updateActionMethod}`;

    return this.dataHandler.putHttpObservable(url, data, false);
  }

  private create(data?: any): Observable<any[]>
  {
    let url: string = this.createServiceUrl + `/${this.createActionMethod}`;

    return this.dataHandler.postHttpObservable(url, data, false, false);
  }

  private destroy(data?: any): Observable<any[]>
  {
    let url: string = this.destroyServiceUrl + `/${this.destroyActionMethod}`;

    return this.dataHandler.putHttpObservable(url, data, false);
  }

  private serializeModels(data?: any): string
  {
    return data ? `&models=${JSON.stringify([data])}` : '';
  }








  notifyFailure(title: string, content: string, exceptionMessage: string, validationExceptionMessage: string)
  {
    this.commonService.notifyFailure(title, content, exceptionMessage, validationExceptionMessage);
  }

  notifyAlert(title: string, content: string)
  {
    this.commonService.notifyAlert(title, content);
  }

  notifyError(title: string, content: string)
  {
    this.commonService.notifyError(title, content);
  }

  notifyInfo(title: string, content: string)
  {
    this.commonService.notifyInfo(title, content);
  }

  notifySuccess(title: string, content: string)
  {
    this.commonService.notifySuccess(title, content);
  }



  public isValidDate(d: any)
  {
    return isValid(new Date(d));
  }

  public formatDateForService(data: any)
  {
    return this.commonService.formatDateForService(data);
  }






}


export class Parameter
{
  public name = "";
  public value: any;
}
