import { Component, Injector } from "@angular/core";
import { Subscription } from "rxjs";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonDataService } from "../../../shared/services/common-data.service";
import { CommonService } from "../../../shared/services/common.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { Client } from "../../calendars/calendars.models";
import { ClientEditComponent } from "./client-edit.component";


@Component({
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class ClientsComponent extends MatTableCrudComponent<Client>
{
  public NameFilter: any;
  public ContactFilter: any;
  public ExternalIdFilter: any;

  private onDataFetchedSubscription: Subscription;

  constructor(public authorisationService: AuthorisationService,
    protected injector: Injector,
    public configurationService: ConfigurationService,
    public commonDataService: CommonDataService,
    public commonService: CommonService)
  {
    super(injector,
      ClientEditComponent,
      ClientEditComponent,
      ClientEditComponent,
      "CreateClient",
      "DeleteClient",
      "GetClients",
      "UpdateClient",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'Name', 'Contact', 'ExternalId'];
    this.filteredValues = [
      { Field: "Name", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "Contact", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "ExternalId", Value: "", Path: "", DataType: FilterDataType.Number }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "42rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddClients);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditClients);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteClients);
  }

  ngOnInit(): void
  {
    super.ngOnInit();

    this.onDataFetchedSubscription = this.mtCrudService.OnDataFetched.subscribe((result: any) =>
    {
      this.commonDataService.clients = result;
    });
  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, client: Client)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this client (${client.Name})?`;
    this.deleteHeaderText = "Delete Client?";

    super.deleteItem(event, client);
  }



  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.onDataFetchedSubscription)
    {
      this.onDataFetchedSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }











}

