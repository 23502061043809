import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { ProductCategory } from "../administration.models";
import { AdministrationService } from '../administration.service';
import { ProductCategoryEditComponent } from "./product-category-edit.component";


@Component({
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class ProductCategoriesComponent extends MatTableCrudComponent<ProductCategory>
{
  public ProductCategoryNameFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      ProductCategoryEditComponent,
      ProductCategoryEditComponent,
      ProductCategoryEditComponent,
      "CreateProductCategory",
      "DeleteProductCategory",
      "GetProductCategories",
      "UpdateProductCategory",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'ProductCategoryName'];
    this.filteredValues = [{ Field: "ProductCategoryName", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProductCategories);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProductCategories);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteProductCategories);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, productcategory: ProductCategory)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this client category (${productcategory.ProductCategoryName})?`;
    this.deleteHeaderText = "Delete Client Category?";

    super.deleteItem(event, productcategory);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

