import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CldrIntlService, IntlModule, IntlService } from '@progress/kendo-angular-intl';
import { IsLoadingModule } from '@service-work/is-loading';
import { ScrollPositionDirectiveModule } from '@service-work/scroll-position';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CustomDateAdapter } from './custom-date-adapter';
import { KendoAngularModule } from './kendo-angular.module';
import { MaterialAngularModule } from './material-angular.module';
import { AirtimeCreditsComponent } from './shared/components/airtime-credits/airtime-credits.component';
import { ConfirmationDialog } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { ContactsSelectorComponent } from './shared/components/contacts-selector/contacts-selector.component';
import { DocumentUploaderComponent } from './shared/components/document-uploader/document-uploader.component';
import { CheckBoxFilterComponent } from './shared/components/kendo-checkbox-filter.component';
import { ComboBoxFilterComponent } from './shared/components/kendo-combobox-filter.component';
import { DropDownListFilterComponent } from './shared/components/kendo-dropdownlist-filter.component';
import { NumericTextBoxFilterComponent } from './shared/components/kendo-numerictextbox-filter.component';
import { TextBoxFilterComponent } from './shared/components/kendo-textbox-filter.component';
import { MatDialogMultiDatePickerComponent } from './shared/components/mat-dialog-multi-datepicker/mat-dialog-multi-datepicker.component';
import { MatTableCrudEditComponent } from './shared/components/mat-table-crud/mat-table-crud-edit.component';
import { SelectableChipsComponent } from './shared/components/selectable-chips/selectable-chips.component';
import { AfterValueChangedDirective } from './shared/directives/after-value-changed.directive';
import { DisableControlDirective } from './shared/directives/disable-control-directive';
import { MatAutoFocusDirective } from './shared/directives/mat-auto-focus.directive';
import { GenericFilterPipe } from './shared/pipes/generic-filter.pipe';
import { CustomKendoIntlService } from './shared/services/custom-kendo-intl.service';



@NgModule({
  declarations: [
    AfterValueChangedDirective,
    DropDownListFilterComponent,
    ComboBoxFilterComponent,
    TextBoxFilterComponent,
    CheckBoxFilterComponent,
    NumericTextBoxFilterComponent,
    DisableControlDirective,
    GenericFilterPipe,
    ConfirmationDialog,
    SelectableChipsComponent,
    AirtimeCreditsComponent,
    ContactsSelectorComponent,
    DocumentUploaderComponent,
    MatDialogMultiDatePickerComponent,
    MatAutoFocusDirective,
    MatTableCrudEditComponent
  ],
  exports: [
    AfterValueChangedDirective,
    DropDownListFilterComponent,
    ComboBoxFilterComponent,
    TextBoxFilterComponent,
    CheckBoxFilterComponent,
    NumericTextBoxFilterComponent,
    DisableControlDirective,
    GenericFilterPipe,
    ConfirmationDialog,
    SelectableChipsComponent,
    AirtimeCreditsComponent,
    ContactsSelectorComponent,
    DocumentUploaderComponent,
    MatDialogMultiDatePickerComponent,
    MatAutoFocusDirective,
    MatTableCrudEditComponent,
    MaterialAngularModule,
    KendoAngularModule,
    AngularDualListBoxModule,
    IsLoadingModule,
    ScrollPositionDirectiveModule,
    CommonModule,
    MatTableExporterModule,
    IntlModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FontAwesomeModule
  ],
  imports: [
    CommonModule,
    MaterialAngularModule,
    KendoAngularModule,
    AngularDualListBoxModule,
    IsLoadingModule,
    ScrollPositionDirectiveModule,
    MatTableExporterModule,
    IntlModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: DateAdapter, useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    CldrIntlService,
    {
      provide: IntlService,
      useClass: CustomKendoIntlService
    }
  ]
})
export class CbSharedModule { }
