import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { AdministrationService } from '../administration.service';
import { NotifyGroupMemberEditComponent } from "./notify-group-member-edit.component";
import { NotifyGroupMember } from "../administration.models";
import { CalendarsService } from "../../calendars/calendars.service";
import { PropertyType } from "../../calendars/calendars.models";


@Component({
  templateUrl: './notify-group-members.component.html',
  styleUrls: ['./notify-group-members.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class NotifyGroupMembersComponent extends MatTableCrudComponent<NotifyGroupMember>
{
  public StationIdFilter: any;
  public PropertyTypeIdFilter: any;
  public NotifyGroupIdFilter: any;
  public EmailFilter: any;
  public MobileFilter: any;

  constructor(public authorisationService: AuthorisationService,
    protected injector: Injector,
    public configurationService: ConfigurationService,
    public calendarsService: CalendarsService,
    public administrationService: AdministrationService)
  {
    super(injector,
      NotifyGroupMemberEditComponent,
      NotifyGroupMemberEditComponent,
      NotifyGroupMemberEditComponent,
      "CreateNotifyGroupMember",
      "DeleteNotifyGroupMember",
      "GetNotifyGroupMembers",
      "UpdateNotifyGroupMember",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'NotifyGroup.Name', 'Station.StationName', 'PropertyType.PropertyTypeName', 'Email', 'Mobile'];
    this.filteredValues = [{ Field: "NotifyGroupId", Value: "", Path: "NotifyGroup.Id", DataType: FilterDataType.Number }, { Field: "StationId", Value: "", Path: "Station.Id", DataType: FilterDataType.Number }, { Field: "PropertyTypeId", Value: "", Path: "PropertyType.Id", DataType: FilterDataType.Number }, { Field: "Email", Value: "", Path: "", DataType: FilterDataType.String }, { Field: "Mobile", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "65rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationNotificationGroups);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationNotificationGroups);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationNotificationGroups);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {

    super.addNew(event);
  }

  deleteItem(event: any, notifyGroupMember: NotifyGroupMember)
  {
    let station: string = "";

    if (notifyGroupMember && notifyGroupMember.Station)
    {
      station = `${notifyGroupMember.Station.StationName}/`;
    }

    this.deleteConfirmationMessage = `Are you sure you want to delete this notification group member (${station}${notifyGroupMember.NotifyGroup.Name})?`;
    this.deleteHeaderText = "Delete Notification Group Member?";

    super.deleteItem(event, notifyGroupMember);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }


  trackByPropertyTypes(index: number, propertyType: PropertyType)
  {
    return propertyType["Id"];
  }








}

