<div class="station-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="station-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="station-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Platform Provider Name</mat-label>
        <input matInput placeholder="Platform Name" formControlName="StationName" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('StationName') && !form.hasError('required')">
          Please enter a valid Name
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Platform Type</mat-label>
        <mat-select formControlName="PlatformType" placeholder="Platform Type" [compareWith]="equalsPlatformType" (selectionChange)="platformTypeChange($event)">
          <mat-option *ngFor="let platformType of administrationService.platformTypes" [value]="platformType">{{platformType.Name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Market Code</mat-label>
        <input matInput placeholder="Market Code" formControlName="MarketCode" maxlength="2" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('MarketCode') && !form.hasError('required')">
          Please enter a valid Market Code
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          Market Code is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="isVendor" class="form-group">
      <mat-form-field>
        <mat-label>Contact</mat-label>
        <input matInput placeholder="Contact" formControlName="Contact" maxlength="100" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div *ngIf="isVendor" class="form-group">
      <mat-form-field>
        <mat-label>Contact Email</mat-label>
        <input matInput placeholder="Contact Email" formControlName="ContactEmail" maxlength="100" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div *ngIf="isVendor" class="form-group">
      <mat-form-field>
        <mat-label>Contact Phone No</mat-label>
        <input matInput placeholder="Contact Phone No" formControlName="ContactPhoneNo" maxlength="25" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput placeholder="City" formControlName="City" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('City') && !form.hasError('required')">
          Please enter a valid City
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          City is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput placeholder="State" formControlName="State" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        <mat-error *ngIf="form.hasError('State') && !form.hasError('required')">
          Please enter a valid State
        </mat-error>
        <mat-error *ngIf="form.hasError('required')">
          State is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Platform Group</mat-label>
        <mat-select formControlName="StationType" placeholder="Platform Group" [compareWith]="equalsStationType">
          <mat-option *ngFor="let stationtype of administrationService.stationTypes | filter:[{Disabled: false}]" [value]="stationtype">{{stationtype.StationTypeName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="isVendor" class="form-group">
      <mat-form-field>
        <mat-label>Days Until DropDead</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Days Until DropDead" formControlName="DaysUntilDropDead" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>External Id</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="External Id" formControlName="ExternalId" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="station-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
