import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { ResolveDataService } from "../services/resolve-data.service";


export const ProjectManagersResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
{
  let resolveDataService = inject(ResolveDataService);

  return resolveDataService.resolveProjectManagers();
}; 
