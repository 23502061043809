import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';


@Component(
  {
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
  })
export class LoginComponent implements OnInit
{
 
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public commonService: CommonService
  )
  {
    // redirect to home if already logged in
    if (this.commonService.isLoggedIn)
    {
      this.router.navigate(['/']);
    }
  }

  async ngOnInit()
  {
    if (!this.commonService.isLoggedIn && !this.commonService.manuallyLoggedOut)
    {
      await this.authenticationService.login();
    }
  
  }













}
