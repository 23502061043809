import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';


@Injectable({ providedIn: 'root' })


export class LoggerService
{
  private _appName = "";

  constructor(private configurationService: ConfigurationService, private httpClient: HttpClient)
  {

  }

  public fatal(msg: string)
  {
    if (msg)
    {
      this.log(msg, "Fatal");
    }
  }


  public debug(msg: string)
  {
    if (msg)
    {
      this.log(msg, "Debug");
    }
  }

  public info(msg: string)
  {
    if (msg)
    {
      this.log(msg, "Info");
    }
  }


  public warn(msg: string)
  {
    if (msg)
    {
      this.log(msg, "Warn");
    }
  }

  public error(msg: string)
  {
    if (msg)
    {
      this.log(msg, "Error");
    }
  }


  get appName(): string
  {
    if (!this._appName)
    {
      let an: any = document.getElementById("AppName");

      if (an)
      {
        this._appName = an.value;
      }
    }

    return this._appName;
  }


  private log(msg: string, logLevel: string)
  {
    if (msg && logLevel)
    {
      msg = encodeURI(msg);
      let loggingInfo: LoggingInfo = { "message": msg, "logLevel": logLevel };

      let headers = new HttpHeaders();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        })
      };

      let url = this.configurationService.cbSettings.loggingApiUrl + `/LogMessage`;

      try
      {
        lastValueFrom(this.httpClient.post<boolean>(url, loggingInfo, httpOptions)
          .pipe(
            retry({ count: 10, delay: 1000 }),
            map((res: any) => this.extractData(res)),
            catchError((err: any) =>
            {
              return this.handleHttpError(err);
            })
          ));
      }
      catch (e)
      {

      }
    }
  }


  extractData(res: HttpResponse<any>)
  {
    let body: any = null;

    if (res != null)
    {
      if (res.body != null)
      {
        body = res.body;
      }
      else
      {
        body = res;
      }
    }

    if (Array.isArray(body))
    {
      return body || [];
    }
    else if (typeof body == "object")
    {
      return body || {};
    }
    else
    {
      return body;
    }
  }


  private handleHttpError(error: any)
  {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(() => errMsg);
  }









}



export interface LoggingInfo
{
  message: string,
  logLevel: string,
}
