import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatDateFormats, MATERIAL_SANITY_CHECKS, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { AppComponent } from './components/app/app.component';
import { appRoutingProviders, routing } from './components/app/app.routing';
import { AppService } from './components/app/app.service';
import { SigninRedirectCallbackComponent } from './components/home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './components/home/signout-redirect-callback.component';
import { UnauthorisedComponent } from './components/home/unauthorised.component';
import { LoginComponent } from './components/login/login.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { RegisterComponent } from './components/register/register.component';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
//import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { CommonService } from './shared/services/common.service';
//import { DraggableDirective } from './shared/directives/draggable.directive';

import '@progress/kendo-angular-intl/locales/en-AU/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import '@progress/kendo-angular-intl/locales/en/all';

import { CbSharedModule } from './cb-shared.module';
import { AdministrationModule } from './feature-modules/administration.module';


export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    } as Intl.DateTimeFormatOptions,
  }
};


export function appServiceFactory(appService: AppService): Function
{
  return () =>
  {
    return appService.loadApplication().then(() =>
    {

    });
  };
}

@NgModule({
  imports: [
    routing,
    CbSharedModule,
    AdministrationModule
  ],
  exports: [
    CbSharedModule
  ],
  declarations: [
    AppComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    UnauthorisedComponent,
    LoginComponent,
    RegisterComponent,
    NavMenuComponent
  ],
  providers: [
    appRoutingProviders,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    //{
    //  provide: DateAdapter, useClass: CustomDateAdapter,
    //  deps: [MAT_DATE_LOCALE, Platform]
    //},
    { provide: APP_INITIALIZER, useFactory: appServiceFactory, deps: [AppService], multi: true },
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false
    },
    {
      provide: LOCALE_ID,
      deps: [CommonService],      //some service handling global settings
      useFactory: (commonService: CommonService) => commonService.getLocaleName()  //returns locale string
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [CommonService],      //some service handling global settings
      useFactory: (commonService: CommonService) => commonService.getLocaleName()  //returns locale string
    },
    //{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    //CldrIntlService,
    //{
    //  provide: IntlService,
    //  useClass: CustomKendoIntlService
    //}
  ],
  bootstrap: []
})
export class AppModuleShared
{
}



