import { Injectable } from '@angular/core';
import { parseDate } from '@progress/kendo-angular-intl';
import { BaseEditService } from '@progress/kendo-angular-scheduler';
import { addMinutes, parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { DeleteResult, SaveResult } from '../../shared/models/result.model';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { EventCategory } from '../administration/administration.models';
import { Station } from '../calendars/calendars.models';
import { CalendarsService } from '../calendars/calendars.service';
import { diaryfields, MyEvent } from './diary.interfaces';
import { EventStation } from './diary.models';


const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';


const fields: diaryfields = {
  id: 'Id',
  title: 'Title',
  description: 'Description',
  header: 'Header',
  startTimezone: 'StartTimezone',
  start: 'StartDate',
  end: 'EndDate',
  endTimezone: 'EndTimezone',
  isAllDay: 'IsAllDayEvent',
  recurrenceRule: 'RecurrencePattern',
  recurrenceExceptions: 'ExceptionAppointments',
  activityId: 'ActivityId',
  eventCategoryId: 'EventCategoryId',
  eventStations: 'EventStations',
  stationIds: 'StationIds'
};

@Injectable({ providedIn: 'root' })

export class DiaryService extends BaseEditService<MyEvent>
{
  private _eventCategories: EventCategory[] = [];
  private _startDate: Date = new Date(this.commonService.today.getFullYear(), this.commonService.today.getMonth(), 1);
  private _endDate: Date = new Date(this.commonService.today.getFullYear(), this.commonService.today.getMonth(), 1);
  private _previousViewIndex = 1;
  private _selectedViewIndex = 1;

  private _availableStations: Station[] = [];
  private _selectedStationGroupId = 0;
  private _selectedStations: Station[] = [];

  public homeStation: Station = null;

  public loading = false;
  public isEditing = false;
  public saveInProgress = false;

  constructor(
    private configurationService: ConfigurationService,
    private calendarsService: CalendarsService,
    private commonService: CommonService,
    private dataHandler: DataHandlerService
  )
  {
    super(fields);


  }

  public read(useCachedData: boolean = false): void
  {
    let stationIds: number[] = this.selectedStations.map(s => s.Id);

    this.fetch(this.startDate, this.endDate, stationIds, useCachedData).subscribe(data =>
    {
      if (data && Array.isArray(data))
      {
        this.data = data.map((item: MyEvent) => 
        {
          return this.readEvent(item);
        });
      }

      this.source.next(this.data);
    });
  }

  public create(event: MyEvent)
  {
    if (!this.commonService.isFunctionCurrentlyProcessing("createEvent"))
    {
      this.commonService.setFunctionProcessingState("createEvent", true);

      this.saveInProgress = true;

      this.saveEvent(event).then(result =>
      {
        this.saveInProgress = false;

        if (result)
        {
          if (result.IsSuccessful)
          {
            let stationIds: number[] = this.selectedStations.map(s => s.Id);

            let key = `${this.configurationService.cbSettings.eventsApiUrl}/ReadRange${JSON.stringify({ StartDate: this.startDate, EndDate: this.endDate, StationIds: stationIds, ChangedByUserId: this.dataHandler.changedByUserId })}`;
            this.dataHandler.removeCacheKey(key);

            this.data = [];
            this.read(false);
            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();

            this.commonService.notifySuccess("Saved", result.Message);
          }
          else
          {
            let errMsg = `Unable to save event ${event.Title}.`;

            if (result.ErrorMessage)
            {
              errMsg = result.ErrorMessage;
            }

            this.commonService.notifyFailure("Error", errMsg, result.ExceptionMessage, result.ValidationExceptionMessage);
          }
        }
        else
        {
          this.commonService.notifyError("Error", `Unable to save event ${event.Title}`);
        }

        this.commonService.setFunctionProcessingState("createEvent", false);
      }).catch(err =>
      {
        this.saveInProgress = false;

        this.commonService.notifyError("Error", `Unable to save event ${event.Title}`);

        this.commonService.setFunctionProcessingState("createEvent", false);
      });
    }
  }

  public update(event: MyEvent, value: MyEvent)
  {
    if (!this.commonService.isFunctionCurrentlyProcessing("updateEvent"))
    {
      this.commonService.setFunctionProcessingState("updateEvent", true);

      //merge objects (values in event will be overwritten with properties of values which have the same name)
      let updatedEvent: MyEvent = { ...event, ...value };

      if (updatedEvent != null)
      {
        if (updatedEvent.ExceptionAppointments != null)
        {
          updatedEvent.ExceptionAppointments = this.serializeExceptions(updatedEvent.ExceptionAppointments);
        }

        this.saveInProgress = true;

        this.saveEvent(updatedEvent).then(result =>
        {
          this.saveInProgress = false;

          if (result)
          {
            if (result.IsSuccessful)
            {
              let stationIds: number[] = this.selectedStations.map(s => s.Id);

              let key = `${this.configurationService.cbSettings.eventsApiUrl}/ReadRange${JSON.stringify({ StartDate: this.startDate, EndDate: this.endDate, StationIds: stationIds, ChangedByUserId: this.dataHandler.changedByUserId })}`;
              this.dataHandler.removeCacheKey(key);

              this.data = [];
              this.read(false);
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();

              this.commonService.notifySuccess("Saved", result.Message);
            }
            else
            {
              let errMsg = `Unable to save event ${updatedEvent.Title}.`;

              if (result.ErrorMessage)
              {
                errMsg = result.ErrorMessage;
              }

              this.commonService.notifyFailure("Error", errMsg, result.ExceptionMessage, result.ValidationExceptionMessage);
            }
          }
          else
          {
            this.commonService.notifyError("Error", `Unable to save event ${updatedEvent.Title}`);
          }

          this.commonService.setFunctionProcessingState("updateEvent", false);
        }).catch(err =>
        {
          this.saveInProgress = false;

          this.commonService.notifyError("Error", `Unable to save event ${updatedEvent.Title}`);

          this.commonService.setFunctionProcessingState("updateEvent", false);
        });
      }
    }
  }

  public createException(event: MyEvent, value: MyEvent)
  {
    if (!this.commonService.isFunctionCurrentlyProcessing("createException"))
    {
      this.commonService.setFunctionProcessingState("createException", true);

      //merge objects (values in event will be overwritten with properties of values which have the same name)
      let updatedEvent: MyEvent = { ...event, ...value };

      if (updatedEvent != null)
      {
        updatedEvent.RecurrencePattern = null;
        updatedEvent.ExceptionAppointments = null;

        this.saveInProgress = true;

        let parent: MyEvent = this.findRecurrenceMaster(updatedEvent);

        if (parent != null)
        {
          updatedEvent.ParentId = parent.Id;

          let exceptions: Date[] = [];

          if (parent.ExceptionAppointments)
          {
            exceptions = parent.ExceptionAppointments;
          }

          if (exceptions && Array.isArray(exceptions))
          {
            updatedEvent.StartDate = this.adjustDateToUtcPlusOffset(updatedEvent.StartDate);

            exceptions.push(updatedEvent.StartDate);
          }

          if (exceptions)
          {
            parent.ExceptionAppointments = this.serializeExceptions(exceptions);
          }

          this.updateOccurrenceParent(parent).then(result =>
          {
            this.saveEvent(updatedEvent).then(result =>
            {
              this.saveInProgress = false;

              if (result)
              {
                if (result.IsSuccessful)
                {
                  let stationIds: number[] = this.selectedStations.map(s => s.Id);

                  let key = `${this.configurationService.cbSettings.eventsApiUrl}/ReadRange${JSON.stringify({ StartDate: this.startDate, EndDate: this.endDate, StationIds: stationIds, ChangedByUserId: this.dataHandler.changedByUserId })}`;
                  this.dataHandler.removeCacheKey(key);

                  this.data = [];
                  this.read(false);
                  this.calendarsService.resetHttpCache();
                  this.calendarsService.resetCalendarData();

                  this.commonService.notifySuccess("Saved", result.Message);
                }
                else
                {
                  let errMsg = `Unable to save event ${updatedEvent.Title}.`;

                  if (result.ErrorMessage)
                  {
                    errMsg = result.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, result.ExceptionMessage, result.ValidationExceptionMessage);
                }
              }
              else
              {
                this.commonService.notifyError("Error", `Unable to save event ${updatedEvent.Title}`);
              }

              this.commonService.setFunctionProcessingState("createException", false);
            }).catch(err =>
            {
              this.saveInProgress = false;

              this.commonService.notifyError("Error", `Unable to save event ${updatedEvent.Title}`);

              this.commonService.setFunctionProcessingState("createException", false);
            });
          }).catch(err =>
          {
            this.saveInProgress = false;

            this.commonService.notifyError("Error", `Unable to save event ${updatedEvent.Title}`);

            this.commonService.setFunctionProcessingState("createException", false);
          });
        }
        else
        {
          this.saveInProgress = false;
          this.commonService.setFunctionProcessingState("createException", false);
        }
      }
      else
      {
        this.commonService.setFunctionProcessingState("createException", false);
      }
    }
  }


  public removeEvent(event: MyEvent, isException: boolean)
  {
    if (event)
    {
      event.ExceptionAppointments = null;

      if (isException)
      {
        this.remove(event);
      }
      else
      {
        this.removeOccurrence(event);
      }

      if (!this.commonService.isFunctionCurrentlyProcessing("deleteOccurrence"))
      {
        this.commonService.setFunctionProcessingState("deleteOccurrence", true);

        this.saveInProgress = true;

        this.deleteOccurrence(event).then(result =>
        {
          this.saveInProgress = false;

          if (result)
          {
            if (result.IsSuccessful)
            {
              let stationIds: number[] = this.selectedStations.map(s => s.Id);

              let key = `${this.configurationService.cbSettings.eventsApiUrl}/ReadRange${JSON.stringify({ StartDate: this.startDate, EndDate: this.endDate, StationIds: stationIds, ChangedByUserId: this.dataHandler.changedByUserId })}`;
              this.dataHandler.removeCacheKey(key);

              this.data = [];
              this.read(false);
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();

              this.commonService.notifySuccess("Deleted", result.Message);
            }
            else
            {
              let errMsg = `Unable to delete event ${event.Title}.`;

              if (result.ErrorMessage)
              {
                errMsg = result.ErrorMessage;
              }

              this.commonService.notifyFailure("Error", errMsg, result.ExceptionMessage, result.ValidationExceptionMessage);
            }
          }
          else
          {
            this.commonService.notifyError("Error", `Unable to delete event ${event.Title}`);
          }

          this.commonService.setFunctionProcessingState("deleteOccurrence", false);
        }).catch(err =>
        {
          this.saveInProgress = false;

          this.commonService.notifyError("Error", `Unable to delete event ${event.Title}`);

          this.commonService.setFunctionProcessingState("deleteOccurrence", false);
        });
      }
    }
  }

  public removeEventSeries(event: MyEvent)
  {
    if (event)
    {
      event.ExceptionAppointments = null;

      this.removeSeries(event);

      if (!this.commonService.isFunctionCurrentlyProcessing("deleteSeries"))
      {
        this.commonService.setFunctionProcessingState("deleteSeries", true);

        this.saveInProgress = true;

        this.deleteSeries(event).then(result =>
        {
          this.saveInProgress = false;

          if (result)
          {
            if (result.IsSuccessful)
            {
              let stationIds: number[] = this.selectedStations.map(s => s.Id);

              let key = `${this.configurationService.cbSettings.eventsApiUrl}/ReadRange${JSON.stringify({ StartDate: this.startDate, EndDate: this.endDate, StationIds: stationIds, ChangedByUserId: this.dataHandler.changedByUserId })}`;
              this.dataHandler.removeCacheKey(key);

              this.data = [];
              this.read(false);
              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();

              this.commonService.notifySuccess("Deleted", result.Message);
            }
            else
            {
              let errMsg = `Unable to delete event ${event.Title}.`;

              if (result.ErrorMessage)
              {
                errMsg = result.ErrorMessage;
              }

              this.commonService.notifyFailure("Error", errMsg, result.ExceptionMessage, result.ValidationExceptionMessage);
            }
          }
          else
          {
            this.commonService.notifyError("Error", `Unable to delete event ${event.Title} series`);
          }

          this.commonService.setFunctionProcessingState("deleteSeries", false);
        }).catch(err =>
        {
          this.saveInProgress = false;

          this.commonService.notifyError("Error", `Unable to delete event ${event.Title} series`);

          this.commonService.setFunctionProcessingState("deleteSeries", false);
        });
      }
    }
  }

  public createExceptionForDeletedOccurrence(event: MyEvent)
  {
    if (!this.commonService.isFunctionCurrentlyProcessing("createExceptionForDeletedOccurrence"))
    {
      this.commonService.setFunctionProcessingState("createExceptionForDeletedOccurrence", true);

      this.saveInProgress = true;

      let exceptions: Date[] = [];

      if (event.ExceptionAppointments)
      {
        exceptions = event.ExceptionAppointments;
      }

      if (exceptions && Array.isArray(exceptions))
      {
        event.StartDate = this.adjustDateToUtcPlusOffset(event.StartDate);

        exceptions.push(event.StartDate);
      }

      if (exceptions)
      {
        event.ExceptionAppointments = this.serializeExceptions(exceptions);
      }

      this.updateOccurrenceParent(event).then(result =>
      {
        this.saveInProgress = false;

        if (result.IsSuccessful)
        {
          let stationIds: number[] = this.selectedStations.map(s => s.Id);

          let key = `${this.configurationService.cbSettings.eventsApiUrl}/ReadRange${JSON.stringify({ StartDate: this.startDate, EndDate: this.endDate, StationIds: stationIds, ChangedByUserId: this.dataHandler.changedByUserId })}`;
          this.dataHandler.removeCacheKey(key);

          this.data = [];
          this.read(false);
          this.calendarsService.resetHttpCache();
          this.calendarsService.resetCalendarData();

          this.commonService.notifySuccess("Deleted", "Successfully deleted occurrence.");
        }
      }).catch(err =>
      {
        this.saveInProgress = false;

        this.commonService.notifyError("Error", `Unable to save event ${event.Title}`);

        this.commonService.setFunctionProcessingState("createExceptionForDeletedOccurrence", false);
      });
    }
  }



  get startDate(): Date
  {
    return this._startDate;
  }
  set startDate(value: Date)
  {
    this._startDate = value;
  }

  get endDate(): Date
  {
    return this._endDate;
  }
  set endDate(value: Date)
  {
    this._endDate = value;
  }


  get previousViewIndex(): number
  {
    return this._previousViewIndex;
  }
  set previousViewIndex(value: number)
  {
    this._previousViewIndex = value;
  }

  get selectedViewIndex(): number
  {
    return this._selectedViewIndex;
  }
  set selectedViewIndex(value: number)
  {
    this._selectedViewIndex = value;
  }


  get availableStations(): Station[]
  {
    if (!this._availableStations || this._availableStations.length == 0)
    {
      if (this.selectedStationGroupId != null && this.calendarsService.stations)
      {
        if (this.selectedStationGroupId == 0)
        {
          this._availableStations = this.calendarsService.stations;
        }
        else
        {
          this._availableStations = this.calendarsService.stations.filter((s) => s.StationType.Id == this.selectedStationGroupId);
        }
      }
    }

    return this._availableStations;
  }

  get selectedStationGroupId(): number
  {
    return this._selectedStationGroupId;
  }
  set selectedStationGroupId(value: number)
  {
    this._selectedStationGroupId = value;
    this._availableStations = [];
  }


  get selectedStations(): Station[]
  {
    return this._selectedStations;
  }
  set selectedStations(value: Station[])
  {
    this._selectedStations = value.sort((a, b) =>
    {
      // Sort by SortOrder
      // If the first item has a higher number, move it up
      // If the first item has a lower number, move it down
      if (a.SortOrder > b.SortOrder) return 1;
      if (a.SortOrder < b.SortOrder) return -1;

      // If the SortOrder number is the same between both items, sort alphabetically
      // If the first item comes first in the alphabet, move it up
      // Otherwise move it down
      if (a.StationName > b.StationName) return 1;
      if (a.StationName < b.StationName) return -1;

      return 1;
    });
  }


  resetSelectedStations()
  {
    if (!this.selectedStations)
    {
      this.selectedStations = [];
    }

    if (this.availableStations && this.availableStations.length > 0)
    {
      if (this.selectedStationGroupId != 0) //ALL
      {
        //Remove selected stations from other Station Groups
        this.selectedStations = this.selectedStations.filter(s => s.StationTypeId == this.selectedStationGroupId);
      }

      //Add default selected station if all were another station group which wasn't ALL
      if (this.selectedStations.length == 0)
      {
        if (this.homeStation && (this.homeStation.StationTypeId == this.selectedStationGroupId || this.selectedStationGroupId == 0)) //All is StationGroupId 0
        {
          this.selectedStations.push(this.homeStation);
        }
        else
        {
          this.selectedStations.push(this.availableStations[0]);
        }
      }
    }
  }

  protected save(created: MyEvent[], updated: MyEvent[], deleted: MyEvent[]): void
  {

  }

  protected fetch(startDate: Date, endDate: Date, stationIds: number[], useCachedData: boolean = false): Observable<MyEvent[]>
  {
    return this.dataHandler.postHttpObservable<MyEvent[]>(`${this.configurationService.cbSettings.eventsApiUrl}/ReadRange`, { StartDate: startDate, EndDate: endDate, StationIds: stationIds }, useCachedData);
  }

  private readEvent(item: any): MyEvent
  {
    let stationIds: number[] = [];

    if (item.EventStations && item.EventStations.length > 0)
    {
      item.EventStations.map((es: EventStation) =>
      {
        if (!stationIds.some(s => s == es.StationId))
        {
          stationIds.push(es.StationId);
        }
      });
    }

    let startDate: any = parseDate(item.StartDate);
    let endDate: any = parseDate(item.EndDate);

    startDate = this.adjustDateToUtcPlusOffset(startDate);
    endDate = this.adjustDateToUtcPlusOffset(endDate);

    return {
      ...item,
      StartDate: startDate,
      EndDate: endDate,
      IsAllDayEvent: item.IsAllDayEvent,
      ParentId: item.ParentId,
      StationIds: stationIds,
      Header: item.Header,
      //StartTimezone: item.StartTimezone,
      //EndTimezone: item.EndTimezone,
      ExceptionAppointments: item && item.ExceptionAppointments ? this.customParseExceptions(item.ExceptionAppointments) : []
    };
  }

  private customParseExceptions(value: string): Date[]
  {
    let dates: Date[] = [];

    var exceptions = value.split(";");

    exceptions.map(e =>
    {
      let date: Date = parseISO(e.substring(0, e.indexOf("T")));

      date = this.adjustDateToUtcPlusOffset(date);

      if (date)
      {
        dates.push(date);
      }
    });

    return dates;
  }

  private adjustDateToUtcPlusOffset(date: Date): Date
  {
    date = new Date(date.toDateString() + ' UTC');

    let offset: number = date.getTimezoneOffset();

    if (offset < 1)
    {
      offset = 1
    }

    date = addMinutes(date, (offset));

    return date;
  }

  private serializeModels(events: MyEvent[]): string
  {
    if (!events)
    {
      return '';
    }

    const data = events.map(event => ({
      ...event,
      ExceptionAppointments: event && event.ExceptionAppointments ? this.serializeExceptions(event.ExceptionAppointments) : null
    }));

    return `&models=${JSON.stringify(data)}`;
  }



  get eventCategories(): EventCategory[]
  {
    return this._eventCategories;
  }
  set eventCategories(value: EventCategory[])
  {
    this._eventCategories = value;
  }


  getEventCategories(useCachedData: boolean = false): Observable<EventCategory[]>
  {
    return this.dataHandler.getHttpObservable<EventCategory[]>(this.configurationService.cbSettings.adminServiceUrl + "/GetEventCategories", useCachedData, false);
  }

  saveEvent(event: MyEvent, useCachedData: boolean = false): Promise<SaveResult>
  {
    event.StartDateISO = this.commonService.getISOStringFromDate(event.StartDate);
    event.EndDateISO = this.commonService.getISOStringFromDate(event.EndDate);

    return this.dataHandler.postHttpPromise<SaveResult>(`${this.configurationService.cbSettings.eventsApiUrl}/Save`, event, useCachedData, false);
  }

  updateOccurrenceParent(event: MyEvent, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(`${this.configurationService.cbSettings.eventsApiUrl}/UpdateOccurrenceParent`, event, useCachedData, false);
  }

  deleteOccurrence(event: MyEvent, useCachedData: boolean = false): Promise<DeleteResult>
  {
    return this.dataHandler.postHttpPromise<DeleteResult>(`${this.configurationService.cbSettings.eventsApiUrl}/DeleteOccurrence`, event, useCachedData, false);
  }

  deleteSeries(event: MyEvent, useCachedData: boolean = false): Promise<DeleteResult>
  {
    return this.dataHandler.postHttpPromise<DeleteResult>(`${this.configurationService.cbSettings.eventsApiUrl}/DeleteSeries`, event, useCachedData, false);
  }








}











