import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DayPart } from '../../components/administration/administration.models';
import { Client, DaysInWeek } from '../../components/calendars/calendars.models';
import { Campaign } from '../../components/campaigns/campaigns.models';
import { ContactTypeUser } from '../models/common.models';
import { ConfigurationService } from './configuration.service';
import { DataHandlerService } from './data-handler.service';


export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

@Injectable({ providedIn: 'root' })

export class CommonDataService
{
  private _dayparts: DayPart[] = [];
  private _clients: Client[] = [];
  private _campaigns: Campaign[] = [];

  private _projectManagers: ContactTypeUser[] = [];
  private _clientExecutives: ContactTypeUser[] = [];
  private _clientSupport: ContactTypeUser[] = [];
  private _briefManagers: ContactTypeUser[] = [];
  private _nationalProjectManagers: ContactTypeUser[] = [];
  private _programmers: ContactTypeUser[] = [];
  private _adOps: ContactTypeUser[] = [];

  private _clientExecutiveLabelText: string = "";
  private _clientSupportLabelText: string = "";
  private _projectManagerLabelText: string = "";
  private _briefManagerLabelText: string = "";
  private _nationalProjectManagerLabelText: string = "";
  private _programmerLabelText: string = "";
  private _adOpsLabelText: string = "";

  public readonly bitmaskWeekdays: number = 62;
  public readonly bitmaskWeekends: number = 65;
  public readonly bitmaskEveryday: number = 127;

  public daysInTheWeek: DaysInWeek[] = [
    { Name: "Monday", Abbreviation: "Mon", FirstLetter: "M", ShortAbbreviatation: "Mo", Value: 2, DayOfWeek: 1 },
    { Name: "Tuesday", Abbreviation: "Tue", FirstLetter: "T", ShortAbbreviatation: "Tu", Value: 4, DayOfWeek: 2 },
    { Name: "Wednesday", Abbreviation: "Wed", FirstLetter: "W", ShortAbbreviatation: "We", Value: 8, DayOfWeek: 3 },
    { Name: "Thursday", Abbreviation: "Thu", FirstLetter: "T", ShortAbbreviatation: "Th", Value: 16, DayOfWeek: 4 },
    { Name: "Friday", Abbreviation: "Fri", FirstLetter: "F", ShortAbbreviatation: "Fr", Value: 32, DayOfWeek: 5 },
    { Name: "Saturday", Abbreviation: "Sat", FirstLetter: "S", ShortAbbreviatation: "Sa", Value: 64, DayOfWeek: 6 },
    { Name: "Sunday", Abbreviation: "Sun", FirstLetter: "S", ShortAbbreviatation: "Su", Value: 1, DayOfWeek: 0 }
  ];

  public months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  public dayInMonthInstance = ["first", "second", "third", "fourth", "last"];

  constructor(
    private dataHandler: DataHandlerService,
    private configurationService: ConfigurationService)
  {
   
  }
  

  get dayparts(): DayPart[]
  {
    return this._dayparts;
  }
  set dayparts(value: DayPart[])
  {
    this._dayparts = value;
  }

  get clients(): Client[]
  {
    return this._clients;
  }
  set clients(value: Client[])
  {
    this._clients = value;
  }

  get campaigns(): Campaign[]
  {
    return this._campaigns;
  }
  set campaigns(value: Campaign[])
  {
    this._campaigns = value;
  }
  

  get projectManagers(): ContactTypeUser[]
  {
    return this._projectManagers;
  }
  set projectManagers(value: ContactTypeUser[])
  {
    this._projectManagers = value;
  }

  get clientExecutives(): ContactTypeUser[]
  {
    return this._clientExecutives;
  }
  set clientExecutives(value: ContactTypeUser[])
  {
    this._clientExecutives = value;
  }

  get clientSupport(): ContactTypeUser[]
  {
    return this._clientSupport;
  }
  set clientSupport(value: ContactTypeUser[])
  {
    this._clientSupport = value;
  }

  get briefManagers(): ContactTypeUser[]
  {
    return this._briefManagers;
  }
  set briefManagers(value: ContactTypeUser[])
  {
    this._briefManagers = value;
  }

  get nationalProjectManagers(): ContactTypeUser[]
  {
    return this._nationalProjectManagers;
  }
  set nationalProjectManagers(value: ContactTypeUser[])
  {
    this._nationalProjectManagers = value;
  }

  get programmers(): ContactTypeUser[]
  {
    return this._programmers;
  }
  set programmers(value: ContactTypeUser[])
  {
    this._programmers = value;
  }

  get adOps(): ContactTypeUser[]
  {
    return this._adOps;
  }
  set adOps(value: ContactTypeUser[])
  {
    this._adOps = value;
  }

  get clientExecutiveLabelText(): string
  {
    if (!this._clientExecutiveLabelText && this.clientExecutives && this.clientExecutives.length > 0)
    {
      this._clientExecutiveLabelText = this.clientExecutives[0].ContactTypeLabelText;
    }

    if (!this._clientExecutiveLabelText && this.clientExecutives.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._clientExecutiveLabelText = this.clientExecutives[1].ContactTypeLabelText;
    }

    if (!this._clientExecutiveLabelText)
    {
      //set label default value if still ""
      this._clientExecutiveLabelText = "Account Lead";
    }

    return this._clientExecutiveLabelText;
  }

  get clientSupportLabelText(): string
  {
    if (!this._clientSupportLabelText && this.clientSupport && this.clientSupport.length > 0)
    {
      this._clientSupportLabelText = this.clientSupport[0].ContactTypeLabelText;
    }

    if (!this._clientSupportLabelText && this.clientSupport.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._clientSupportLabelText = this.clientSupport[1].ContactTypeLabelText;
    }

    if (!this._clientSupportLabelText)
    {
      //set label default value if still ""
      this._clientSupportLabelText = "Account Support";
    }

    return this._clientSupportLabelText;
  }

  get briefManagerLabelText(): string
  {
    if (!this._briefManagerLabelText && this.briefManagers && this.briefManagers.length > 0)
    {
      this._briefManagerLabelText = this.briefManagers[0].ContactTypeLabelText;
    }

    if (!this._briefManagerLabelText && this.briefManagers.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._briefManagerLabelText = this.briefManagers[1].ContactTypeLabelText;
    }

    if (!this._briefManagerLabelText)
    {
      //set label default value if still ""
      this._briefManagerLabelText = "Brief Manager";
    }

    return this._briefManagerLabelText;
  }

  get projectManagerLabelText(): string
  {
    if (!this._projectManagerLabelText && this.projectManagers && this.projectManagers.length > 0)
    {
      this._projectManagerLabelText = this.projectManagers[0].ContactTypeLabelText;
    }

    if (!this._projectManagerLabelText && this.projectManagers.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._projectManagerLabelText = this.projectManagers[1].ContactTypeLabelText;
    }

    if (!this._projectManagerLabelText)
    {
      //set label default value if still ""
      this._projectManagerLabelText = "Delivery Manager";
    }

    return this._projectManagerLabelText;
  }

  get nationalProjectManagerLabelText(): string
  {
    if (!this._nationalProjectManagerLabelText && this.nationalProjectManagers && this.nationalProjectManagers.length > 0)
    {
      this._nationalProjectManagerLabelText = this.nationalProjectManagers[0].ContactTypeLabelText;
    }

    if (!this._nationalProjectManagerLabelText && this.nationalProjectManagers.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._nationalProjectManagerLabelText = this.nationalProjectManagers[1].ContactTypeLabelText;
    }

    if (!this._nationalProjectManagerLabelText)
    {
      //set label default value if still ""
      this._nationalProjectManagerLabelText = "Campaign Delivery Manager";
    }

    return this._nationalProjectManagerLabelText;
  }

  get programmerLabelText(): string
  {
    if (!this._programmerLabelText && this.programmers && this.programmers.length > 0)
    {
      this._programmerLabelText = this.programmers[0].ContactTypeLabelText;
    }

    if (!this._programmerLabelText && this.programmers.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._programmerLabelText = this.programmers[1].ContactTypeLabelText;
    }

    if (!this._programmerLabelText)
    {
      //set label default value if still ""
      this._programmerLabelText = "Programmer";
    }

    return this._programmerLabelText;
  }

  get adOpsLabelText(): string
  {
    if (!this._adOpsLabelText && this.adOps && this.adOps.length > 0)
    {
      this._adOpsLabelText = this.adOps[0].ContactTypeLabelText;
    }

    if (!this._adOpsLabelText && this.adOps.length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._adOpsLabelText = this.adOps[1].ContactTypeLabelText;
    }

    if (!this._adOpsLabelText)
    {
      //set label default value if still ""
      this._adOpsLabelText = "Ad Ops";
    }

    return this._adOpsLabelText;
  }















  getContactTypeUsers(contactTypeId: number, useCachedData: boolean = false): Observable<ContactTypeUser[]>
  {
    return this.dataHandler.getHttpObservable<ContactTypeUser[]>(this.configurationService.cbSettings.calendarServiceUrl + "/GetContactTypeUsers?contactTypeId=" + contactTypeId, useCachedData, false);
  }

  getDayParts(useCachedData: boolean = false): Observable<DayPart[]>
  {
    return this.dataHandler.getHttpObservable<DayPart[]>(this.configurationService.cbSettings.adminServiceUrl + "/GetDayParts", useCachedData, false);
  }

  getClients(useCachedData: boolean = false): Observable<Client[]>
  {
    return this.dataHandler.getHttpObservable<Client[]>(this.configurationService.cbSettings.adminServiceUrl + "/GetClients", useCachedData, false);
  }

  getCampaigns(useCachedData: boolean = false): Observable<Campaign[]>
  {
    return this.dataHandler.getHttpObservable<Campaign[]>(this.configurationService.cbSettings.campaignServiceUrl + "/GetCampaigns", useCachedData, false);
  }
}



