import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { EventCategory } from "../administration.models";
import { AdministrationService } from '../administration.service';
import { EventCategoryEditComponent } from "./event-category-edit.component";


@Component({
  templateUrl: './event-categories.component.html',
  styleUrls: ['./event-categories.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class EventCategoriesComponent extends MatTableCrudComponent<EventCategory>
{
  public NameFilter: any;
  public ColourNameFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      EventCategoryEditComponent,
      EventCategoryEditComponent,
      EventCategoryEditComponent,
      "CreateEventCategory",
      "DeleteEventCategory",
      "GetEventCategories",
      "UpdateEventCategory",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'Name', 'ColourName'];
    this.filteredValues = [{ Field: "Name", Value: "", Path: "", DataType: FilterDataType.String }, { Field: "ColourName", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    //Set to false because Property Types are system objects and shouldn't be added by users only limited editing.
    this.CanAdd = false; //this.authorisationService.hasPermission(this.authorisationService.Permissions.AddEventCategories);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditEventCategories);
    this.CanDelete = false; //this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteEventCategories);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, eventCategory: EventCategory)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this event category (${eventCategory.Name})?`;
    this.deleteHeaderText = "Delete Event Category?";

    super.deleteItem(event, eventCategory);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

