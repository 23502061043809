import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Station } from '../../../components/calendars/calendars.models';
import { User } from '../../models/common.models';
import { CommonService } from '../../services/common.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DataHandlerService } from '../../services/data-handler.service';
import { DashboardData } from './dashboard.models';

@Injectable({ providedIn: 'root' })

export class DashboardService
{
  private _dashboardData: DashboardData = null;
  private _stationStatusData: any[] = [];
  private _userStatusData: any[] = [];
  private _homeStation: Station = null;
  private _currentUser: User = null;

  constructor(
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private dataHandler: DataHandlerService)
  {

  }

  get currentUser(): User
  {
    return this._currentUser;
  }
  set currentUser(value: User)
  {
    this._currentUser = value;
  }


  resetDashboardData()
  {
    this._dashboardData = null;
    this._stationStatusData = [];
    this._userStatusData = [];
  }

  get dashboardData(): DashboardData
  {
    return this._dashboardData;
  }
  set dashboardData(value: DashboardData)
  {
    this._dashboardData = value;

    if (value)
    {
      this.updateDashboardData();
    }
  }

  get homeStation(): Station
  {
    return this._homeStation;
  }
  set homeStation(value: Station)
  {
    this._homeStation = value;
  }



  get stationStatusData(): any[]
  {
    if ((!this._stationStatusData || this._stationStatusData.length == 0) && this._dashboardData && this._dashboardData.ActivitiesStationStatusThisMonth)
    {
      this._stationStatusData = [];

      this._stationStatusData.push({ "name": "First Right of Refusal", "value": this._dashboardData.ActivitiesStationStatusThisMonth.Guaranteed, "colour": "#22a0ce" });
      this._stationStatusData.push({ "name": "On Hold", "value": this._dashboardData.ActivitiesStationStatusThisMonth.OnHold, "colour": "#684579" });
      this._stationStatusData.push({ "name": "Pending", "value": this._dashboardData.ActivitiesStationStatusThisMonth.Pending, "colour": "#73bf4c" });
      this._stationStatusData.push({ "name": "Confirmed", "value": this._dashboardData.ActivitiesStationStatusThisMonth.Sold, "colour": "#ef5252" });
      this._stationStatusData.push({ "name": "Submitted for Delivery", "value": this._dashboardData.ActivitiesStationStatusThisMonth.UnConfirmedSold, "colour": "#e0e000" });
    }

    return this._stationStatusData;
  }

  get stationStatusDataTotal(): number
  {
    return this.stationStatusData.reduce((sum, current) => sum + current.value, 0);
  }

  get userStatusData(): any[]
  {
    if ((!this._userStatusData || this._userStatusData.length == 0) && this._dashboardData && this._dashboardData.ActivitiesUserStatusThisMonth)
    {
      this._userStatusData = [];

      this._userStatusData.push({ "name": "First Right of Refusal", "value": this._dashboardData.ActivitiesUserStatusThisMonth.Guaranteed, "colour": "#22a0ce" });
      this._userStatusData.push({ "name": "On Hold", "value": this._dashboardData.ActivitiesUserStatusThisMonth.OnHold, "colour": "#684579" });
      this._userStatusData.push({ "name": "Pending", "value": this._dashboardData.ActivitiesUserStatusThisMonth.Pending, "colour": "#73bf4c" });
      this._userStatusData.push({ "name": "Confirmed", "value": this._dashboardData.ActivitiesUserStatusThisMonth.Sold, "colour": "#ef5252" });
      this._userStatusData.push({ "name": "Submitted for Delivery", "value": this._dashboardData.ActivitiesUserStatusThisMonth.UnConfirmedSold, "colour": "#e0e000" });
    }

    return this._userStatusData;
  }

  get userStatusDataTotal(): number
  {
    return this.userStatusData.reduce((sum, current) => sum + current.value, 0);
  }

  updateDashboardData()
  {
    if (this._dashboardData.DropDeadActivities && this._dashboardData.DropDeadActivities.length > 0)
    {
      this._dashboardData.DropDeadActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (this._dashboardData.OnHoldActivities && this._dashboardData.OnHoldActivities.length > 0)
    {
      this._dashboardData.OnHoldActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (this._dashboardData.CreditLineDueActivities && this._dashboardData.CreditLineDueActivities.length > 0)
    {
      this._dashboardData.CreditLineDueActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (this._dashboardData.FinalPcrActivities && this._dashboardData.FinalPcrActivities.length > 0)
    {
      this._dashboardData.FinalPcrActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (this._dashboardData.FirstDayAirCheckActivities && this._dashboardData.FirstDayAirCheckActivities.length > 0)
    {
      this._dashboardData.FirstDayAirCheckActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }
  }

  getDashboardData(userId: number, month: number = this.commonService.today.getMonth() + 1, year: number = this.commonService.today.getFullYear(), isOnline: boolean = false, useCachedData: boolean = false): Observable<DashboardData>
  {
    this.resetDashboardData();

    return this.dataHandler.getHttpObservable<DashboardData>(this.configurationService.cbSettings.dashboardServiceUrl + `/GetDashboardData?month=${month}&year=${year}&userId=${userId}&isOnline=${isOnline}`, useCachedData, false);
  }


}
