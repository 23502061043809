import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { StationType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { StationTypeEditComponent } from "./station-type-edit.component";


@Component({
  templateUrl: './station-types.component.html',
  styleUrls: ['./station-types.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class StationTypesComponent extends MatTableCrudComponent<StationType>
{
  public StationTypeNameFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      StationTypeEditComponent,
      StationTypeEditComponent,
      StationTypeEditComponent,
      "CreateStationType",
      "DeleteStationType",
      "GetStationTypes",
      "UpdateStationType",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'StationTypeName'];
    this.filteredValues = [{ Field: "StationTypeName", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationTypes);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationTypes);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationTypes);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, stationtype: StationType)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this platform group (${stationtype.StationTypeName})?`;
    this.deleteHeaderText = "Delete Platform Group?";

    super.deleteItem(event, stationtype);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

