import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { InterfaceImport } from '../../../shared/models/common.models';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ControlPanelService } from './control-panel.service';

@Component(
  {
    templateUrl: 'control-panel.component.html',
    styleUrls: ['./control-panel.component.scss']
  })
export class ControlPanelComponent implements OnInit
{
  public form: UntypedFormGroup;

  constructor(public fb: UntypedFormBuilder, private controlPanelService: ControlPanelService,
    private commonService: CommonService,
    public configurationService: ConfigurationService,
    private confirmDialog: MatDialog,)
  {
    this.form = this.fb.group({
      ClientsStartDate: [new Date()],
      ActivityStartDate: [new Date()]
    });
  }

  ngOnInit()
  {

  }

  ResetActivitiesCache()
  {
    this.controlPanelService.resetActivitiesCache().then(result =>
    {
      let msg = "Unable to reset activities cache.";

      if (result == true)
      {
        msg = "Activities cache was successfully reset.";
      }

      this.commonService.notify("Reset Cache", msg, result);
    });
  }

  ResetPropertiesCache()
  {
    this.controlPanelService.resetPropertiesCache().then(result =>
    {
      let msg = "Unable to reset properties cache.";

      if (result == true)
      {
        msg = "Properties cache was successfully reset.";
      }

      this.commonService.notify("Reset Cache", msg, result);
    });
  }

  ResetCategoriesCache()
  {
    this.controlPanelService.resetCategoriesCache().then(result =>
    {
      let msg = "Unable to reset categories cache.";

      if (result == true)
      {
        msg = "Categories cache was successfully reset.";
      }

      this.commonService.notify("Reset Cache", msg, result);
    });
  }

  ResetClientsCache()
  {
    this.controlPanelService.resetClientsCache().then(result =>
    {
      let msg = "Unable to reset clients cache.";

      if (result == true)
      {
        msg = "Clients cache was successfully reset.";
      }

      this.commonService.notify("Reset Cache", msg, result);
    });
  }

  ResetProductCategoriesCache()
  {
    this.controlPanelService.resetProductCategoriesCache().then(result =>
    {
      let msg = "Unable to reset client categories cache.";

      if (result == true)
      {
        msg = "Client Categories cache was successfully reset.";
      }

      this.commonService.notify("Reset Cache", msg, result);
    });
  }

  PurgeAuditTrail()
  {
    this.controlPanelService.purgeAuditTrail().then(result =>
    {
      let msg = "Unable to purge audit trail.";

      if (result == true)
      {
        msg = "Audit trail was successfully purged.";
      }

      this.commonService.notify("Purge Audit Trail", msg, result);
    });
  }

  ImportClients()
  {
    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to import clients?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Import Clients?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        let startdate: any = this.form.get("ClientsStartDate").value;
        let interfaceImport: InterfaceImport = new InterfaceImport;
        interfaceImport.StartDate = startdate;

        this.controlPanelService.importClientsAsync(interfaceImport);
        this.commonService.SubscribeToMessages();
      }
    });
  }

  ImportRates()
  {
    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to import rates?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Import Rates?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        let interfaceImport: InterfaceImport = new InterfaceImport;
        this.controlPanelService.importRatesAsync(interfaceImport);
        this.commonService.SubscribeToMessages();
      }
    });
  }

  ImportUsers()
  {
    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to import users?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Import Users?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        let interfaceImport: InterfaceImport = new InterfaceImport;
        this.controlPanelService.importUsersAsync(interfaceImport);
        this.commonService.SubscribeToMessages();
      }
    });
  }

  ImportActivities()
  {
    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to import activities?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Import Activities?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        let startdate: any = this.form.get("ActivityStartDate").value;
        let interfaceImport: InterfaceImport = new InterfaceImport;
        interfaceImport.StartDate = startdate;

        this.controlPanelService.importActivitiesAsync(interfaceImport);
        this.commonService.SubscribeToMessages();
      }
    });
  }

  ImportProductCategories()
  {
    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to import client categories?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Import Client Categories?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        let interfaceImport: InterfaceImport = new InterfaceImport;
        this.controlPanelService.importProductCategoriesAsync(interfaceImport);
        this.commonService.SubscribeToMessages();
      }
    });
  }














}
