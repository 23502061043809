import { registerLocaleData } from '@angular/common';
import localeAU from '@angular/common/locales/en-AU';
import localeGB from '@angular/common/locales/en-GB';
import localeUS from '@angular/common/locales/es-US';
import { Inject, Injectable, LOCALE_ID, OnDestroy, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event as NavigationEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Route, Router } from '@angular/router';
import { IntlService } from '@progress/kendo-angular-intl';
import { parse } from 'date-fns';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DashboardData } from '../../shared/components/dashboard/dashboard.models';
import { DashboardService } from '../../shared/components/dashboard/dashboard.service';
import { GroupType, User } from '../../shared/models/common.models';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CommonDataService } from '../../shared/services/common-data.service';
import { CommonService } from '../../shared/services/common.service';
import { CbSettings, ConfigurationService } from '../../shared/services/configuration.service';
import { CustomKendoIntlService } from '../../shared/services/custom-kendo-intl.service';
import { CalendarInfo, ContactTypeId, Station, WeekListItem } from '../calendars/calendars.models';
import { CalendarsService } from '../calendars/calendars.service';
import { OnlineCalendarsService } from '../calendars/online-calendars.service';
import { DiaryService } from '../diary/diary.service';
import { ReportService } from '../reports/report.service';
import { AppConfig } from './app.config';
import { appRoutes, ClientRoute, ClientRoutes, RouteCategory } from './app.routing';
import { QueryClientService } from '@ngneat/query';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { ResolveDataService } from '../../shared/services/resolve-data.service';

export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

@Injectable({ providedIn: 'root' })

export class AppService implements OnDestroy
{
  public routes: ClientRoutes;
  public routeCategories: RouteCategory[] = [];

  private userPermissionsChangedSubscription: Subscription;
  private routerEventsSubscription: Subscription;

  private queryClient = inject(QueryClientService);
  private dataHandler = inject(DataHandlerService);

  public resolveDataService = inject(ResolveDataService);

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    private router: Router,
    private commonService: CommonService,
    private commonDataService: CommonDataService,
    private authenticationService: AuthenticationService,
    private authorisationService: AuthorisationService,
    private appConfig: AppConfig,
    private configurationService: ConfigurationService,
    private calendarsService: CalendarsService,
    private onlineCalendarsService: OnlineCalendarsService,
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private diaryService: DiaryService,
    public intlService: IntlService,
    private titleService: Title
  )
  {
    this.userPermissionsChangedSubscription = this.authenticationService.userPermissionsChange.subscribe(() =>
    {
      this.setUpRoutes();
    });

    if (this.commonService.isLoggedIn)
    {
      this.setUpRoutes();
    }
  }

  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.routerEventsSubscription)
    {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.userPermissionsChangedSubscription)
    {
      this.userPermissionsChangedSubscription.unsubscribe();
    }
  }

  public loadApplication(): Promise<any>
  {
    return this.appConfig.load()
      .then((res: CbSettings) =>        
      {
        this.configurationService.cbSettings = res;

        if (res)
        {
          //culture from app.settings
          let culture: string = res.systemLocale;

          if (!culture)
          {
            //get culture from client locale if app.setting locale is empty
            if (navigator)
            {
              if (navigator.language)
              {
                culture = navigator.language;
              }
              else if (navigator.languages && navigator.languages.length > 0)
              {
                culture = navigator.languages[0];
              }
            }
          }

          switch (culture)
          {
            case 'en-AU':
              registerLocaleData(localeAU);
              localStorage.setItem(CURRENT_LOCALE, JSON.stringify(localeAU));
              break;
            case 'en-GB':
              registerLocaleData(localeGB);
              localStorage.setItem(CURRENT_LOCALE, JSON.stringify(localeGB));
              break;
            case 'en-US':
              registerLocaleData(localeUS);
              localStorage.setItem(CURRENT_LOCALE, JSON.stringify(localeUS));
              break;
          }

          localStorage.setItem(CURRENT_LOCALE_NAME, culture);

          this.localeId = culture;

          //set locale for Kendo controls
          (<CustomKendoIntlService>this.intlService).localeId = culture;
        }

        this.authenticationService.resetAuthentication();

        this.authenticationService.setupUserManager();
      })
      .catch((err: any) =>
      {
        // this.loggerService.error(err);
      });
  }



  subscribeToHubEvents(currentUserId: number, homeStationId: number)
  {
    if (currentUserId && homeStationId)
    {
      //if (this.configurationService.cbSettings.subscribeToCalendarHubEvents)
      //{
      this.commonService.appSignalRService.hubConnection.on('ReceiveCalendarHtml', (data: any) =>
      {
        if (!data)
        {
          this.calendarsService.resetHttpCache();
          //  this.calendarsService.resetCalendarData();
        }
        else
        {
          this.calendarsService.resetHttpCache();
        }
      });
      //}

      //if (this.configurationService.cbSettings.subscribeToDashboardHubEvents)
      //{
      this.commonService.appSignalRService.hubConnection.on('ReceiveDashboardData', (data: DashboardData) =>
      {
        this.dashboardService.dashboardData = data;
      });

      this.commonService.appSignalRService.hubConnection.on('ReceiveRefreshDashboardDataMessage', (data: any) =>
      {
        lastValueFrom(this.dashboardService.getDashboardData(currentUserId)).then(data => this.dashboardService.dashboardData = data);
      });
      //}
    }
  }


  unsubscribeFromHubEvents()
  {
    if (this.commonService && this.commonService.appSignalRService && this.commonService.appSignalRService.hubConnection)
    {
      //if (this.configurationService.cbSettings.subscribeToCalendarHubEvents)
      //{
      this.commonService.appSignalRService.hubConnection.off('ReceiveCalendarHtml');
      //}

      //if (this.configurationService.cbSettings.subscribeToDashboardHubEvents)
      //{
      this.commonService.appSignalRService.hubConnection.off('ReceiveDashboardData');
      this.commonService.appSignalRService.hubConnection.off('ReceiveRefreshDashboardDataMessage');
      //}
    }
  }

  setPropertyTypeIdsFromStorage(): boolean
  {
    let selectedPropertyTypeIds = JSON.parse(localStorage.getItem("selectedPropertyTypeIds"));

    if (selectedPropertyTypeIds && Array.isArray(selectedPropertyTypeIds) && selectedPropertyTypeIds.length > 0)
    {
      this.calendarsService.selectedPropertyTypeIds = selectedPropertyTypeIds;

      return true;
    }

    return false;
  }

  setCategoryIdsFromStorage(): boolean
  {
    let selectedCategoryIds = JSON.parse(localStorage.getItem("selectedCategoryIds"));

    if (selectedCategoryIds && Array.isArray(selectedCategoryIds) && selectedCategoryIds.length > 0)
    {
      this.calendarsService.selectedCategoryIds = selectedCategoryIds;

      return true;
    }

    return false;
  }

  setStationsFromStorage(): boolean
  {
    let selectedStations = JSON.parse(localStorage.getItem("selectedStations"));

    if (selectedStations && Array.isArray(selectedStations) && selectedStations.length > 0)
    {
      this.calendarsService.selectedStations = selectedStations;

      let stationGroupId = this.calendarsService.selectedStations[0].StationTypeId;
      this.calendarsService.selectedStationGroupId = stationGroupId;

      if (this.calendarsService.selectedStations.some(s => s.StationTypeId != stationGroupId))
      {
        //set to all groups if selected stations in different groups
        this.calendarsService.selectedStationGroupId = 0;
      }

      this.calendarsService.setNoOfWeeks();

      return true;
    }

    return false;
  }

  setYearFromStorage(): boolean
  {
    let selectedYear = JSON.parse(localStorage.getItem("selectedYear"));

    if (selectedYear)
    {
      this.calendarsService.selectedYear = selectedYear;

      return true;
    }

    return false;
  }

  setWeekFromStorage(): boolean
  {
    let selectedWeek = JSON.parse(localStorage.getItem("selectedWeek"));

    if (selectedWeek)
    {
      this.calendarsService.selectedWeek = selectedWeek;

      return true;
    }

    return false;
  }

  setCalendarFilterFromStorage(): boolean
  {
    let hasParametersSaved = false;

    if (this.setPropertyTypeIdsFromStorage())
    {
      hasParametersSaved = true;
    }

    if (this.setCategoryIdsFromStorage())
    {
      hasParametersSaved = true;
    }

    if (this.setStationsFromStorage())
    {
      hasParametersSaved = true;
    }

    if (this.setYearFromStorage())
    {
      hasParametersSaved = true;
    }

    if (this.setWeekFromStorage())
    {
      hasParametersSaved = true;
    }

    return hasParametersSaved;
  }

  setCalendarDefaults(currentUser: User)
  {
    if (currentUser != null && currentUser.Id != null && currentUser.Id > 0)
    {
      this.commonService.appSignalRService.run('AddToGroupAsync', "home", GroupType.Navigation);

      if (!this.calendarsService.CalendarHtml)
      {
        this.setYearFromStorage();
        this.setPropertyTypeIdsFromStorage();
        this.setCategoryIdsFromStorage();

        lastValueFrom(this.calendarsService.getWeekListItems()).then(
          data =>
          {
            this.calendarsService.weeks = data;

            if (this.calendarsService.weeks && this.calendarsService.weeks.length > 0)
            {
              this.calendarsService.weeks.map((w: WeekListItem) => w.WeekCommencingDate = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today));
            }

            if (!this.setWeekFromStorage())
            {
              let weekNoForToday = this.calendarsService.getWeekNoForDate(this.commonService.today);

              let weekForToday = this.calendarsService.weeks.filter(w => w.WeekNo == weekNoForToday)[0];
              this.calendarsService.selectedWeek = weekForToday;
            }

            lastValueFrom(this.calendarsService.getStationGroups()).then(
              data =>
              {
                if (data && data.length > 0)
                {
                  this.calendarsService.stationGroups = data;

                  lastValueFrom(this.calendarsService.getStations()).then(
                    data =>
                    {
                      var that = this;

                      if (data && data.length > 0)
                      {
                        this.calendarsService.stations = data;

                        let homestation: Station = this.calendarsService.stations.filter(s => s.Id == currentUser.HomeStationId)[0];

                        if (!this.setStationsFromStorage())
                        {
                          this.calendarsService.noOfWeeks = 5;

                          this.calendarsService.resetSelectedStations();

                          let newSelectedStations: Station[] = [];                          

                          if (homestation)
                          {
                            //Select user's home station.
                            this.calendarsService.selectedStationGroupId = homestation.StationType.Id;
                            newSelectedStations.push(homestation);

                            this.calendarsService.homeStation = homestation;
                          }
                          else
                          {
                            //Select 1st station in list if home station not found.
                            this.calendarsService.selectedStationGroupId = this.calendarsService.stations[0].StationType.Id;
                            newSelectedStations.push(this.calendarsService.stations[0]);
                          }

                          //selected Stations set NOT added to with push so selectedStations localStorage saved
                          this.calendarsService.selectedStations = newSelectedStations;
                        }

                        this.calendarsService.resetCalendarData();


                        //// BELOW IS NEEDED FOR WHEN ACTIVITIES IN DASHBOARD ARE ACCESSED BEFORE ANY NAVIGATION BECAUSE RESOLVERS HAVE NOT BEEN CALLED /////
                        setTimeout(() =>
                        {
                          this.resolveDataService.resolveProperties();
                          this.resolveDataService.resolveCategories();
                          this.resolveDataService.resolveDayParts();
                          this.resolveDataService.resolvePropertyTypes();
                          this.resolveDataService.resolveProjectManagers();
                          this.resolveDataService.resolveProgrammers();
                          this.resolveDataService.resolveClientCategories();
                          this.resolveDataService.resolveNationalProjectManagers();
                          this.resolveDataService.resolveMechanicTypes();
                          this.resolveDataService.resolveClientExecutives();
                          this.resolveDataService.resolveClientSupports();
                          this.resolveDataService.resolveBriefManagers();
                          this.resolveDataService.resolveClients();
                          this.resolveDataService.resolvePlatformTypes();
                          this.resolveDataService.resolveAdOps();
                          this.resolveDataService.resolveExtensions();
                          this.resolveDataService.resolveEventCategories();


                          //Prefetch calendar data before GetClients which takes a long time
                          this.calendarsService.calendarParameters$.pipe(take(1)).subscribe(parameters =>
                          {
                            this.queryClient.prefetchQuery([parameters], () =>
                              firstValueFrom(this.dataHandler.postHttpObservable<CalendarInfo>(this.configurationService.cbSettings.calendarServiceUrl + "/GetEventDaysAndCalendarHtml", parameters, false, false)),
                              { staleTime: this.configurationService.cbSettings.prefetchQueryStaleTime }
                            );
                          });

                          this.resolveDataService.resolveClients();
                        }, 0);
                      }
                    }
                  ).catch((err: any) =>
                  {

                  });
                }
              }
            ).catch((err: any) =>
            {

            });
          }
        ).catch((err) =>
        {

        });
      }


      //  if (this.configurationService.cbSettings.showDigitalCalendar && !this.onlineCalendarsService.CalendarHtml)
      //  {
      //    this.onlineCalendarsService.getWeekListItems(this.onlineCalendarsService.selectedYear).then(
      //      data =>
      //      {
      //        this.onlineCalendarsService.weeks = data;

      //        if (this.onlineCalendarsService.weeks && this.onlineCalendarsService.weeks.length > 0)
      //        {
      //          this.onlineCalendarsService.weeks.map((w: WeekListItem) => w.WeekCommencingDate = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today));
      //        }

      //        this.onlineCalendarsService.selectedWeekNo = this.onlineCalendarsService.getWeekNoForDate(this.commonService.today);

      //        this.onlineCalendarsService.getStationGroups().then(
      //          data =>
      //          {
      //            if (data && data.length > 0)
      //            {
      //              this.onlineCalendarsService.stationGroups = data;

      //              this.onlineCalendarsService.getStations().then(
      //                data =>
      //                {
      //                  if (data && data.length > 0)
      //                  {
      //                    var that = this;
      //                    this.onlineCalendarsService.stations = data;

      //                    this.onlineCalendarsService.resetSelectedStations();

      //                    this.onlineCalendarsService.noOfWeeks = 5;

      //                    this.onlineCalendarsService.selectedStations = [];

      //                    let homestation: Station = this.onlineCalendarsService.stations.filter(s => s.Id == currentUser.HomeStationId)[0];

      //                    if (homestation)
      //                    {
      //                      //Select user's home station.
      //                      this.onlineCalendarsService.selectedStationGroupId = homestation.StationType.Id;
      //                      this.onlineCalendarsService.selectedStations.push(homestation);
      //                    }
      //                    else
      //                    {
      //                      //Select 1st station in list if home station not found.
      //                      this.onlineCalendarsService.selectedStationGroupId = this.onlineCalendarsService.stations[0].StationType.Id;
      //                      this.onlineCalendarsService.selectedStations.push(this.onlineCalendarsService.stations[0]);
      //                    }

      //                    this.onlineCalendarsService.resetCalendarData();
      //                  }
      //                }
      //              ).catch((err) =>
      //              {

      //              });
      //            }
      //          }
      //        ).catch((err) =>
      //        {

      //        });
      //      }
      //    ).catch((err) =>
      //    {

      //    });
      //  }
    }
    else
    {
      this.router.navigate(['/unauthorised']);
    }
  }

  private setUpRoutes()
  {
    this.routeCategories = [];

    this.routes = JSON.parse(JSON.stringify(appRoutes));

    if (this.routes && this.routes.length > 0)
    {
      let routesToRemove: ClientRoute[] = [];

      //loop to get unauthorised routes
      for (let route of this.routes)
      {
        let hasPermissionForRoute = false;

        switch (route.path.replace('/', ''))
        {
          case 'addcampaign':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCampaigns);
            break;
          case 'editcampaign':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCampaigns);
            break;
          case 'campaigns':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteCampaigns)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCampaigns)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCampaigns)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCampaigns);
            break;
          case 'calendar':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewStationCalendar);
            break;
          case 'onlinecalendar':
            hasPermissionForRoute = this.configurationService.cbSettings.showDigitalCalendar && this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewDigitalCalendar);
            break;
          case 'diary':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditWhatsOnEvent)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteWhatsOnEvent);
            break;
          case 'search':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.Search);
            break;
          case 'toolscopyactivityinfo':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanCopyActivityInformation);
            break;
          case 'toolscopycreditlines':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanCopyCreditLines);
            break;
          case 'toolscopypropertyinfo':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanCopyPropertyInformation);
            break;
          case 'toolsreplacecontacts':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanReplaceActivityContacts);
            break;
          case 'audit':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.Audit);
            break;
          case 'reports':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitiesSummaryReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewRevenueReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewDigitalReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditLineReport)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditReport);
            break;
          case 'contacttypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddContactTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteContactTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditContactTypes);
            break;
          case 'controlpanel':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.CanUseControlPanel);
            break;
          case 'eventcategories':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddEventCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteEventCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditEventCategories);
            break;
          case 'extensions':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddExtensions)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteExtensions)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditExtensions);
            break;
          case 'clients':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddClients)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteClients)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditClients);
            break;
          case 'departments':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddDepartments)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteDepartments)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDepartments);
            break;
          case 'dayparts':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddDayParts)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteDayParts)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDayParts);
            break;
          case 'jobs':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddJobs)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteJobs)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditJobs);
            break;
          case 'mechanictypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddMechanicTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteMechanicTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditMechanicTypes);
            break;
          case 'onlinedependencytypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddDigitalDependencyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteDigitalDependencyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditDigitalDependencyTypes);
            break;
          case 'productcategories':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProductCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteProductCategories)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProductCategories);
            break;
          case 'propertytypes':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddPropertyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeletePropertyTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditPropertyTypes);
            break;
          case 'roles':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddRoles)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteRoles)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditRoles);
            break;
          case 'platforms':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStations)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStations)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStations);
            break;
          case 'platformgroups':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationTypes)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationTypes);
            break;
          case 'notifygroupmembers':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddStationNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteStationNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditStationNotificationGroups);
            break;
          case 'notifygroups':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteNotificationGroups)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditNotificationGroups);
            break;
          case 'users':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteUsers)
              || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditUsers);
            break;
          case 'currentUsers':
            hasPermissionForRoute = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCurrentUsers);
            break;
          case 'login':
            hasPermissionForRoute = true;
            break;
          case 'register':
            hasPermissionForRoute = true;
            break;
          case 'home':
            hasPermissionForRoute = true;
            break;
          case 'signin-callback':
            hasPermissionForRoute = true;
            break;
          case 'signout-callback':
            hasPermissionForRoute = true;
            break;
          case 'unauthorised':
            hasPermissionForRoute = true;
            break;
          default:
            hasPermissionForRoute = false;
            break;
        }

        if (!hasPermissionForRoute)
        {
          // role not authorised
          routesToRemove.push(route);
        }
      }

      for (let route of routesToRemove)
      {
        //remove unauthorised routes.
        this.routes = this.routes.filter((r: Route) => r.path != route.path);
      }

      //loop to get categories after the unauthorised routes have been removed so categories with no authorised routes are not added
      for (let route of this.routes)
      {
        let rc: RouteCategory = new RouteCategory();
        rc.CategoryName = route.category;
        rc.Path = route.path;
        rc.Text = route.text;
        rc.RoutesInCategory = this.routes.filter(r => r.category == route.category).length;

        if (!this.routeCategories.some(r => r.CategoryName == route.category) && route.visible)
        {
          this.routeCategories.push(rc);
        }
      }

      if (this.routes != null && !this.routerEventsSubscription)
      {
        this.routerEventsSubscription = this.router.events.subscribe((event: NavigationEvent) =>
        {
          if (event instanceof NavigationStart)
          {
            this.commonService.isNavigating = true;
            this.commonService.browserRefresh = !this.router.navigated || (this.commonService.previouspage != null && this.commonService.currentpage != null && this.commonService.previouspage == this.commonService.currentpage);
            this.commonService.previouspage = this.commonService.currentpage;
          }

          if (event instanceof NavigationEnd)
          {
            this.commonService.isNavigating = false;

            if (event && event.urlAfterRedirects)
            {
              this.commonService.currentpage = event.urlAfterRedirects;

              const clientRoute: any = this.routes.filter((route) =>
              {
                return route.path === this.commonService.currentpage.replace('/', '');
              })[0];

              if (clientRoute)
              {
                this.commonService.showPageHeader = clientRoute.showPageHeader;

                this.commonService.currentpagecategory = clientRoute.category;

                this.commonService.currentpagetitle = clientRoute.text;

                let title: string = "";

                if (this.commonService.currentpagetitle)
                {
                  title = ` - ${this.commonService.currentpagetitle}`;
                }

                this.titleService.setTitle(`Campaign Boss${title}`);
              }
            }
          }

          if (event instanceof NavigationCancel)
          {
            this.commonService.isNavigating = false;

            this.router.navigate(['/']);
          }

          if (event instanceof NavigationError)
          {
            this.commonService.isNavigating = false;
          }
        });
      }
    }



















  }





































}
