import { ChangeDetectorRef, Component, OnDestroy, OnInit, Injector, ViewChild, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ConfigurationService } from '@progress/kendo-angular-charts';
import { Subscription } from 'rxjs';
import { User } from '../../shared/models/common.models';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';
import { CalendarsService } from '../calendars/calendars.service';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy
{
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  private currentUserInfoRetrievedSubscription: Subscription;
  private loginChangedSubscription: Subscription;
  private isNavigatingChangedSubscription: Subscription;
  private loadingTextChangedSubscription: Subscription;

  public router: Router;

  private cdr = inject(ChangeDetectorRef);
  private authenticationService = inject(AuthenticationService);
  public commonService = inject(CommonService);
  public appService = inject(AppService);


  constructor(private injector: Injector)
  {
    this.router = this.injector.get(Router);

    this.currentUserInfoRetrievedSubscription = this.authenticationService.currentUserInfoRetrieved.subscribe((user: User) =>
    {
      if (user)
      {
        this.commonService.initialiseHubs(user.Id);
        this.appService.subscribeToHubEvents(user.Id, user.HomeStationId);

        this.commonService.refreshDashboard();
        this.appService.setCalendarDefaults(this.authenticationService.currentUserValue);

       // this.cdr.detectChanges();
      }
    });

    this.loginChangedSubscription = this.authenticationService.loginChanged.subscribe((loggedIn: any) =>
    {
      this.commonService.isLoggedIn = loggedIn;
    });

    this.isNavigatingChangedSubscription = this.commonService.isNavigatingChanged.subscribe((isNavigating: boolean) =>
    {
      this.cdr.detectChanges();
    });

    this.loadingTextChangedSubscription = this.commonService.loadingTextChanged.subscribe((textChanged: boolean) =>
    {
      this.cdr.detectChanges();
    });

    
  }

  ngOnInit()
  {
    
  }

 

  ngOnDestroy()
  {
    this.appService.unsubscribeFromHubEvents();

    if (this.currentUserInfoRetrievedSubscription)
    {
      this.currentUserInfoRetrievedSubscription.unsubscribe();
    }

    if (this.loginChangedSubscription)
    {
      this.loginChangedSubscription.unsubscribe();
    }

    if (this.isNavigatingChangedSubscription)
    {
      this.isNavigatingChangedSubscription.unsubscribe();
    }

    if (this.loadingTextChangedSubscription)
    {
      this.loadingTextChangedSubscription.unsubscribe();
    }
    
  }





}
