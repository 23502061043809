import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
@Component({
  selector: 'app-unauthorised',
  templateUrl: 'unauthorised.component.html',
   styleUrls: ['./home.scss']  
})

export class UnauthorisedComponent implements OnInit
{
  constructor(private _authService: AuthenticationService)
  {
  }

  ngOnInit() { }

  logout()
  {
    this._authService.logout();
  }
}
