﻿﻿<h2 mat-dialog-title>{{confirmTitle}}</h2>
<hr />
<h3 class="mat-h3" style="white-space:pre; font-weight:bold; margin:0;">{{confirmHeader}}</h3>
<mat-dialog-content class="mat-typography">
    <h3 style="white-space:pre">{{confirmMessage}}</h3>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="dialogRef.close(false)" *ngIf="showCancelButton">{{cancelText}}</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="dialogRef.close(true)">{{confirmText}}</button>
</mat-dialog-actions>


