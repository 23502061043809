import { Component, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';
import { AppService } from '../app/app.service';


@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss']
})
export class NavMenuComponent 
{
  @Output() public sideBarToggle: EventEmitter<any> = new EventEmitter<any>();

  constructor(public appService: AppService, public commonService: CommonService, public authenticationService: AuthenticationService)
  {
   
  }

  ngOnInit()
  {



  }


  public sidenavButtonClick(event: any)
  {
    this.sideBarToggle.emit(event);
  }





}
