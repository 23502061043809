import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from "../../../shared/services/common.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { PlatformType, Station, StationType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';


@Component({
  templateUrl: './station-edit.component.html',
  selector: 'station-edit-form',
  styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './stations.component.scss'],
  providers: [MatTableCrudService]
})
export class StationEditComponent extends MatTableCrudEditComponent<StationEditComponent, Station>
{
  public isVendor: boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<StationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Station,
    public commonService: CommonService,
    public administrationService: AdministrationService,
    public authorisationService: AuthorisationService,
    public loggerService: LoggerService
  )
  {
    super(dialogRef, data);

    this.isVendor = this.data != null && this.data.PlatformType != null && this.data.PlatformTypeId == this.commonService.vendorPlatformTypeId;

    this.form = this.fb.group({
      Id: [this.data.Id],
      StationName: [this.data.StationName, Validators.required],
      Contact: [this.data.Contact],
      ContactEmail: [this.data.ContactEmail],
      ContactPhoneNo: [this.data.ContactPhoneNo],
      City: [this.data.City, Validators.required],
      MarketCode: [this.data.MarketCode, Validators.required],
      State: [this.data.State, Validators.required],
      StationType: [this.data.StationType, Validators.required],
      PlatformType: [this.data.PlatformType, Validators.required],
      DaysUntilDropDead: [this.data.DaysUntilDropDead],
      ExternalId: [this.data.ExternalId],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  platformTypeChange(event: any)
  {
    this.isVendor = event && event.value && event.value.Id && event.value.Id == this.commonService.vendorPlatformTypeId;
  }

  onSave(): void
  {
    let station: any = this.form.value;

    if (station)
    {
      if (this.isNew)
      {
        station.Id = 0;

        if (station.Disabled == null)
        {
          station.Disabled = false;
        }
      }

      station[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(station);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Platform Provider";

    if (this.isNew)
    {
      headerText = "New Platform Provider";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.StationName}`;
      }
    }

    return headerText;
  }


  equalsStationType(o1: StationType, o2: StationType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsPlatformType(o1: PlatformType, o2: PlatformType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
