import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { AdministrationService } from '../administration.service';
import { JobEditComponent } from "./job-edit.component";
import { Job } from "../administration.models";


@Component({
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class JobsComponent extends MatTableCrudComponent<Job>
{
  public TitleFilter: any;
  public JobTypeIdFilter: any;
  public JobActionIdFilter: any;
  public StartDateFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      JobEditComponent,
      JobEditComponent,
      JobEditComponent,
      "CreateJob",
      "DeleteJob",
      "GetJobs",
      "UpdateJob",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'Title', 'JobTypeName', 'JobActionName', 'StartDate'];
    this.filteredValues = [
      { Field: "Title", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "JobTypeId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "JobActionId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "StartDate", Value: "", Path: "", DataType: FilterDataType.Date }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "65rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddJobs);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditJobs);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteJobs);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {

    super.addNew(event);
  }

  deleteItem(event: any, job: Job)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this job (${job.Title})?`;
    this.deleteHeaderText = "Delete Job?";

    super.deleteItem(event, job);
  }





  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

