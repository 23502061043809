import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { Extension } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { ExtensionEditComponent } from "./extension-edit.component";


@Component({
  templateUrl: './extensions.component.html',
  selector: 'extension',
  styleUrls: ['./extensions.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService]
})
export class ExtensionsComponent extends MatTableCrudComponent<Extension>
{
  public NameFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      ExtensionEditComponent,
      ExtensionEditComponent,
      ExtensionEditComponent,
      "CreateExtension",
      "DeleteExtension",
      "GetExtensions",
      "UpdateExtension",
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl,
      configurationService.cbSettings.adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'Name'];
    this.filteredValues = [{ Field: "Name", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddExtensions);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditExtensions);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteExtensions);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, extension: Extension)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this extension (${extension.Name})?`;
    this.deleteHeaderText = "Delete Extension?";

    super.deleteItem(event, extension);
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

