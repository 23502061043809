import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { Extension } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';


@Component({
  templateUrl: './extension-edit.component.html',
  selector: 'extension-edit-form',
  styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './extensions.component.scss'],
  providers: [MatTableCrudService]
})
export class ExtensionEditComponent extends MatTableCrudEditComponent<ExtensionEditComponent, Extension>
{
  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<ExtensionEditComponent>, @Inject(MAT_DIALOG_DATA) public data: Extension, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      Name: [this.data.Name, Validators.required],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    let extension: any = this.form.value;

    if (extension)
    {
      if (this.isNew)
      {
        extension.Id = 0;

        if (extension.Disabled == null)
        {
          extension.Disabled = false;
        }
      }

      extension[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(extension);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Extension";

    if (this.isNew)
    {
      headerText = "New Extension";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.Name}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
