import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { DayPart } from "../administration.models";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { AdministrationService } from '../administration.service';


@Component({
  templateUrl: './daypart-edit.component.html',
  selector: 'daypart-edit-form',
  styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './dayParts.component.scss'],
  providers: [MatTableCrudService]
})
export class DayPartEditComponent extends MatTableCrudEditComponent<DayPartEditComponent, DayPart>
{
  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<DayPartEditComponent>, @Inject(MAT_DIALOG_DATA) public data: DayPart, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      DayPartName: [this.data.DayPartName, Validators.required],
      DayPartLabel: [this.data.DayPartLabel, Validators.required],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    let dayPart: any = this.form.value;

    if (dayPart)
    {
      if (this.isNew)
      {
        dayPart.Id = 0;

        if (dayPart.Disabled == null)
        {
          dayPart.Disabled = false;
        }
      }

      dayPart[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(dayPart);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit DayPart";

    if (this.isNew)
    {
      headerText = "New DayPart";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.DayPartName}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
